import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import LocalizedStrings from 'react-localization';
import lang from '../../lang/lang';
import { store } from '../../stores/store';
import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import Wait from '../Wait';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import './CorrectiveAction.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55%',
  minWidth: '570px',
  maxWidth: '750px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CorrectiveAction = React.forwardRef((
  {data, correctiveCodes, handleClose, handleRefresh, resultMessage}, 
  ref) => {

  const globalState = useContext(store);
  let strings = new LocalizedStrings(lang);
  strings.setLanguage(globalState.state.lang);
  const oApi = new ApiService();

  const [formData, setFormData] = useState({
    owner: data.owner || '',
    actionCode: data.actionCode || '',
    reason: data.reason || '',
    remarks: data.remarks || '',
  });
  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [confirmAction, setConfirmAction] = useState('');
  const [changesMade, setChangesMade] = useState(false);

  //if action does not already exist (has an action key), set flag to true
  const addNewAction = data.actionKey <= '0';

  const handleInputChange = (e) => {
    setChangesMade(true);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAdd = () => {
    setLoading(true);
    
    const body = {
      'ng_key': data.ngKey,
      'action_code': formData.actionCode,
      'reason': formData.reason,
      'descr': formData.remarks,
      'emp_name': formData.owner,
    };

    oApi.POST(
        ApiConstants.Action.Controller, 
        ApiConstants.Action.Add_Action, 
        null, 
        body
      ).then((response) => {
      setLoading(false);
      resultMessage('success', strings.conf_dialog.successMsgSaveCAAction);
      handleRefresh();
      handleClose();
    }).catch((error) => {
      setLoading(false);
      resultMessage('error', strings.conf_dialog.errorMsgSaveCAAction);
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const body = {
      'action_key': data.actionKey,
      'action_code': formData.actionCode,
      'reason': formData.reason,
      'descr': formData.remarks,
      'emp_name': formData.owner,
      'empid': formData.modifiedBy,
    };

    oApi.POST(
      ApiConstants.Action.Controller, 
      ApiConstants.Action.Update_Action,
      null, 
      body
    ).then((response) => {
      setLoading(false);
      resultMessage('success', strings.conf_dialog.successMsgSaveCAAction);
      handleRefresh();
      handleClose();
    }).catch((error) => {
      setLoading(false);
      resultMessage('error', strings.conf_dialog.errorMsgSaveCAAction);
    });
  };

  const handleRemove = () => {
    setLoading(true);
    const params = {
      action_key: data.actionKey
    };
    
    oApi.GET(
      ApiConstants.Action.Controller, 
      ApiConstants.Action.Delete_Action, 
      params
    ).then((response) => {
      setLoading(false);
      resultMessage('success', strings.conf_dialog.successMsgRemoveCAAction);
      handleRefresh();
      handleClose();
    }).catch((error) => {
      setLoading(false);
      resultMessage('error', strings.conf_dialog.errorMsgRemoveCAAction);
    });
  }

  /*
  * Handles a confirm 'yes' for save, remove, or cancel
  */
  const confirmHandler = () => {
    switch (confirmAction) {
      case 'save':
        addNewAction ? handleAdd() : handleUpdate();
        break;
      case 'remove':
        handleRemove();
        break;
      case 'cancel':
        // discard changes
        setChangesMade(false);
        handleClose();
        break;
      default:
    }
    //reset confirm choice
    closeConfirm();
  }

  /*
  * Sets the confirm text and opens confirm dialog
  */
  const confirm = (choice) => {
    setConfirmAction(choice);
    switch (choice) {
      case 'save': 
        setConfirmText(strings.conf_dialog.save_confirm_text);
        setOpenConfirm(true);
        break;
      case 'remove': 
        setConfirmText(strings.conf_dialog.remove_confirm_text);
        setOpenConfirm(true);
        break;
      case 'cancel': 
        if(changesMade)
        {
          setConfirmText(strings.conf_dialog.cancel_confirm_text);
          setOpenConfirm(true);
        } else {
          handleClose();
        }
        break;
      default: 
        setConfirmText('Are you sure?');
    }
  }

  const closeConfirm = () => {
    setConfirmAction('');
    setOpenConfirm(false);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    // validate form 
    // check required fields
    // if ( !(formData.owner && formData.reason && formData.actionCode) ) 
    // {
    //   console.log("Please fill out required fields, marked with *");
    // }

    confirm('save');
  };

  return (
    <>
    <Box sx={style} ref={ref} tabIndex={-1}>
      <h3>{strings.cds_notification.cor_act}</h3>
      <form onSubmit={onSubmit}>
        <div className='form-item'>
          <div className='form-label'>{strings.input_labels.process}:</div> 
          <div>{data.process}</div>
        </div>
        <div className='form-item'>
          <div className='form-label'>{strings.cds_correctiveaction_modal.v_rule}: </div> 
          <div>{data.vr}</div>
          </div>
        <div className='form-item'>
          <div className='form-label'>{strings.cds_notification.cont}-{strings.cds_notification.seq}: </div> 
          <div>{data.containerNo}-{data.sequentialNo}</div>
        </div>
        <div className='form-item'>
          <label className='form-label' htmlFor='cds_ca_owner'>{strings.cds_correctiveaction_modal.owner}:* </label> 
          <input 
            name="owner" 
            id="cds_ca_owner"
            type="text"
            value={formData.owner}
            onChange={(e) => handleInputChange(e)} 
            // disabled="true"
            required
          />
        </div>
        <div className='form-item'>
          <div className='form-label'>{strings.cds_correctiveaction_modal.modified_by}: </div> 
          <div>{data.modifiedBy}</div>
        </div>
        <div className='form-item'>
          <label className='form-label' htmlFor='cds_ca_reason'>{strings.cds_correctiveaction_modal.reason}:* </label> 
          <input 
            name="reason" 
            id="cds_ca_reason"
            type="text"
            value={formData.reason}
            onChange={(e) => handleInputChange(e)}
            required
          />
        </div>
        <div className='form-item'>
          <label className='form-label' htmlFor='cds_ca_actiontype'>{strings.cds_correctiveaction_modal.action_type}:* </label> 
          <select 
            name="actionCode" 
            id="cds_ca_actiontype"
            value={formData.actionCode}
            onChange={(e) => handleInputChange(e)} 
            required
          >
            <option key={-1} value="">
              Please select corrective action
            </option>
            { correctiveCodes.map( (item, index) =>  
                <option key={index} value={item.action_code}>
                  {item.action_description}
                </option>) 
            }
          </select>
        </div>
        <div className='form-item'>
          <label className='form-label' htmlFor='cds_ca_remarks'>{strings.cds_correctiveaction_modal.remarks}: </label> 
          <textarea 
            name="remarks" 
            id="cds_ca_remarks" 
            rows="4"
            value={formData.remarks}
            onChange={(e) => handleInputChange(e)} 
          />
        </div>

        <div className='form-buttons'>
          <button 
            className="input-button app-cancel-button"
            type="button" 
            onClick={() => confirm('cancel')}
          >
            {strings.cds_correctiveaction_modal.but_cancel}
          </button>

          { addNewAction || 
            <button
              className="input-button app-remove-button"
              type="button"
              onClick={() => confirm('remove')}
            >
              {strings.cds_correctiveaction_modal.but_remove}
            </button>
          }

          <button 
            className="input-button app-save-button"
            type="submit"
          >
            {addNewAction ? strings.cds_correctiveaction_modal.but_save : strings.cds_correctiveaction_modal.but_update}
          </button>
        </div>
      </form>
    </Box>
          
    <ConfirmationModal 
      isOpen={openConfirm} 
      handleYes={confirmHandler} 
      handleNo={closeConfirm} 
      text={confirmText} 
    />

      { loading && 
        <div className='loading-overlay loading-overlay-display'>
          <Wait message={strings.hold_msg} />
        </div>
      }
    </>
  )
});

export default CorrectiveAction