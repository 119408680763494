export const ja = {
    menu_labels: {        
        action_history: 'アクション履歴',
        search_chart: '図を探す',
        set_test: '検査基準を設定する',
        exit: '終了する',
        language: '言語',
        refresh: '再読み込み',
        hello: 'こんにちは',
        product_tool_num: '製品－ツール番号',
        dimension: '測定項目',
        back: '戻る',
        code_mang: 'コード管理',
        insp_plan: '検査計画を見る',
        admin: 'システムセッティング',
        stat_abn_rec: '統計的異常の受信者',
        application_config: 'アプリケーション設定',
        home_page: 'ホームページ',
    },
    input_labels: {
        plant: '工場',
        process: '工程',
        product_number: '製品番号',
        mc_tool_num: 'ツール番号',
        search: '検索',
        select_revision: 'を選択',
        items_per_page: 'ページごとの検査項目',
        save: '保存',
        total_items: '検査項目合計数',
        clear_dates: 'クリア',
        insplanheading: '設計規格',
    },
    list_labels: {
        pn: '製品番号',
        mc_tool: '設備/ツール番号',
        revision: 'Rev番号',
        last_date: '最終測定日',
    },
    logout_modal_labels: {
        yes_button : 'はい、ログアウトします',
        no_button: 'いいえ',
        desc: '本当にログアウトしますか？',
    },
    chart_detail: {
        pci: '工程能力情報',
        va: 'Rev番号',
    },
    cds_search: {
        runs: '直近100件の実行',
        range: '検索対象期間',
        refresh: '再読み込み',
        select_chart: '図の種類を選択',
        chart_label: '図',
    },
    cds_notification : {
        sys_not: 'システム通知',
        cs_sup: 'スーパーバイザーからの是正対策',
        cor_act: '是正対策',
        trcb: 'トレーサビリティ',
        vr: '逸脱されたルール',
        iss_dat: '発行日',
        val: '測定',
        lsl: 'LSL',
        lcl: 'LCL',
        ucl: 'UCL',
        usl: 'USL',
        root_cause: '異常原因',
        tak_act: '取られたアクション',
        act_dat: 'アクション実施日',
        owner: 'オーナー',
        cont: 'コンテナ番号',
        seq: '連番',
        actions: 'アクション',
        batchno: '追加トレーサビリティ1',
        serialno: '追加トレーサビリティ2',
    },
    cds_correctiveaction_modal : {
        but_save: '追加',
        but_cancel: 'キャンセル',
        v_rule: '逸脱されたルール',
        owner: 'オーナー',
        modified_by: '改定者',
        reason: '異常原因',
        action_type: '是正対策',
        remarks : '備考',
        but_update: '更新',
        but_remove: '削除',
    },
    ahs_labels : {
        date_range : '警告日',
        prod_numb: '製品番号',
        doc_num: '文書番号',
        ext_trc1: '追加トレーサビリティ1',
        ext_trc2: '追加トレーサビリティ2',
        reason_jud: '逸脱されたルール',
        reporter: 'オーナー',
        reason_for_abnormality: '異常原因',
    },
    tcs_labels : {
        plant_tab: '工場',
        plant_tab_text: '工場既定検査基準の更新',
        process_tab: '工程',
        process_tab_text: '工程検査基準の更新',
        process_label: '工程',
        product_tab: '製品',
        product_tab_text: '製品検査基準の更新',
        product_label: '製品番号',
        item_label: '検査項目',

        test_item_label: '検査ルール',
        process_product_alert: '選択された工程/製品が調整されました。保存後に設定内容が上書きされます。',
        product_items_alerts: '選択された製品/寸法が見つかりません。無効な製品番号',
    },
    error_msgs: {
        min_prod_length: '製品番号は４ケタ以上である必要があります。',
        no_search_data_found: 'この検索のデータが見つかりません。',
        ca_fields_required: '異常原因と是正対策のフィールドへの入力が必要です。',
    },　
    cms_notification: {
        code_management_label: '是正対策コード管理',
        process_lbl: '工程',
        code_lbl: 'コード',
        code_desc_lbl: 'コード名',
        status_lbl: 'ステータス',
    },
    hold_msg: 'リクエストを処理しています。お待ちください。',
    cds_pci: {
        potential_failure: '潜在的故障',
        overall: '全体',
        within: '以内',
        total: '合計',
    },
    email_notifications: {        
        dept: '工程',                      
        stat_abn: '統計的異常受信者設定',
        out_spec: '規格外れ',
        out_cont: '制御不能',
        low_cpk: 'CPKが低い',
        save_sett: '保存',
        stat_abn_type: '統計的異常のタイプ',
        email_add: 'Eメールアドレス',
        add: '追加',
        remove: '削除',
        global_email_label: 'グローバルメールアドレス',
        rec_email_label: '受信者',
        copy_from_label: 'コピー元',
        copy_list_but: 'コピー',
    },
    sel_lang: '言語を選択してください',
    language_popup_alert: '注意：新しい言語を選択すると、ホームページへリダイレクトされ、保存されていない変更はすべて失われます。',
    conf_dialog: {
        yes: 'はい',
        no: 'いいえ',
        save_confirm_text: '本当にこれらの変更を保存しますか？',
        cancel_confirm_text: '本当にキャンセルしますか？すべての変更は保存されません。',
        remove_confirm_text: '本当に削除しますか？',
        copy_recp_confirm_text: '本当に受信者をコピーしますか？',
        save_recp_confirm_text: 'これらの変更を保存しますか？',
        successMsgSaveRecipients: '受信者は保存されました。',
        errorMsgSaveRecipients: 'エラーのため受信者を保存できませんでした。',
        changelost_confirm_text: 'すべての保存されていない変更は失われます。よろしいですか？',
        confirm_label: 'アクションを確認',
        successMsgSaveCAAction: '是正対策は保存されました。',
        errorMsgSaveCAAction: 'エラーのため是正対策を保存できませんでした。',
        successMsgRemoveCAAction: '是正対策は削除されました。',
        errorMsgRemoveCAAction: 'エラーのため是正対策を削除できませんでした。',
        successCodeAddAction :'コードが追加されました。',
        errorCodeAddAction: 'エラーのためコードを追加できませんでした。',
        successCodeUpdateAction :'コードが更新されました。',
        errorCodeUpdateAction: 'エラーのためコードを更新できませんでした。',
        successCodeDeleteAction :'コードが消去/無効化されました。',
        errorCodeDeleteAction: 'エラーのためコードが消去/無効化されませんでした。',
        updateCodeValidationMsg : 'コード名とステータスのフィールドへの入力が必要です。',
        addCodeValidationMsg : 'すべてのフィールドに入力が必要です。',
        successMsgTestsSaved: '検査が保存されました。',
        errorMsgTestsSaved: 'エラーのため検査を保存できませんでした。',
        errorMsgInspPlanNoPDF: 'PDF文書が見つかりません。',
    },
    no_entitlement_msg: 'このアプリケーションを使用するには資格をリクエストしてください。',
    no_plants_msg: 'このアプリケーションを使用するには、工場へのアクセスをリクエストしてください。',
    no_roles_msg: 'このアプリケーションを使用するには、ロールへのアクセスをリクエストしてください。',
    grid: {
        body: {
            emptyDataSourceMessage: '要求に対する該当データなし',
            addTooltip: '新しい記録を追加する',
            deleteTooltip: '記録を消去する',
            editTooltip: '記録を編集する',
            filterRow: {
                filterTooltip: 'フィルター'
            },
            editRow: {
                deleteText: '本当にこの記録を消去しますか？',
                cancelTooltip: 'キャンセル',
                saveTooltip: '保存'
            }
        },
        header: {
            actions: ''
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} of {count}`,
            labelRowsSelect: '',
            firstAriaLabel: '',
            firstTooltip: '１ページ目',
            previousAriaLabel: '',
            previousTooltip: '前ページ',
            nextAriaLabel: '',
            nextTooltip: '次ページ',
            lastAriaLabel: '',
            lastTooltip: '最終ページ'
        },
        toolbar: {     
            addRemoveColumns: '',
            nRowsSelected: '',
            showColumnsTitle: '',
            showColumnsAriaLabel: '',
            exportTitle: 'エクスポート',
            exportAriaLabel: 'エクスポート',            
            searchTooltip: '検索',
            searchPlaceholder: '検索'           
        }    
    }
};
