import ApiService from '../services/ApiService';
import ApiConstants from '../services/ApiConstants';
import helperSession from '../helpers/HelperSession';

const UserAccessHandler = {

    oApi: new ApiService(),
    
    getUserAccessData(access_code) {
        const urlParams = {};
        const headers = {};

        return new Promise((resolve, reject) => {
            if(helperSession.air_mode){
                urlParams.AIR_access_code = access_code;
                headers.plant_code = helperSession.plant_code;
            } else {
                urlParams.code = access_code;
                urlParams.redirect_uri = process.env.REACT_APP_REDIRECT_URL;                
            }           

            this.oApi.GET(ApiConstants.User.Controller, ApiConstants.User.Token, urlParams, headers)
            .then(response => {                
                helperSession.access_token = response.data.access_token;

                const entitlements = [];                
                response.data.user_profile.entitlements.forEach(x => {
                    const attrs = x.split(',')
                    if (attrs[2].split('=')[1] === 'molex ccs spc tool')
                        entitlements.push(attrs[0].split('=')[1])
                });
                response.data.user_profile.entitlements = entitlements;

                helperSession.profile = response.data.user_profile;

                helperSession.removeAppSession('access_code');
                resolve(response.data)
                //history.push('/');
            }).catch(error => {
                helperSession.removeAppSession('access_code');                
                console.error(error);
                reject(error);
            });
        });
    },

    
}

export default UserAccessHandler;