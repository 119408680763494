
import React from 'react';
import ReactJson from 'react-json-view';
import '../../App.css';

const CDObjectEditor = (props) => {
    
    const marginTop = props.marginTop ? props.marginTop : "50px;"
    const rendertHeader = () => {
        if(props.no_header) return null;
        return (
            <h3 style={{backgroundColor: 'lightGray'}}>
                &nbsp;&nbsp;
                <i className="fa fa-times" onClick={props.handleClose} title="Close"></i>
                &nbsp;&nbsp;
                {props.title}                        
            </h3>
        )
    }
   

    return (

        <div style={{width: props.width, margin: "0px auto", marginTop: {marginTop}, backgroundColor: "white"}}>                
            {rendertHeader()}
            
            <div style={{ height: "auto", maxHeight: "650px",  overflow: "auto"}}>
                {props.edit &&
                    <ReactJson src={props.object_data} displayArrayKey={false} name={props.name} displayDataTypes={props.displayDataTypes ? true : false } collapsed={props.collapsed} 
                        onEdit = {e => props.onEdit(e)}
                    >
                    </ReactJson>
                }
                {!props.edit &&
                    <ReactJson src={props.object_data} name={props.name} displayArrayKey={false} displayDataTypes={props.displayDataTypes ? true : false } collapsed={props.collapsed}>
                    </ReactJson>
                }


            </div>
        </div>
    );
}

export default CDObjectEditor;