import React from 'react';
import { Line } from 'react-chartjs-2';
import '../../App.css';

const Xbar = (props) => {
    const data = props.data.xbar;

    const chartData = {
        labels: [],
        datasets: [
            {
                label: "X-BAR",
                data: [],
                fill: false,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                pointRadius: 1
            },

            {
                label: "LSL",
                data: [],
                fill: false,
                backgroundColor: "",
                borderColor: "red",
                pointRadius: 1
            },
            {
                label: "USL",
                data: [],
                fill: false,
                backgroundColor: "#742774",
                borderColor: "red",
                pointRadius: 1
            }
        ]

    }

    const chartOptions = {
        responsive: true,
        title: {
            display: false
        },
        tooltips: {
            mode: 'index',
            intersect: true
        },
        annotation: {
            annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 2,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 1,
                label: {
                    enabled: false,
                    content: 'Test label'
                }
            }]
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: false
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        display: true
                    }
                }
            ]
        },
        legend: {
            display: false,
        }
    };


    data.forEach((dt) => {
        chartData.labels.push('');
        chartData.datasets[0].data.push(dt);

        if(props.data.LSL) chartData.datasets[1].data.push(props.data.LSL);
        if(props.data.USL) chartData.datasets[2].data.push(props.data.USL);

    });

    return (
        <Line data={chartData} options={chartOptions} />
    );

}
export default Xbar;