
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect,useState } from 'react';
import LocalizedStrings from 'react-localization';
import '../../App.css';
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import lang from '../../lang/lang';
import { store } from '../../stores/store';
import MuiAlert from '@material-ui/lab/Alert';
import Wait from "../../components/Wait";

import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import { AlertTitle } from '@material-ui/lab';

const InspectionPlanScreen = (props) => {
    const globalState = useContext(store);
    const oApi = new ApiService();
    let strings = new LocalizedStrings(lang);    
    strings.setLanguage(globalState.state.lang);
    const [error, setError] = useState('');
    
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';
    }

    const getInspectionPlan = () => {
        setError("");        
        let params = {
            uqno: globalState.state.uqno
        };

        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Design_Spec, params).then((response) => {
            let obj = document.createElement('object');
            obj.style.width = '100%';
            obj.style.height = '1024px';
            obj.type = 'application/pdf';
            obj.data = 'data:application/pdf;base64,' + response.data.data;
            document.getElementById('ins-plan-pdf').appendChild(obj);
            stopWaiting();
        }).catch(error => {
            stopWaiting();
            //setOpenSnakeBarError(true);
            setError(strings.conf_dialog.errorMsgInspPlanNoPDF);
        });
    }

    const handleRefresh = () => {
        wait();
        document.getElementById('ins-plan-pdf').innerHTML = '';
        getInspectionPlan();
    }

    useEffect(() => {
        wait();
        getInspectionPlan();
    }, []);

    return (
        <div className="App" >
            <TopBar strings={strings} />
            {error && 
                <Alert className='app-cds-alert-style' variant="filled" severity="error" onClose={() => setError('')}>
                    <AlertTitle>{error}</AlertTitle>
                </Alert>
            }
            <header className="App-header">
                <div style={{ textAlign: 'center', width: '90%', marginTop: '-10px' }}>
                    <Typography className='inline-text-16-white'>{strings.input_labels.insplanheading}: </Typography>
                    <Typography className='inline-text-16-white-bold'>{globalState.state.pno + globalState.state.toolno + globalState.state.revision} </Typography>
                </div>

                <div id='ins-plan-pdf' style={{ width: '100%', marginTop: '25px' }}>

                </div>
            </header>

            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>                    
            </div>
            <BottomBar strings={strings} handleRefresh={handleRefresh} />
        </div>
    );
}

export default InspectionPlanScreen;