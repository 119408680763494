import React, { useContext } from 'react';
import lang from '../../lang/lang';
import LocalizedStrings from 'react-localization';
import { store } from '../../stores/store';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';

const CDSSearch = (props) => {
    const globalState = useContext(store);
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);

    return (
        <>
        <div className="cds-search-select">
            <FormControl component="fieldset">
                <RadioGroup aria-label="cds" name="cds" value={props.value} onChange={props.handleChange}>
                    <FormControlLabel value="100runs" control={<Radio />} label={<>
                        <Typography variant="subtitle1" color="inherit" className='inline-text-14-black'>{strings.cds_search.runs}</Typography>                                                
                    </>} />
                    <FormControlLabel value="daterange" control={<Radio />} label={<>
                        <Typography variant="subtitle1" color="inherit" className='inline-text-14-black'>{strings.cds_search.range}</Typography> &nbsp;
                            <input type="date" name="cds_daterangefrom" id="cds_daterangefrom" value={props.dateRangeFrom} onChange={props.handleFromDateChange}/>&nbsp;&nbsp;-&nbsp;&nbsp;
                            <input type="date" name="cds_daterangeto" id="cds_daterangeto" value={props.dateRangeTo} onChange={props.handleToDateChange}/>
                    </>} />
                </RadioGroup>                
                <button className="input-button" style={{width: "200px", margin: "10px auto"}} onClick={props.handleRefresh}>{strings.cds_search.refresh}</button>
            </FormControl>
        </div>
        {
            props.lotSize>1 &&
        <div className="cds-chartarea-select-padding-margin">
                <FormControl component="fieldset">
                    <FormLabel component="legend" style={{ color: "black" }}><Typography variant="subtitle1" className='inline-text-14-black'>{strings.cds_search.select_chart}</Typography></FormLabel>
                    <RadioGroup aria-label="cds" name="cds" value={props.selectValue} onChange={props.handleSelectChange} row>
        <FormControlLabel value="r_chart" control={<Radio />} label={<Typography color="inherit" className='inline-text-14-black'>Xbar-R {strings.cds_search.chart_label}</Typography>} />
                        <FormControlLabel value="s_chart" control={<Radio />} label={<Typography variant="subtitle1" color="inherit" className='inline-text-14-black'>Xbar-S {strings.cds_search.chart_label}</Typography>} />
                    </RadioGroup>
                </FormControl>
            </div>
        }
        </>
    );
}

export default CDSSearch;