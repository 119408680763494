import MaterialTable from 'material-table';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LocalizedStrings from 'react-localization';
import '../../App.css';
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import { checkRole } from '../../helpers/Utilities';
import lang from '../../lang/lang';
import { CODE_MANAGEMENT_SCREEN } from '../../resources/securityRoles';
import { store } from '../../stores/store';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Wait from "../../components/Wait";

import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';

const CodeManagementScreen = (props) => {
    const globalState = useContext(store);
    const oApi = new ApiService();
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);
    const useStyles = makeStyles((theme) => ({
        snakebarRoot: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        }
    }));

    const [openSnakeBar, setOpenSnakeBar] = React.useState(false);
    const [openSnakeBarError, setOpenSnakeBarError] = React.useState(false);
    const [snakeBarSuccessMsg, setSnakeBarSuccessMsg] = useState('');
    const [snakeBarErrorMsg, setSnakeBarErrorMsg] = useState('');
    const classes = useStyles();

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleCloseSnakeBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnakeBar(false);
    };

    const handleCloseSnakeBarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnakeBarError(false);
    };

    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';                
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';        
    }

    const [columns, setColumns] = useState([
        { title: strings.cms_notification.code_lbl, cellStyle: {
            width: 100,
            maxWidth: 100,
            },
            headerStyle: {
            width: 100,
            maxWidth: 100,
            },field: 'actioncode', editable: 'onAdd',validate: rowData => rowData.actioncode && rowData.actioncode !== '' },
        { title: strings.cms_notification.code_desc_lbl, 
            cellStyle: {
                width: 400,
                maxWidth: 400
                },
                headerStyle: {
                width: 400,
                maxWidth: 400
                },field: 'actiondescription', validate: rowData => rowData.actiondescription && rowData.actiondescription !== '' },
        {
            title: strings.cms_notification.status_lbl,
            cellStyle: {
                width: 200,
                maxWidth: 200
                },
                headerStyle: {
                width: 200,
                maxWidth: 200
                },
            field: 'active',
            validate: rowData => rowData.active !== '',
            lookup: { 1: 'Active', 0: 'Inactive' },
        },
    ]);
    
    const [data, setData] = useState([]);

    const getCorrectiveCodes = () => {
        oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.Corrective_Codes, null).then((response) => {
            stopWaiting();
            const codeList = response.data.data;
            const modCodeList = [];
            let codeData = {};
            for (let i = 0; i < codeList.length; i++) {
                codeData.process = codeList[i]['process_code'];
                codeData.actioncode = codeList[i]['action_code'];
                codeData.actiondescription = codeList[i]['action_description'];
                codeData.active = codeList[i]['active'] === true ? 1 : 0;
                modCodeList.push(codeData);
                codeData = {};
            }
            setData(modCodeList);

        }).catch(error => {
        });
    }

    const getProcesses = (isRefresh = false) => {
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Processes, null).then((response) => {
            const processList = response.data.data;
            let processLookupData = {};

            if (!isRefresh) {
                for (let i = 0; i < processList.length; i++) {
                    if (processList[i]['isUsed']) {
                        processLookupData = { ...processLookupData, [processList[i]['process_code']]: processList[i]['process'] }
                    }
                }
                setColumns([{
                    title: strings.cms_notification.process_lbl,
                    cellStyle: {
                        width: 350,
                        maxWidth: 350
                        },
                        headerStyle: {
                        width: 350,
                        maxWidth: 350
                        },
                    field: 'process', editable: 'onAdd', validate: rowData => rowData.process !== '',
                    lookup: { ...processLookupData }
                }, ...columns]);
            }
            getCorrectiveCodes();

        }).catch(error => {
            stopWaiting();
        });
    }

    const handleAdd = newData => {
        if (newData.process && newData.actioncode && newData.actiondescription && newData.active) {
            return new Promise((resolve, reject) => {
                const body = {
                    'process_code': newData.process,
                    'action_code': newData.actioncode,
                    'action_description': newData.actiondescription,
                    'active': newData.active
                };
                oApi.POST(ApiConstants.Action.Controller, ApiConstants.Action.Add_Corrective_Code, null, body).then((response) => {
                    getCorrectiveCodes();
                    setSnakeBarSuccessMsg(strings.conf_dialog.successCodeAddAction);
                    setOpenSnakeBar(true);
                    resolve();
                }).catch(error => {
                    reject();
                    setSnakeBarErrorMsg(strings.conf_dialog.errorCodeAddAction);
                    setOpenSnakeBarError(true);
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                setSnakeBarErrorMsg(strings.conf_dialog.addCodeValidationMsg);
                setOpenSnakeBarError(true);
                reject();
            })
        }
    }

    const handleUpdate = (newData, oldData) => {
        if (newData.actiondescription) {
            return new Promise((resolve, reject) => {
                const body = {
                    'process_code': newData.process,
                    'action_code': newData.actioncode,
                    'action_description': newData.actiondescription,
                    'active': newData.active
                };
                oApi.POST(ApiConstants.Action.Controller, ApiConstants.Action.Update_Corrective_Code, null, body).then((response) => {
                    getCorrectiveCodes();
                    setSnakeBarSuccessMsg(strings.conf_dialog.successCodeUpdateAction);
                    setOpenSnakeBar(true);
                    resolve();
                }).catch(error => {
                    reject();
                    setSnakeBarErrorMsg(strings.conf_dialog.errorCodeUpdateAction);
                    setOpenSnakeBarError(true);
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                setSnakeBarErrorMsg(strings.conf_dialog.updateCodeValidationMsg);
                setOpenSnakeBarError(true);
                reject();
            })
        }
    }

    const handleDelete = oldData => {
        return new Promise((resolve, reject) => {
            let params = {
                process_code: oldData.process,
                action_code: oldData.actioncode
            };
            oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.Delete_Corrective_Code, params).then((response) => {
                getCorrectiveCodes();
                setSnakeBarSuccessMsg(strings.conf_dialog.successCodeDeleteAction);
                setOpenSnakeBar(true);
                resolve();
            }).catch(error => {
                reject();
                setSnakeBarErrorMsg(strings.conf_dialog.errorCodeDeleteAction);
                setOpenSnakeBarError(true);
            });
        })
    }

    const handleRefresh = () => {
        wait();
        getProcesses(true);
    }

    useEffect(() => {
        wait();
        getProcesses();
    }, []);

    if (checkRole(CODE_MANAGEMENT_SCREEN))
        return (
            <div className="App" >
                <TopBar strings={strings} />
                <header className="App-header">
                    <div className='cms-main-div'>
                        <MaterialTable
                            title={strings.cms_notification.code_management_label}
                            options={{
                                rowStyle: {
                                    fontSize: 15,
                                },
                                headerStyle: {
                                    backgroundColor: '#01579b',
                                    color: '#FFF',
                                    position: 'sticky',
                                    top: 0
                                },
                                filtering: true,
                                exportButton: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 20, { value: data.length, label: 'All' }],
                                exportAllData: true,
                                maxBodyHeight: '490px',
                                addRowPosition: 'first'
                            }}
                            columns={columns}
                            data={data}
                            editable={{
                                onRowAdd: handleAdd,
                                onRowUpdate: handleUpdate,
                                onRowDelete: handleDelete
                            }}
                            localization={strings.grid}
                        />
                    </div>
                </header>

                <div id="loading_ph" className="loading-overlay">
                    <Wait message={strings.hold_msg} >
                    </Wait>                    
                </div>
                <div className={classes.snakebarRoot}>
                    <Snackbar open={openSnakeBar} autoHideDuration={6000} onClose={handleCloseSnakeBar}>
                        <Alert onClose={handleCloseSnakeBar} severity="success">
                            {snakeBarSuccessMsg}
                        </Alert>
                    </Snackbar>
                </div>
                <div className={classes.snakebarRoot}>
                    <Snackbar open={openSnakeBarError} autoHideDuration={6000} onClose={handleCloseSnakeBarError}>
                        <Alert onClose={handleCloseSnakeBarError} severity="error">
                            {snakeBarErrorMsg}
                        </Alert>
                    </Snackbar>
                </div>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                <BottomBar strings={strings} handleRefresh={handleRefresh} />
            </div>
        );
    else
        return (
            <div className="App" >
                <TopBar strings={strings} />
                <header className="App-header" style={{ top: '10px', marginTop: '90px' }}>
                    <div style={{ textAlign: 'center', width: '60%' }}>
                        <h2>You are not authorized.</h2>
                    </div>
                </header>

                <BottomBar strings={strings} />
            </div>
        )
}

export default CodeManagementScreen;