import React from 'react';
import { Line } from 'react-chartjs-2';
import '../../App.css';

const CDSXbar = (props) => {
  const data = props.data.xbar;
  // const max = props.data['USL'] < props.data['UCLx'] ? props.data['UCLx'] : props.data['USL'];
  // const min = props.data['LSL'] > props.data['LCLx'] ? props.data['LCLx'] : props.data['LSL'];

  const chartData = {
    // labels: props.data.x,
    labels: [],
    datasets: [
      {
        label: "X-BAR",
        data: [],
        fill: false,
        backgroundColor: "lightblue",
        borderColor: "darkblue",
        pointRadius: 2,
        lineTension: 0
      },
      {
        label: "LCLx",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'yellow' ? "orange" : "yellow",
        borderColor: props.data.color === 'yellow' ? "orange" : "yellow",
        pointRadius: 1,
        borderDash: [8, 5]
      },
      {
        label: "UCLx",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'yellow' ? "orange" : "yellow",
        borderColor: props.data.color === 'yellow' ? "orange" : "yellow",
        pointRadius: 1,
        borderDash: [8, 5]
      },
      {
        label: "xbarbar",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'yellow' ? "red" : "white",
        borderColor: props.data.color === 'yellow' ? "red" : "white",
        pointRadius: 1,
        borderDash: [5, 5]
      },
      {
        label: "U_2sigma_x",
        data: [],
        fill: false,
        backgroundColor: props.data.color === '#808080' ? "orange" : "grey",
        borderColor: props.data.color === '#808080' ? "orange" : "grey",
        pointRadius: 1,
        borderDash: [1, 5],
        borderWidth: 1
      },
      {
        label: "U_1sigma_x",
        data: [],
        fill: false,
        backgroundColor: props.data.color === '#808080' ? "orange" : "grey",
        borderColor: props.data.color === '#808080' ? "orange" : "grey",
        pointRadius: 1,
        borderDash: [1, 5],
        borderWidth: 1
      },
      {
        label: "L_2sigma_x",
        data: [],
        fill: false,
        backgroundColor: props.data.color === '#808080' ? "orange" : "grey",
        borderColor: props.data.color === '#808080' ? "orange" : "grey",
        pointRadius: 1,
        borderDash: [1, 5],
        borderWidth: 1
      },
      {
        label: "L_1sigma_x",
        data: [],
        fill: false,
        backgroundColor: props.data.color === '#808080' ? "orange" : "grey",
        borderColor: props.data.color === '#808080' ? "orange" : "grey",
        pointRadius: 1,
        borderDash: [1, 5],
        borderWidth: 1
      },
      {
        label: "LSL",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'red' ? "black" : "red",
        borderColor: props.data.color === 'red' ? "black" : "red",
        pointRadius: 1
      },
      {
        label: "USL",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'red' ? "black" : "red",
        borderColor: props.data.color === 'red' ? "black" : "red",
        pointRadius: 1
      },
      {
        label: "",
        data: [],
        fill: false,
        backgroundColor: props.data.color,
        borderColor: props.data.color,
        pointRadius: 1
      },
      {
        label: "",
        data: [],
        fill: false,
        backgroundColor: props.data.color,
        borderColor: props.data.color,
        pointRadius: 1
      }
    ]
  }

  const toolTipTitleCallback = (tooltipItems, data) => {
    if (tooltipItems && tooltipItems.length > 0) return props.data.x[tooltipItems[0].index];
    return "X-Bar";
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    title: {
      display: false
    },
    tooltips: {
      filter: (tooltipItem) => {
        const indexes = [0, 1, 2, 3, 8, 9];
        return indexes.indexOf(tooltipItem.datasetIndex) >= 0;
      },
      mode: 'index',
      // intersect: true,
      callbacks: {
        // label: toolTipLabelCallback,
        title: toolTipTitleCallback
      }
    },
    annotation: {
      annotations: [{
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 2,
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
        label: {
          enabled: false,
          content: 'Test label'
        }
      }]
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            fontColor: props.data['text_color'],
            // stepSize: +props.data['USL'] - (+props.data['LSL']) > 2? 1 : 0.005,
            // Add padding 
            // min: min*0.9975,
            // max: max*1.0025
          }
        }
      ]
    },
    legend: {
      display: false,
    }
  };

  data.forEach((dt) => {
    chartData.labels.push('');
    chartData.datasets[0].data.push(dt);
    chartData.datasets[1].data.push(props.data['LCLx']);
    chartData.datasets[2].data.push(props.data['UCLx']);
    chartData.datasets[3].data.push(props.data['xbarbar']);
    chartData.datasets[4].data.push(props.data['U_2sigma_x']);
    chartData.datasets[5].data.push(props.data['U_1sigma_x']);
    chartData.datasets[6].data.push(props.data['L_2sigma_x']);
    chartData.datasets[7].data.push(props.data['L_1sigma_x']);

    if (props.data['LSL']) chartData.datasets[8].data.push(props.data['LSL']);
    if (props.data['USL']) chartData.datasets[9].data.push(props.data['USL']);

    // chartData.datasets[10].data.push(+props.data['USL']*1.025);
    // let val = +props.data['LSL']*1.025 -(+props.data['LSL']);
    // chartData.datasets[11].data.push(+props.data['LSL']-val);
  });

  return (
    <div>
      <div className='chart-heading'>{props.heading || ''}</div>
      <div
      className='chart-wrapper'
        style={{ backgroundColor: props.data.color, cursor: 'pointer'}}
        onClick={props.onClick}  
      >
        <Line
          data={chartData}
          options={chartOptions}
          datasetKeyProvider={props.datasetKeyProvider}
          key={props.refreshKey}
        />
      </div>
    </div>
  );
}

export default CDSXbar;