
import React, { useContext, useEffect,useState } from 'react';
import Typography from '@material-ui/core/Typography';

import LocalizedStrings from 'react-localization';

import lang from '../../lang/lang';
import { store } from '../../stores/store';
import MuiAlert from '@material-ui/lab/Alert';
import Wait from "../../components/Wait";

import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import { AlertTitle } from '@material-ui/lab';

import '../../App.css';

const CDInspectionPlan = (props) => {

    const globalState = useContext(store);
    const oApi = new ApiService();
    let strings = new LocalizedStrings(lang);    
    strings.setLanguage(globalState.state.lang);
    const [error, setError] = useState('');
    const [docSeqs, setDocSeqs] = useState([]);
    const [docSeq, setDocSeq] = useState('1')

    useEffect(() => {
        
        if(!globalState.state.uqno){
            setError("Invalid UQNO")
            return
        }        
        getInspectionPlanCounts()
    }, []);

    useEffect(() => {
        getInspectionPlan()
    }, [docSeq]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const startWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';
    }

    const getInspectionPlanCounts = () => {
        setError("");
        
        let params = {
            uqno: globalState.state.uqno
        };

        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Design_Spec_Count, params).then((response) => {            
            const seqs = []
            const len = response.data.data.length
            for(let i=0; i<len; i++) seqs.push(response.data.data[i].Seq)
            setDocSeqs(seqs)            
        }).catch(error => {            
            setError(strings.conf_dialog.errorMsgInspPlanNoPDF);
        });
    }

    const getInspectionPlan = () => {
        
        startWaiting();
        setError("");
        
        let params = {
            uqno: globalState.state.uqno,
            seq: docSeq
        };
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Design_Spec, params).then((response) => {
            
            const pdfElement = document.getElementById('ins-plan-pdf')
            pdfElement.innerHTML = ''

            let obj = document.createElement('object');
            obj.style.width = '100%';
            obj.style.height = '700px';            
            obj.type = 'application/pdf';
            obj.data = 'data:application/pdf;base64,' + response.data.data;
            pdfElement.appendChild(obj);            
            stopWaiting();  
        }).catch(error => {            
            setError(strings.conf_dialog.errorMsgInspPlanNoPDF);
            stopWaiting();  
        });
                               
    }

    const handleDocSeqChange = (event) => {        
        const { value } = event.target;        
        setDocSeq(value)
    }
  
    return (
        <div >

            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>                    
            </div>
            
            {error && 
                <Alert className='app-cds-alert-style' variant="filled" severity="error" >
                    <AlertTitle>{error}</AlertTitle>                    
                </Alert>
            }
            <header className="App-header" style={{marginTop: '5px'}}>
                <div style={{ textAlign: 'center', width: '90%', marginTop: '0px' }}>                    
                <Typography className='inline-text-16-white'>Docs: </Typography>
                    <select onChange={(e) => handleDocSeqChange(e)}>
                        {
                            docSeqs.map(x => <option value={x} key={x}>Doc {x}</option>)
                        }                        
                    </select>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                
                    <Typography className='inline-text-16-white'>{strings.input_labels.insplanheading}: </Typography>
                    <Typography className='inline-text-16-white-bold'>{globalState.state.uqno} </Typography>
                </div>

                <div id='ins-plan-pdf' style={{ width: '100%', marginTop: '5px' }}>
                </div>
            </header>
            
            
        </div>
    );
}

export default CDInspectionPlan;