export const SEARCH_CHART_SCREEN = 'search_chart_screen';
export const CODE_MANAGEMENT_SCREEN = 'code_management_screen';
export const CORRECTIVE_ACTION = 'corrective_action';
export const TEST_CRITERIA_SCREEN = 'test_criteria_screen';
export const REGULARITY_INSPECTION_SETTINGS = 'regularity_inspection_settings';
export const STATISTICAL_ABNORMALITY_SETTINGS = 'statistical_abnormality_settings';
export const ACTION_HISTORY_SCREEN = 'action_history_screen';
export const INSPECTION_PLAN_SCREEN = 'inspection_plan_screen';
export const APPLICATION_CONFIG_SCREEN = 'application_config_screen';
export const REVISION_SCREEN = 'revision_screen';
export const CHART_DETAIL_SCREEN = 'chart_detail_screen';

const SYSTEM_ADMIN = 'system_admin';
const PROCESS_ENGINEER = 'process_engineer';
const PRODUCT_DESIGNER = 'product_engineer';
const GENERAL_USER = 'general_user';
const MANUFACTURING_RESPONSIBILITY = 'function_owner';
const AQP_RESPONSIBILITY = 'aqp_responsibility';
const MQA_RESPONSIBILITY = 'mqa_responsibility';
const IT_ADMIN = 'it_admin';

export const securityRoles = {
    SEARCH_CHART_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, PRODUCT_DESIGNER, GENERAL_USER, MANUFACTURING_RESPONSIBILITY, AQP_RESPONSIBILITY, MQA_RESPONSIBILITY],
    REVISION_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, PRODUCT_DESIGNER, GENERAL_USER, MANUFACTURING_RESPONSIBILITY, AQP_RESPONSIBILITY, MQA_RESPONSIBILITY],
    CHART_DETAIL_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, PRODUCT_DESIGNER, GENERAL_USER, MANUFACTURING_RESPONSIBILITY, AQP_RESPONSIBILITY, MQA_RESPONSIBILITY],
    CODE_MANAGEMENT_SCREEN: [SYSTEM_ADMIN],
    CORRECTIVE_ACTION: [SYSTEM_ADMIN, MANUFACTURING_RESPONSIBILITY, PROCESS_ENGINEER, MQA_RESPONSIBILITY],
    TEST_CRITERIA_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, MQA_RESPONSIBILITY],
    REGULARITY_INSPECTION_SETTINGS: [SYSTEM_ADMIN, PROCESS_ENGINEER, MQA_RESPONSIBILITY],
    STATISTICAL_ABNORMALITY_SETTINGS: [SYSTEM_ADMIN, PROCESS_ENGINEER, MQA_RESPONSIBILITY],
    ACTION_HISTORY_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, PRODUCT_DESIGNER, GENERAL_USER, MANUFACTURING_RESPONSIBILITY, AQP_RESPONSIBILITY, MQA_RESPONSIBILITY],
    INSPECTION_PLAN_SCREEN: [SYSTEM_ADMIN, PROCESS_ENGINEER, PRODUCT_DESIGNER, GENERAL_USER, MANUFACTURING_RESPONSIBILITY, AQP_RESPONSIBILITY, MQA_RESPONSIBILITY],
    APPLICATION_CONFIG_SCREEN: [IT_ADMIN]
}