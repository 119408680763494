import React, { useContext } from 'react';
import { store } from '../stores/store'
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import authHelper from "./AuthHelper";

import ApiService from '../services/ApiService';
import ApiConstants from '../services/ApiConstants';

const cookies = new Cookies();

const AIR_AuthHandler = (props) => {

    let history = useHistory();
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const oApi = new ApiService();

    const getUserAccessData = (access_code, plant_code) => {

        const urlParams = {
            AIR_access_code: access_code            
        };
        const headers = {
            plant_code: plant_code
        }
        
        oApi.GET(ApiConstants.User.Controller, ApiConstants.User.Token, urlParams, headers)
        .then(response => {
            cookies.remove('access_code');            
            if(response.data) {
                sessionStorage.setItem('access_token', response.data.access_token);
                
                dispatch({ type: 'update_token', value: response.data.access_token });
                dispatch({ type: 'update_user', value: response.data.user_profile });
                history.push('/');
            } else {
                history.push({pathname: '/error', state: {detail: "Invalid Access Token"} });            
            }
        }).catch(error => {
            cookies.remove('access_code');
            console.error(error);
            history.push({pathname: '/error', state: {detail: "Invalid Access Token"} });            
        });
    }

    const processAuthResults = (url) => {
        const urlParams = authHelper.getUrlParams(url);        
        const access_code = authHelper.getUrlParam(urlParams, 'AIR_access_code');        
        const plant_code = authHelper.getUrlParam(urlParams, 'plant_code');
        getUserAccessData(access_code, plant_code)
    }
    
    processAuthResults(window.location.href);

    return (
        <div className="square-green">
            <span>Logged in !</span>
        </div>
    )
}

export default AIR_AuthHandler;