import React, { useContext, useState } from 'react';
import raondomstring from 'randomstring';
import lang from '../../lang/lang';
import LocalizedStrings from 'react-localization';
import { store } from '../../stores/store';
import ChartSection from './ChartSection';
import CDSXbar from '../CDSXbar/CDSXbar';
import RangeChart from '../RangeChart/RangeChart';
import './CDSChartArea.css';

const CDSChartArea = ({ data, pciHandler, lotSize, selectValue }) => {
  const globalState = useContext(store);
  let strings = new LocalizedStrings(lang);
  strings.setLanguage(globalState.state.lang);

  const [refreshKey, setRefreshKey] = useState(0);

  const datasetKeyProvider = () => {
    return raondomstring.generate({
      length: 6,
      charset: 'alphabetic'
    })
  }

  // changing the refresh key will cause the charts to re-render
  // for re-sizing (chart expand)
  const refreshCharts = () => {
    setRefreshKey(Math.floor(Math.random() * 999));
  }

  const getCharts = data.map((item, i) => {
    return (
      <CDSXbar
        data={item}
        datasetKeyProvider={datasetKeyProvider}
        onClick={() => pciHandler(item)}
        heading={`${strings.chart_detail.va} ${item.revision}`}
        key={i}
        refreshKey={refreshKey}
      />
    )
  });

  const getRangeCharts = data.map((item, i) => {
    return (
      <RangeChart
        data={item}
        datasetKeyProvider={datasetKeyProvider}
        selectValue={lotSize === 1 ? 'range' : selectValue}
        key={i}
        refreshKey={refreshKey}
      />
    )
  });

  return (
    <div className='cds-chartarea-main'>
      <ChartSection
        heading={`X-Bar ${strings.cds_search.chart_label}`}
        refreshCharts={refreshCharts}
      >
        {getCharts}
      </ChartSection>

      <ChartSection
        heading={`${lotSize === 1 ? 'Range ' : selectValue === 'r_chart' ? 'R-' : 'S-'}${strings.cds_search.chart_label}`}
        refreshCharts={refreshCharts}
      >
        {getRangeCharts}
      </ChartSection>
    </div>
  );
}

export default CDSChartArea;