import Grid from '@material-ui/core/Grid';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';
import CDSChartArea from '../../components/CDSChartArea/CDSChartArea';
import CDSNotificationTable from '../../components/CDSNotificationTable/CDSNotificationTable';
import CDSSearch from '../../components/CDSSearch/CDSSearch';
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import ProcessCapInfo from '../../components/process_cap_info/ProcessCapInfo';
import { convertTodaysDateToYYYYMMDD } from '../../helpers/Utilities';
import lang from '../../lang/lang';
import { store } from '../../stores/store';
import Wait from "../../components/Wait"

import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';

const ChartDetailScreen = (props) => {
    const globalState = useContext(store);
    const { dispatch } = globalState;
    
    const oApi = new ApiService();

    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);
    const [statsData, setStatsData] = useState([]);
    const [notificationData, setNotificationData] = useState([]);
    const [pciData, setPCIData] = useState({});
    const [histogramData, setHistogramData] = useState({ frequency: [], exp_overall: [], exp_within: [], bins: [] });
    const [dimensions, setDimensions] = useState([]);
    const [error, setError] = useState('');
    const [value, setValue] = React.useState('100runs');    
    const [dateRangeTo, setDateRangeTo] = React.useState('');
    const [dateRangeFrom, setDateRangeFrom] = React.useState('');
    const [selectValue, setSelectValue] = React.useState('r_chart');
    const [lotSize, setLotSize] = React.useState(1);
    const [CACodes, setCACodes] = useState([]);

    const handleRefresh = () => {
        if (value === '100runs') {            
                handleDateSearch(value);
        } else {
            if (dateRangeFrom && dateRangeTo)
                handleDateSearch(value, dateRangeFrom, dateRangeTo);
            else
                setError('Please provide both dates.')
        }
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleToDateChange = (event) => {
        setDateRangeTo(event.target.value);
    }

    const handleFromDateChange = (event) => {
        setDateRangeFrom(event.target.value);
    }

    const handleSelectChange = (event) => {
        setSelectValue(event.target.value);
    };

    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';        
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';        
    }

    const handleChangeDimension = (dimension) => {
        setError('');
        dispatch({ type: 'set_dimension', value: { dimension: dimension } });
        wait();
        if (value === '100runs')
            getStatsData({ ...globalState.state, dimension: dimension, count: 100 });
        else
            getStatsData({ ...globalState.state, dimension: dimension, fromdate: dateRangeFrom, todate: dateRangeTo });
    }

    // This method handles click on Refresh button on search component
    const handleDateSearch = (value, dateOne, dateTwo = '') => {
        setError('');
        if (value === '100runs') {
            wait();
            getStatsData({ ...globalState.state, count: 100 });
        } else {
            wait();
            getStatsData({ ...globalState.state, fromdate: dateOne, todate: dateTwo });
        }
    }

    // This method handles when PCI data is changed by clicking on xbar chart
    const pciHandler = data => {
        if (pciData.revision !== data.revision) {
            wait();
            getNotificationData(data.revision);
            setPCIData(data);
            setHistogramData({ frequency: [], exp_overall: [], exp_within: [], bins: [] });
            setHistogramData(data.histogram);
        }
    }

    // Get stats data from API
    const getStatsData = (params) => {

        wait();
        var dimParams = {
            item: params.dimension.trim(),
            pno: params.pno.trim(),
            toolno: params.toolno.trim(),
            todate: params.todate || '',
            fromdate: params.fromdate || '',
            count: params.count || ''
        };
        
        oApi.GET(ApiConstants.TestData.Controller, ApiConstants.TestData.Stats, dimParams).then((response) => {
            switch (response.status) {
                case 200:                    
                    if(response.data.data.length===0) break
                    // Set uqno
                    const uqno = response.data.data[0].uqno
                    dispatch({ type: 'set_uqno', value: { uqno: uqno } });
                    getNotificationData();
                    setStatsData([]);
                    setLotSize(response.data.data[0].lotSize);
                    setStatsData(response.data.data.reverse());
                    setPCIData(response.data.data[response.data.data.length-1]);
                    setHistogramData({ frequency: [], exp_overall: [], exp_within: [], bins: [] });
                    setHistogramData(response.data.data[response.data.data.length-1].histogram);
                    
                    break;
                case 204:
                    stopWaiting();
                    setError(strings.error_msgs.no_search_data_found);
                    break;
                default:
            }
        }).catch(error => {
            console.error(error);
            stopWaiting();
            setError(strings.error_msgs.no_search_data_found);
        });
    }

    const handleUpdateNotifData = () => {
        getNotificationData(pciData.revision);
    }

    const getNotificationData = () => {       
        let urlParams = {
            item: globalState.state.dimension.trim(),
            uqno: globalState.state.uqno,
            toolno: globalState.state.toolno.trim()
        };

        wait();
        oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.History, urlParams).then((response) => {
            stopWaiting();
            switch (response.status) {
                case 200:
                    setNotificationData(response.data.data);
                    break;
                default:
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const getListOfAllDimensions = (revno) => {
        let dimParams = {
            pno: globalState.state.pno.trim(),
            toolno: globalState.state.toolno.trim(),
            revision: revno
        };
        
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Dimensions, dimParams).then((response) => {
            var dimArray = []
            response.data.data.forEach((data,i) => {
                if (data.dimension === globalState.state.dimension) {
                    dimArray.push(<option selected key={i} value={data.dimension}>{data.dimension}</option>)
                }
                else {
                    dimArray.push(<option key={i} value={data.dimension}>{data.dimension}</option>)
                }
            });
            setDimensions(dimArray);
        }).catch(error => {
            console.error(error);
        });
    }

    const getCorrectiveCodes = (process_code) => {
        let params = {
            process_code: process_code,
            active: true
        };

        oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.Corrective_Codes, params).then((response) => {            
            setCACodes(response.data.data);            
        }).catch(error => {
            console.error(error);
        });
    }

    const handlePageRefresh = () => {
        wait();
        getCorrectiveCodes(globalState.state.process_code);
        getListOfAllDimensions(globalState.state.revision);
        getStatsData({ ...globalState.state, count: 100, todate: convertTodaysDateToYYYYMMDD(1) });
    }

    useEffect(() => {
        getCorrectiveCodes(globalState.state.process_code);
        getListOfAllDimensions(globalState.state.revision);
        getStatsData({ ...globalState.state, count: 100, todate: convertTodaysDateToYYYYMMDD(1) });
    }, []);


    return (
        <div className="App" >
            <TopBar showInspIcon={true} process={globalState.state.process_name} strings={strings} pno={globalState.state.pno} 
                    toolno={globalState.state.toolno} dimension={globalState.state.dimension} dimensionList={dimensions} handleChangeDimension={handleChangeDimension} />

            {error && 
                <Alert className='app-cds-alert-style' variant="filled" severity="error" onClose={() => setError('')}>
                    <AlertTitle>{error}</AlertTitle>
                </Alert>
            }
            <header className="App-header">
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={7} className='grid-margin-10'>
                        <ProcessCapInfo data={pciData} histogramData={histogramData} />
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={4} className='grid-margin-10'>
                        <CDSSearch lotSize={lotSize} value={value} dateRangeTo={dateRangeTo} dateRangeFrom={dateRangeFrom} selectValue={selectValue} 
                        handleRefresh={handleRefresh} handleChange={handleChange} 
                        handleToDateChange={handleToDateChange} handleFromDateChange={handleFromDateChange} 
                        handleSelectChange={handleSelectChange} />
                    </Grid>
                    <Grid item xs={12} className='grid-margin-10'>
                        <CDSChartArea data={statsData} pciHandler={pciHandler} lotSize={lotSize} selectValue={selectValue} />
                    </Grid>
                    <Grid item xs={12} className='grid-margin-5'>
                        <CDSNotificationTable data={notificationData} setError={setError} correctiveCodes={CACodes} handleUpdateNotifData={handleUpdateNotifData}/>
                    </Grid>
                </Grid>
            </header>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>                                    
            </div>
            <BottomBar strings={strings} handleRefresh= {handlePageRefresh}/>
        </div>
    );
}

export default ChartDetailScreen