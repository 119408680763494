export const zh = {
    menu_labels: {        
        action_history: '措施履历',
        search_chart: '查询控制图',
        set_test: '控制图原则设置',
        exit: '退出系统',
        language: '语言设置',
        refresh: '刷新页面',
        hello: '你好',
        product_tool_num: '产品料号-模具号(产线号)#',
        dimension: '测量项目',
        back: '返回',
        code_mang: '代码管理',
        insp_plan: '查看检验计划',
        admin: '系统设置',        
        stat_abn_rec: '统计异常电子邮件接受者',
        application_config: '应用程序配置',
        home_page: '主页'
    },
    input_labels: {
        plant: '工厂',
        process: '制程(部门)',        
        product_number: '产品料号',
        mc_tool_num: '模具号(线号)',        
        search: '查询',
        select_revision: '选择修订版本: ',
        items_per_page: '每页显示测量项目数: ',
        save: '保存',
        total_items: '测量项目总数量(总计)',
        clear_dates: '删除日期范围',
        insplanheading: '测量示意图'
    },
    list_labels: {
        pn: '产品料号',
        mc_tool: '模具(线)号/机器号',        
        revision: '修订版本#',
        last_date: '最新的测量日期'
    },
    logout_modal_labels: {
        yes_button : '是, 请注销登录',
        no_button: '否',
        desc: '您确定要注销登录吗？'
    },
    chart_detail: {
        pci: '制程能力信息',
        va: '修订版本#'        
    },
    cds_search: {
        runs: '最近的100笔数据',
        range: '查询日期范围',
        refresh: '刷新页面',
        select_chart: '选择控制图类型',
        chart_label: '控制图'
    },
    cds_notification : {
        sys_not: '系统通知',
        cs_sup: '主管的纠正措施',
        cor_act: '纠正措施',
        trcb: '追溯性信息',
        vr: '违反的控制图判图原则',
        iss_dat: '发行日期',
        val: '测量值',
        lsl: '规格下限(LSL)',
        lcl: '控制下限(LCL)',
        ucl: '控制上限(UCL)',
        usl: '规格上限(USL)',
        root_cause: '异常原因',
        tak_act: '采取的措施',
        act_dat: '采取措施时间',
        owner: '责任人',
        cont: '批号#',
        seq: '盘(箱)号#',
        actions: '措施',
        batchno: '工单号(PO)',
        serialno: 'Barcode'
    },
    cds_correctiveaction_modal : {
        but_save: '新增',
        but_cancel: '取消',
        v_rule: '违反的控制图判图原则',
        owner: '责任人',
        modified_by: '修改者',
        reason: '异常原因',
        action_type: '纠正措施(代码)',
        remarks : '备注',
        but_update: '更新',
        but_remove: '删除'
    },
    ahs_labels : {
        date_range : '通知日期',        
        prod_numb: '产品料号',
        doc_num: '文件编号',
        ext_trc1: '工单号(PO)',
        ext_trc2: 'Barcode',
        reason_jud: '违反的控制图判图原则',                                
        reporter: '责任人',
        reason_for_abnormality: '异常原因'        
    },
    tcs_labels : {
        plant_tab: '工厂',
        plant_tab_text: '更新工厂范围的默认控制图判图原则',
        
        process_tab: '制程(部门)',
        process_tab_text: '更新制程(部门)范围的默认控制图判图原则',
        process_label: '制程(部门)',

        product_tab: '产品料号',
        product_tab_text: '更新产品料号/测量项目的控制图判图原则',
        product_label: '产品料号',
        item_label: '测量项目',

        test_item_label: '检验规则',
        process_product_alert: '选定的制程产品料号已被调整，保存时设置将被覆盖',
        product_items_alerts: '未找到所选产品料号的测量项目/尺寸!, 无效产品料号'
    },
    error_msgs: {
        min_prod_length: '产品料号至少为4位.',
        no_search_data_found: '此次搜索没有找到数据.',
        ca_fields_required: '[异常原因]和[纠正措施]为必填栏位。'
    },
    cms_notification: {
        code_management_label: '纠正措施代码管理',                                
        process_lbl: '制程(部门)',
        code_lbl: '代码',
        code_desc_lbl: '代码描述',
        status_lbl: '状态'
    },
    hold_msg: '正在处理您的请求，请稍候……',
    cds_pci: {
        potential_failure: '潜在的不良率',
        overall: '整体',
        within: '组内',
        total: '合计'
    },
    email_notifications: {        
        dept: '制程(部门)',                        
        stat_abn: '统计异常邮件收件人设置',        
        out_spec: '超出规格上/下限',
        out_cont: '超出控制上限/下限',
        low_cpk: 'CPK值低于标准值',
        save_sett: '保存',
        stat_abn_type: '统计异常的类型',
        email_add: '电子邮件地址',
        add: '新增',
        remove: '删除',
        global_email_label: '全球电子邮件地址列表',
        rec_email_label: '收件人为',
        copy_from_label: '复制自',
        copy_list_but: '复制'
    },
    sel_lang: '选择语言',
    language_popup_alert: '注意:当您选择一种新的语言后，页面将被重定向并跳转到主页，所有未保存的更改内容将丢失。',
    conf_dialog: {
        yes: '是',
        no: '否',
        save_confirm_text: '您确定要保存这些更改吗?',
        cancel_confirm_text: '您确定要取消吗?任何更改都不会被保存。',
        remove_confirm_text: '您确定要删除吗?',
        copy_recp_confirm_text: '您确定要复制电子邮件收件人吗?',
        save_recp_confirm_text: '保存这些更改吗?',
        successMsgSaveRecipients: '已成功保存电子邮件收件人.',
        errorMsgSaveRecipients: '由于错误，无法保存电子邮件收件人.',
        changelost_confirm_text: '所有未保存的更改都将丢失。你确定吗?',
        confirm_label: '确认措施',
        successMsgSaveCAAction: '纠正措施已成功保存.',
        errorMsgSaveCAAction: '由于错误无法保存纠正措施.',
        successMsgRemoveCAAction: '纠正措施已成功删除.',
        errorMsgRemoveCAAction: '由于错误无法删除纠正措施.',
        successCodeAddAction :'代码已成功添加.',
        errorCodeAddAction: '由于错误无法添加代码.',
        successCodeUpdateAction :'代码更新成功.',
        errorCodeUpdateAction: '由于错误无法更新代码.',
        successCodeDeleteAction :'代码已成功删除/取消激活.',
        errorCodeDeleteAction: '由于错误无法删除/停用代码.',
        updateCodeValidationMsg : '代码描述和状态是必填的字段.',
        addCodeValidationMsg : '所有字段都是必需的.',
        successMsgTestsSaved: '已成功保存测试.',
        errorMsgTestsSaved: '由于错误无法保存测试.',
        errorMsgInspPlanNoPDF: '找不到PDF文档.'
    },
    no_entitlement_msg: '请申请授权以便使用此应用程序.',
    no_plants_msg: '请申请相关工厂的访问权限以便使用此应用程序。',
    no_roles_msg: '请申请相关角色的访问权限以便使用此应用程序。',
    grid: {
        body: {
            emptyDataSourceMessage: "没有符合要求的数据",
            addTooltip: '追加新的记录',
            deleteTooltip: '删除记录',
            editTooltip: 'Edit Record',
            filterRow: {
                filterTooltip: '筛选'
            },
            editRow: {
                deleteText: '真的要删除这个记录吗?',
                cancelTooltip: '取消',
                saveTooltip: '保存'
            }
        },
        header: {
            actions: ''
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} of {count}`,
            labelRowsSelect: '',
            firstAriaLabel: '',
            firstTooltip: '第一页',
            previousAriaLabel: '',
            previousTooltip: '前一页',
            nextAriaLabel: '',
            nextTooltip: '后一页',
            lastAriaLabel: '',
            lastTooltip: '最后一页'
        },
        toolbar: {     
            addRemoveColumns: '',
            nRowsSelected: '',
            showColumnsTitle: '',
            showColumnsAriaLabel: '',
            exportTitle: '导出',
            exportAriaLabel: '导出',            
            searchTooltip: '查询',
            searchPlaceholder: '查询'           
        }    
    }
};
