import { en } from './en';
import { ko } from './ko';
import { ja } from './ja';
import { zh } from './zh';

const lang = {
    en: en,
    ko: ko,
    ja: ja,
    zh: zh
}

export default lang;