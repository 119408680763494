import React, { useState, useContext } from 'react';
import Cookies from 'universal-cookie';
import LocalizedStrings from 'react-localization';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import "./LanguagePopup.css";
import lang from '../../lang/lang';
import { store } from '../../stores/store';

const LanguagePopupModal = (props) => {
    const cookies = new Cookies();
    let history = useHistory();
    let globalState = useContext(store);
    const { dispatch } = globalState;
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);

    const languages = [
        {
            code: 'en',
            label: 'English'
        },
        {
            code: 'ko',
            label: '한국어'
        },
        {
            code: 'ja',
            label: '日本語'
        },
        {
            code: 'zh',
            label: '中文'
        }
    ];

    const handleLanguageModalClose = () => {
        props.closeModal();
    };

    function getModalStyle() {
        return {
            top: '10%'
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 870,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        }
    }));

    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();

    const modalBody = (
        <div id="langpopup" style={modalStyle} className={classes.paper}>
            <h3>{strings.sel_lang}</h3>            
            <button className="close" onClick={handleLanguageModalClose}>&times;</button>
            
            <div className='languagepopup-alert-div'>
                <h6 className='languagepopup-alert-text'>{strings.language_popup_alert}</h6>
            </div>
            <div className="content">
                <ul className="languageList" style={{ listStyle: 'none' }}>
                    {languages.map((language,i) => (
                        <li key={i} onClick={() => changeLang(language.code)}>{language.label}</li>
                    ))}
                </ul>
            </div>
        </div>
    );

    var setLocleData = (lang) => {
        try {
            cookies.set('lang', lang, { path: '/' });
        }
        catch (err) {
        }
    }

    var changeLang = (langID) => {
        dispatch({ type: 'select_lang', value: langID });
        setLocleData(langID);
        handleLanguageModalClose();
        history.push("/chartscreen");
    }

    return (
        <Modal
            disableBackdropClick
            open={props.isOpen}
            onClose={handleLanguageModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {modalBody}
        </Modal>
    )
}

export default LanguagePopupModal;