export const ko = {
    menu_labels: {        
        action_history: '조치이력',
        search_chart: '차트검색',
        set_test: '검정조건 설정',
        exit: '나가기',
        language: '언어선택',
        refresh: '새로고침',
        hello: '안녕하세요',
        product_tool_num: '제품번호-툴/설비번호',
        dimension: '치수항목',
        back: '뒤로가기',
        code_mang: '조치코드 관리',
        insp_plan: '검사표준서 보기',
        admin: '시스템 셋팅',        
        stat_abn_rec: '이상발생 메일 수신자관리',
        application_config: '앱 환경설정',
        home_page: '홈페이지'
    },
    input_labels: {
        plant: '플랜트',
        process: '공정',        
        product_number: '제품번호',
        mc_tool_num: '툴/설비번호',        
        search: '검색',
        select_revision: '개정번호 선택: ',
        items_per_page: '페이지별 치수항목수: ',
        save: '저장',
        total_items: '총 치수항목 수',
        clear_dates: '지우기',
        insplanheading: '검사항목 도식(약도)'
    },
    list_labels: {
        pn: '제품번호',
        mc_tool: '설비/툴번호',        
        revision: '개정번호',
        last_date: '최근 측정값 입력일'
    },
    logout_modal_labels: {
        yes_button : '예, 로그아웃 합니다.',
        no_button: '아니오',
        desc: '정말로 로그아웃 하시겠습니까?'
    },
    chart_detail: {
        pci: '공정능력 정보',
        va: '개정번호'        
    },
    cds_search: {
        runs: '최근 100개의 측정값',
        range: '검색 기간 입력',
        refresh: '새로고침',
        select_chart: '차트종류 선택',
        chart_label: '차트'
    },
    cds_notification : {
        sys_not: '시스템 알림',
        cs_sup: '관리자 대책입력',
        cor_act: '조치',
        trcb: '로트 추적성',
        vr: '위배된 검정규칙',
        iss_dat: '발생일',
        val: '측정값',
        lsl: 'LSL',
        lcl: 'LCL',
        ucl: 'UCL',
        usl: 'USL',
        root_cause: '이상발생 사유',
        tak_act: '조치사항',
        act_dat: '조치일',
        owner: '조치자',
        cont: '컨테이너 번호',
        seq: '일련번호',
        actions: '조치내용',
        batchno: '기타 추적성1',
        serialno: '기타 추적성2'
    },
    cds_correctiveaction_modal : {
        but_save: '추가',
        but_cancel: '취소',
        v_rule: '위배된 검정규칙',
        owner: '조치자',
        modified_by: '수정자',
        reason: '이상발생사유',
        action_type: '조치사항',
        remarks : '특이사항',
        but_update: '업데이트',
        but_remove: '제거'
    },
    ahs_labels : {
        date_range : '알림일',        
        prod_numb: '제품번호',
        doc_num: '문서번호',
        ext_trc1: '기타 추적성1',
        ext_trc2: '기타 추적성2',
        reason_jud: '위배된 검정규칙',                                
        reporter: '조치자',
        reason_for_abnormality: '이상발생 사유'        
    },
    tcs_labels : {
        plant_tab: '플랜트',
        plant_tab_text: '플랜트 기본 검정조건 업데이트',
        
        process_tab: '프로세스',
        process_tab_text: '프로세스 검정조건 업데이트',
        process_label: '프로세스',

        product_tab: '제품번호',
        product_tab_text: '제품/치수별 검정조건 업데이트',
        product_label: '제품번호',
        item_label: '치수검사 항목',

        test_item_label: '검정규칙',
        process_product_alert: '선택한 제품의 검정 규칙이 수정되었습니다. 설정값이 덮어쓰여지게 됩니다',
        product_items_alerts: '선택한 제품/치수항목이 없습니다. 유효하지 않은 제품번호입니다'
    },
    error_msgs: {
        min_prod_length: '제품번호는 4자리 이상 입력하여야 합니다',
        no_search_data_found: '검색된 값이 없습니다',
        ca_fields_required: '이상발생 사유, 조치사항은 필수 입력값입니다'
    },
    cms_notification: {
        code_management_label: '조치코드 관리',                                
        process_lbl: '프로세스',
        code_lbl: '코드',
        code_desc_lbl: '코드설명',
        status_lbl: '상태'
    },
    hold_msg: '처리중입니다. 잠시만 기다려주세요...',
    cds_pci: {
        potential_failure: '잠재적 불량률',
        overall: '군간',
        within: '군내',
        total: '전체'
    },
    email_notifications: {        
        dept: '프로세스',                        
        stat_abn: '통계이상치 이메일 수신자관리',        
        out_spec: '규격 상한/하한 초과',
        out_cont: '관리 상한/하한 초관',
        low_cpk: 'Cpk 미달',
        save_sett: '저장',
        stat_abn_type: '통계이상치 종류',
        email_add: '이메일 주소',
        add: '추가',
        remove: '제거',
        global_email_label: '이메일 목록',
        rec_email_label: '수신자',
        copy_from_label: '불러와 복사하기',
        copy_list_but: '복사'
    },
    sel_lang: '언어선택',
    language_popup_alert: '언어 변경시 홈페이지로 이동하게 되며 저장하지 않은 값들을 잃게됩니다',
    conf_dialog: {
        yes: '예',
        no: '아니오',
        save_confirm_text: '변경값을 정말로 저장하시겠습니까?',
        cancel_confirm_text: '정말로 취소하시겠습니까? 변경사항이 저장되지 않습니다',
        remove_confirm_text: '정말로 제거하시겠습니까?',
        copy_recp_confirm_text: '수신자 목록을 복사합니다',
        save_recp_confirm_text: '변경사항을 저장하시겠습니까?',
        successMsgSaveRecipients: '수신자가 성공적으로 저장되었습니다',
        errorMsgSaveRecipients: '에러로 인해 수신자를 저장할 수 없습니다',
        changelost_confirm_text: '저장되지 않은 변경사항을 잃게 됩니다. 진행하시겠습니까?',
        confirm_label: '확인',
        successMsgSaveCAAction: '조치사항이 저장되었습니다',
        errorMsgSaveCAAction: '오류로 인해 조치사항을 저장하지 못하였습니다',
        successMsgRemoveCAAction: '조치사항이 제거되었습니다',
        errorMsgRemoveCAAction: '오류로 인해 조치사항을 제거하지 못하였습니다',
        successCodeAddAction :'조치코드가 추가되었습니다',
        errorCodeAddAction: '오류로 인해 조치코드를 추가하지 못하였습니다',
        successCodeUpdateAction :'조치코드가 업데이트 되었습니다',
        errorCodeUpdateAction: '오류로 인해 조치코드가 업데이트 되지 않았습니다',
        successCodeDeleteAction :'조치코드가 삭제/비활성화 되었습니다',
        errorCodeDeleteAction: '오류로 인해 조치코드가 삭제/비활성화 되지 않았습니다',
        updateCodeValidationMsg : '코드설명과 상태는 필수항목입니다',
        addCodeValidationMsg : '모든 항목이 필수입력값 입니다',
        successMsgTestsSaved: '검정조건이 저장되었습니다',
        errorMsgTestsSaved: '오류로 인해 검정조건이 저장되지 않았습니다',
        errorMsgInspPlanNoPDF: 'PDF파일을 찾을 수 없습니다'
    },
    no_entitlement_msg: '접근 권한이 없습니다. 접근권한을 요청해주세요',
    no_plants_msg: '어플리케이션을 사용하기 위해 공장접근 권한을 신청하세요',
    no_roles_msg: '어플리케이션을 사용하기 위해 필요한 롤 권한을 신청하세요',
    grid: {
        body: {
            emptyDataSourceMessage: '해당 요청에 대한 데이터가 없습니다',
            addTooltip: '새 항목 추가',
            deleteTooltip: '항목 삭제',
            editTooltip: '항목 편집',
            filterRow: {
                filterTooltip: '필터'
            },
            editRow: {
                deleteText: '정말로 삭제하시겠습니까?',
                cancelTooltip: '취소',
                saveTooltip: '저장'
            }
        },
        header: {
            actions: ''
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} of {count}`,
            labelRowsSelect: '',
            firstAriaLabel: '',
            firstTooltip: '첫페이지',
            previousAriaLabel: '',
            previousTooltip: '이전 페이지',
            nextAriaLabel: '',
            nextTooltip: '다음 페이지',
            lastAriaLabel: '',
            lastTooltip: '마지막 페이지'
        },
        toolbar: {     
            addRemoveColumns: '',
            nRowsSelected: '',
            showColumnsTitle: '',
            showColumnsAriaLabel: '',
            exportTitle: '내보내기',
            exportAriaLabel: '내보내기',            
            searchTooltip: '검색',
            searchPlaceholder: '검색'           
        }    
    }
};
