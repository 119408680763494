const AuthHelper =  {

    //scopes: process.env.REACT_APP_SCOPES,
    scopes: "openid profile entitlements",
    /**
     * 
     * @param {String} url 
     */
    getUrlParams(url){
        const regex = /[#?&]([^=#]+)=([^&#]*)/g;
        let params = {};
        let match;
        while ((match = regex.exec(url)) !== null) {        
            params[match[1]] = match[2];            
        }
        return params;
    },

    /**
     * 
     * @param {Array} params 
     * @param {String} key 
     */
    getUrlParam(params, key){
        let sValue = null;
        if(params) {
            sValue = this.sanitizeValue(params[key]);            
        }
        if(!sValue) {
            sValue = this.sanitizeValue(sessionStorage.getItem(key));            
        }
        return sValue;
    },

    /**
     * 
     * @param {Obj} value 
     */
    sanitizeValue(value){
        if(value && (value==='undefined' || value==='null')) return null;
        return (value===undefined) ? null : value;
    },

    /**
     * 
     * @param {*} length 
     */
    generateNonce(length) {
        const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let array = new Uint8Array(length);
        window.crypto.getRandomValues(array);
        array = array.map(x => validChars.charCodeAt(x % validChars.length));
        const randomState = String.fromCharCode.apply(null, array);
        return randomState;        
    },
    
    /**
     * 
     */
    IDPuserInfoUrl() {
        let nonce = this.generateNonce(10)
        const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
        //const encodedOriginal = encodeURI(redirect_uri);

        return process.env.REACT_APP_AUTH_URL +
            '?response_type=id_token' +
            '&client_id=' + process.env.REACT_APP_CLIENT_ID +
            '&scope=' + this.scopes +
            '&redirect_uri=' + redirect_uri +
            '&nonce=' + nonce
    },

    /**
     * 
     */
    IDPtokenUrl() {
        
        let nonce = this.generateNonce(10);
        const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
        //const encodedOriginal = encodeURI(redirect_uri);
        return process.env.REACT_APP_AUTH_URL +
            '?response_type=id_token%20token' +
            '&client_id=' + process.env.REACT_APP_CLIENT_ID +
            '&scope=' + this.scopes +
            '&redirect_uri=' + redirect_uri +
            '&nonce=' + nonce
    },

    IDPcodeUrl() {
        let nonce = this.generateNonce(10);
        const redirect_uri = process.env.REACT_APP_REDIRECT_URL;        
        //const encodedOriginal = encodeURI(redirect_uri);
        return process.env.REACT_APP_AUTH_URL +
            '?response_type=code' +
            '&client_id=' + process.env.REACT_APP_CLIENT_ID +
            '&scope=' + this.scopes +
            '&redirect_uri=' + redirect_uri +
            '&nonce=' + nonce
    },

    AIRCodeUrl(air_access_code, plant_code) {        
        return `/airauth?AIR_access_code=${air_access_code}&plant_code=${plant_code}`;
    }
    
}
export default AuthHelper