import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Xbar from '../charts/xbar'
import '../../App.css';
import { store } from '../../stores/store'
import LocalizedStrings from 'react-localization';
import lang from '../../lang/lang'
import ApiService from '../../services/ApiService'
import ApiConstants from '../../services/ApiConstants'
import styles from './Dimension.module.css';

const Dimension = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();
  //Language selection
  let strings = new LocalizedStrings(lang);
  strings.setLanguage(globalState.state.lang);
  //end of language selection
  const [ifNoData, setIfNoData] = useState(false);

  //Set default placeholder
  const setdefaultPlaceholder = () => {
    return (
      <div id="loading_ph" className="loading-overlay-small">
        <div id="loading-text" className="loading-text-small">
          Loading..
        </div>
      </div>
    )
  }

  //No Content div
  const handleNoContent = () => {
    return (
      <div className={styles.nodata}>
        No Data Available
      </div>
    )
  }

  //Get chart data
  const getChartData = (params, pno, toolno, revno) => {
    var urlParams = {
      item: params.dimension,
      pno: pno,
      toolno: toolno,
      revision: revno
    };

    const oApi = new ApiService();
    oApi.GET(ApiConstants.TestData.Controller, ApiConstants.TestData.Xbar, urlParams).then((response) => {
      var chart;
      var i = 0;
      for (i = 0; i < response.data.data.length; i++) {
        setIsFetched(1)
        if (globalState.state.process_code === '') {
          dispatch({ type: 'set_process', value: { process_code: response.data.data[i].processcode, process_name: response.data.data[i].processname } });
        }
        chart = (<Xbar data={response.data.data[i]} />)
        setAlertTypeColor(
          { backcolor: response.data.data[i].color, textcolor: response.data.data[i].text_color }
        );

      }
      setChartdata(chart)
    }).catch(error => {
      setIsFetched(1);
      setIfNoData(true);
      setChartdata(handleNoContent());
    });
  }

  const [chartData, setChartdata] = useState(() => setdefaultPlaceholder())
  const [isFetched, setIsFetched] = useState(0)
  const [alertTypeColor, setAlertTypeColor] = useState({ backcolor: 'black', textcolor: 'white' });

  const selectedDimension = (pno, dimension, revision, sd_testdate, toolno, plant_code, rev_data) => {
    if (!ifNoData) {
      dispatch({
        type: 'set_chart_detail_screen_data', value: {
          pno: pno,
          dimension: dimension,
          toolno: toolno,
          revision: revision,
          sd_testdate: sd_testdate,
          plant_code: plant_code
        }
      })
      history.push({
        pathname: '/chartdetailscreen',
      })
    }
  }
  //Get chart data
  if (isFetched === 0) {
    getChartData(props.data, props.pno, props.toolno, props.data.revision)
  }

  return (
    <div className={styles.container}

      onClick={() => selectedDimension(
        props.pno,
        props.data.dimension,
        props.data.revision,
        props.sd_testdate,
        props.toolno,
        props.plant_code,
        props.riv_data
      )}
    >

      <div className={styles.label}
        style={{ backgroundColor: alertTypeColor.backcolor, color: alertTypeColor.textcolor }}
        title={props.data.dimension}
      >
        {props.data.dimension}
      </div>
      <div className={styles.graph}
        style={{ border: '8px solid', borderColor: alertTypeColor.backcolor }}
      >
        {chartData}
      </div>

    </div>
  )
}
export default Dimension;