import { Button, Checkbox,  FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import LocalizedStrings from 'react-localization';
import '../../App.css';
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import lang from '../../lang/lang';
import { store } from '../../stores/store';

import Wait from '../../components/Wait';
import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import helperSession from '../../helpers/HelperSession'
import DualListBox from 'react-dual-listbox';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';

import Select from 'react-select';


// Select compoenent style
const selectCustomStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      
      fontSize: 14
    }),
    control: (provided, state) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        backgroundColor: 'white',
        fontSize: 14,
        borderRadius: '5px'      
    }),
    singleValue: (provided, state) => {      
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';  
      return { ...provided, opacity, transition };
    }
}

const StatAbnNotificationScreen = (props) => {
    const globalState = useContext(store);
    const oApi = new ApiService();    
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);

    const [isSaveData,setIsSaveData] = useState(false);
    const [isDeleteData,setIsDeleteData] = useState(false);
    const [isCopyData,setIsCopyData] = useState(false);

    const [severity, setSeverity] = useState('info');
    const [alertMessage, setAlertMessage] = useState('');
    const [isAllProducts, setIsAllProducts] = useState(false);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [recipientDataId,setRecipientDataId] = useState(0);
    const [emailRecipient,setEmailRecipient] = useState({});
    const [emailRecipients,setEmailRecipients] = useState([]);        
    const [processes, setProcesses] = useState([]);
    const [selectedProcess, setSelectedProcess] = useState({});
    const [selectedAbnType, setSelectedAbnType] = useState({});
    const [selectedCopyAbnType, setSelectedCopyAbnType] = useState({});
    const [emailIsDisabled, setEmailIsDisabled] = useState(false);
    const [abnTypes, setAbnTypes] = useState([]);

    
    /**
     * Initial load
     */
    useEffect(() => {
        wait();        
        getEmails();
        getAbnTypes();
        getProcesses();
        
        //console.log(helperSession.profile);
        
        //getProcessProducts(selectedProcess)
    }, []);
    
    const checkPermissions = ()=>{
        const entitlements = helperSession.profile.entitlements || [];
        if(entitlements.includes('system_admin')){
            setEmailIsDisabled(false)
        } else {
            setEmailIsDisabled(true)
        }

        
    }
    /**
     * Show waiting spinnig
     */
    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';        
    }

    /**
     * Hide waiting spinner
     */
    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';        
    }

    /**
     * Get list of all available emails
     */
    const getEmails = () => {        
        const emailOptions = []
        oApi.GET(ApiConstants.User.Controller, ApiConstants.User.Air_Users, null).then((response) => {
            stopWaiting();
            const emailList = response.data.data;
            
            for (let i = 0; i < emailList.length; i++) {
                if (emailList[i].email){
                    const option = {value: emailList[i].email, label: emailList[i].email }            
                    emailOptions.push(option);
                }
            }            
            setEmailRecipients(emailOptions);   
            const userEmail=helperSession.profile.emailAddress;
            if(userEmail){
                const val = emailOptions.find(x=> x.value.toLowerCase() === userEmail.toLowerCase());                
                setEmailRecipient(val)
            }         
            checkPermissions();
        }).catch(error => {
            stopWaiting();
        });
    }

    /**
     * Get list of available processes
     */
    const getProcesses = () => {
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Processes, null).then((response) => {
            const processList = response.data.data;            
            const processOptions=[]
            processes.push(<option key={0} value=''></option>);
            for (let i = 0; i < processList.length; i++) {                
                if (processList[i]['isUsed'] === true ){
                    const processCode = processList[i].process_code;
                    if(processCode!=="*"){
                        const processName = processCode + ': '+processList[i].process;
                        const option = {value: processCode, label: processName };
                        processOptions.push(option);
                    }
                }
            }
            setProcesses(processOptions)            
        }).catch(error => {
            stopWaiting();
        });
    }

    /**
     * Get available Abnornamlity Types
     */
     const getAbnTypes = () => {
        oApi.GET(ApiConstants.Notify.Controller, ApiConstants.Notify.Abnormality_List, null).then((response) => {
            stopWaiting();
            const typesList = response.data.data;            
            const abnTypeOptions=[ {value: '*', label: 'All'}];
            
            for (let i = 0; i < typesList.length; i++) {
                const code = typesList[i].abnormality_code;
                const name = typesList[i].abnormality;
                const option = {value: code, label: name};
                abnTypeOptions.push(option)                                
            }
            setAbnTypes(abnTypeOptions);            
        }).catch(error => {
            stopWaiting();
        });
    }

    /**
     * Get list of product for a given process
     * @param {String} processCode 
     */
    const getProcessProducts = (processCode) => {
        
        if(!processCode) return
        wait()
        // setSelectedProducts([]);
        const params = {
            process_code: processCode
        };
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.ProcessProducts, params).then((response) => {
            const data = response.data.data || []            
            const product_options = []            
            
            for (let i = 0; i < data.length; i++) {
                const record = data[i];
                product_options.push({value: record.product, label: record.product})
            }            
            setProducts(product_options); 
            
            stopWaiting();           
            
        }).catch(error => {
            console.log(error);
            stopWaiting();
        });
    }   

    /**
     * Handle email selection change
     * @param {String} newValue 
     */
     const emailChangeHandler = (newValue) => {        
        setEmailRecipient(newValue)
        clearRecipient();        
    }    

    /**
     * Handle ABN selection change
     * @param {String} newValue 
     */
    const abnTypeChangeHandler = (newValue) => {        
    
        setRecipientDataId(0)
        const abnCode = newValue.value
        setSelectedAbnType(newValue)
        getRecipientData(abnCode);    
    }    

    const copyAbnTypeChangeHandler = (newValue) => {                
        setSelectedCopyAbnType(newValue)
    }   

    /**
     * Get recipient products for selected recip[ient]     
     * @param {String} abnCode 
     * @returns 
     */
    const getRecipientData = ( abnCode) => {                
        if(!abnCode) abnCode = selectedAbnType.value;
        // If either is not defined, just rturn        
        clearProcessData();
        let params = {
            email: emailRecipient.value,
            abnormality_code: abnCode
        };        
        
        //oApi.GET(ApiConstants.Notify.Controller, ApiConstants.Notify.Recipients, params).then((response) => {
        oApi.GET(ApiConstants.Notify.Controller, "recipient_data", params).then((response) => {
            stopWaiting();
            
            if(response.data.data.length===0) return;
            const record = response.data.data[0]; 
            let products=[];
            setIsAllProducts(record.Pno==='*')                
            if(record.Pno !== '*' ) {                
                if(record.Pno) products = record.Pno.split(',').map(x => `${x}` )
            }
            setRecipientDataId(record.ID);
            
            //setSelectedProducts(products);                        
            // Select process
            const val = processes.find(x=> x.value === record.process_code);            
            processChangeHandler(val, products)
        }).catch(error => {
            console.log(error)
            stopWaiting();
        });
    }

    const clearProcessData = () =>{
        setSelectedProcess({})
        setSelectedProducts([]);
        setProducts([]);
    }

    const clearRecipient = () => {
        setRecipientDataId(0);
        setSelectedAbnType('');
        setSelectedCopyAbnType('');
        clearProcessData();

    }

    const setAlert = (message, severity)=>{
        setAlertMessage(message || '')
        setSeverity( severity || "info")
    }

    /**
     * Check if required data available
     * @returns true,false
     */
    const isValidInput = ()=>{

        setAlert();

        let errorMessage = ''
        if(!emailRecipient) errorMessage +='  - Recipient email not specified';
        if(!selectedAbnType.value) errorMessage +='  - Abnormality type not specified';
        if(!selectedProcess.value) errorMessage +='  - Process not specified';
        if(selectedProducts.length===0 && !isAllProducts) errorMessage+='  - Product(s) not specified';
        if(errorMessage){
            setAlert(errorMessage, "error")
            return false;
        }
        return true
    }
    /**
     * Update recipient data
     * @returns 
     */
    const updateRecipientData = ()=>{
        wait();
        const productCodes = isAllProducts ? '*' : selectedProducts.join(',')
        const body={
            id: recipientDataId,
            email: emailRecipient.value,
            abnormality_code: selectedAbnType.value,
            process_code: selectedProcess.value,
            product_codes: productCodes
        }

        oApi.POST(ApiConstants.Notify.Controller, ApiConstants.Notify.Add_Recipients, null, body).then((response) => {
            
            setRecipientDataId(response.data.ID);
            setAlert('Data saved','success')
            stopWaiting();
            
        }).catch(error => {            
            setAlert('Error Saving recipient: ' + JSON.stringify(error.response.data), 'error')
            stopWaiting();
        });
    }

    /**
     * Update recipient data
     * @returns 
     */
     const deleteRecipientData = ()=>{
        wait();
        
        const queryParams={
            id: recipientDataId,
            email: emailRecipient.value,
            abnormality_code: selectedAbnType.value            
        }

        oApi.GET(ApiConstants.Notify.Controller, 'delete_recipient', queryParams).then((response) => {
            setAlert('Data rrmoved','success')
            stopWaiting();
            setRecipientDataId(0)
            
        }).catch( (error) => {            
            setAlert('Error Saving recipient: ' + JSON.stringify(error.response.data), 'error')
            stopWaiting();
        });
    }

    /**
     * copy  recipient data to new abn code
     * @returns 
     */
     const copyRecipientData = ()=>{
        const toAbnCode = selectedCopyAbnType.value
        if(toAbnCode === selectedAbnType.value) {
            setAlert('Copy to Abnormailty cannot be same as source Abnormality', 'error')
            return;
        }
        const queryParams={
            id: recipientDataId,            
            to_abn_code: toAbnCode          
        }

        wait();

        oApi.GET(ApiConstants.Notify.Controller, 'copy_recipient', queryParams).then((response) => {
            setAlert('Datat copied','success')
            getRecipientData(toAbnCode);    
            stopWaiting();
        }).catch( (error) => {            
            setAlert('Error Copy Data: ' + JSON.stringify(error.response.data), 'error')
            stopWaiting();
        });
    }

    /**
     * Handle process selection chang
     * @param {String} newValue, newly selected process code
     */
    const processChangeHandler = (newValue, products) => {

        // If products is not passed then clear existing selected products
        if(!products) products=[]
        setSelectedProducts(products);                        
        
        const processCode = newValue.value
        setSelectedProcess(newValue);
        getProcessProducts(processCode);        
    }    

    const allProductsChangeHandler = (event) => {
        const checked = event.target.checked;        
        setIsAllProducts(checked)
    }    

    const handleRefresh = ()=> {}    
        
    return (
        <div className="App" >
            
            <TopBar strings={strings} />
            <header className="App-header">
                <div className='ns-main-div'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {alertMessage && <Alert severity={severity}>{alertMessage} </Alert>}
                        </Grid>                        
                        
                        <Grid item xs={8}>
                            <Alert severity="info"> 
                                <Typography variant="subtitle1" >{strings.email_notifications.stat_abn}</Typography>
                            </Alert>                            
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="primary" startIcon={<SaveIcon />}
                                    onClick={()=> { if(isValidInput()) setIsSaveData(true) }  } >{strings.email_notifications.save_sett}
                            </Button>                            
                        </Grid>                        
                        <Grid item xs={1}>
                            
                            { (recipientDataId>0) &&
                                <Button variant="contained" color="secondary" startIcon={<DeleteIcon />}
                                    onClick={()=> setIsDeleteData(true) } >{strings.email_notifications.remove}
                                </Button>
                            }
                        </Grid>                        

                        <Grid item xs={6} >                            
                            <Typography variant="subtitle2" >{strings.email_notifications.email_add}</Typography>
                            <Select options={emailRecipients} value={emailRecipient} styles={selectCustomStyle} isDisabled={emailIsDisabled}
                                onChange={value => emailChangeHandler(value)}
                            />
                        </Grid>                        
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">{strings.email_notifications.stat_abn_type}</Typography>
                            <Select options={abnTypes} value={selectedAbnType} styles={selectCustomStyle} onChange={value => abnTypeChangeHandler(value)} />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="subtitle2">{strings.email_notifications.dept}</Typography>                            
                            <Select options={processes} value={selectedProcess} styles={selectCustomStyle} 
                                onChange={value => processChangeHandler(value)}
                            />                            
                        </Grid>
                        <Grid item xs={5}>                                      
                            
                            { (recipientDataId>0) &&                            
                            <>
                            <Typography variant="subtitle2">{strings.email_notifications.copy_stat_abn_type}</Typography>
                            <Select options={abnTypes} value={selectedCopyAbnType} styles={selectCustomStyle} onChange={value => copyAbnTypeChangeHandler(value)} />
                            </>
}
                        </Grid>
                        <Grid item xs={1} style={{marginTop: 20}}>
                            { (recipientDataId>0) &&                            
                                <Button variant="contained" color="primary" startIcon={<CopyIcon />}
                                    onClick={()=> setIsCopyData(true) } >{strings.email_notifications.copy_list_but}
                                </Button>
                            }
                        </Grid>
                                                
                        <Grid xs={12} style={{backgroundColor: 'white', color: 'black', borderRadius: '5px', margin: 5}}>                            
                            <Typography variant="subtitle2" >&nbsp;Assigned Products:  &nbsp;&nbsp;                                
                                <FormControlLabel 
                                    label="All Products" 
                                    control={ <Checkbox  checked={isAllProducts}  onChange={allProductsChangeHandler} />}      
                                />                                
                            </Typography>
                            
                            <div style={{height: "350px", width: "80%", margin: "5px auto"}}>
                            {
                                !isAllProducts &&
                                <DualListBox    
                                    options={products} selected={selectedProducts} 
                                    canFilter showHeaderLabels={false}                  
                                    onChange = {(selected => {                                    
                                    setSelectedProducts(selected)
                                })}
                            />
                            }
                            </div>
                        </Grid>
                    </Grid>
                </div>                
            </header>
            <ConfirmationModal isOpen={isSaveData} text={`Save recipient data?`}
                handleYes={ ()=> {
                    setIsSaveData(false);
                    updateRecipientData();                    
                }} 
                handleNo={ ()=> {
                    setIsSaveData(false);
                }}  
            />
            <ConfirmationModal isOpen={isDeleteData} text={`Remove recipient data?`}
                handleYes={ ()=> {
                    setIsDeleteData(false);
                    deleteRecipientData();                    
                }} 
                handleNo={ ()=> {
                    setIsDeleteData(false);
                }}  
            />
            <ConfirmationModal isOpen={isCopyData} text={`Copy recipient data?`}
                handleYes={ ()=> {
                    setIsCopyData(false);
                    copyRecipientData();
                }} 
                handleNo={ ()=> {
                    setIsCopyData(false);
                }}  
            />


            <br /><br /><br /><br />
            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>                    
            </div>

            <BottomBar strings={strings} handleRefresh= {handleRefresh}/>
            
        </div>
    );
}

export default StatAbnNotificationScreen;