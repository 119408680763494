import React, { useContext } from 'react';
import { store } from './stores/store'
import { BrowserRouter } from "react-router-dom"
import Root from "./Root";
import Cookies from 'universal-cookie';
import authHelper from "./auth/AuthHelper";
import helperSession from '../src/helpers/HelperSession';
import helperCommon from '../src/helpers/HelperCommon';

import userAccessHandler from "../src/auth/UserAccessHandler";

const cookies = new Cookies();
function App() {

  const globalState = useContext(store);
  const { dispatch } = globalState;  
  
  // Store url parameters
  const sUrl = window.location.href ? window.location.href : "";
  const urlParams = authHelper.getUrlParams(sUrl); 
  helperSession.setAppSession(urlParams);

  var getLang = () => {
    if (navigator.languages)
      return navigator.languages[1];
    else
      return navigator.language;
  }
  
  var setLocaleData = () => {
    try {
      cookies.set('lang', getLang(), { path: '/' });
    }
    catch (err) {
      console.log("Error setting cookie! ", err)
    }
  }

  const setLanguage = () =>{
    if (localStorage.getItem('language')) dispatch({ type: 'select_lang', value: localStorage.getItem('language') });
    else dispatch({ type: 'select_lang', value: getLang() });    
  }

  const authorizeUser = async (urlParams, code, air_access_code) => {    
    //helperSession.setAppSession(urlParams);
    const access_code = air_access_code ? air_access_code : code;
    try{
      
      await userAccessHandler.getUserAccessData(access_code);       
      await helperCommon.GetPlants();
      //dispatch({ type: 'update_plant', value: {plant_code: helperSession.plant_code, plant_name:  helperSession.plant_name} });
      
      window.location.href = process.env.REACT_APP_SPC_URL;      
    } catch(error){
      console.log("*** Error: ",error.message)
      if(!window.location.state) window.location.state = {};
      window.location.state.detail = error.message;
      window.location.href = process.env.REACT_APP_SPC_URL+"/error?detail="+error.message;      
    }
  }  

  if(sUrl.indexOf("/error") >=0) {
    //setLanguage();
  } else {
    const urlParams = authHelper.getUrlParams(window.location.href); 
    const air_access_code= authHelper.getUrlParam(urlParams,'AIR_access_code');
    const code= authHelper.getUrlParam(urlParams,'code');
    const access_token = air_access_code ? null : helperSession.access_token;

    if(!access_token) {
      if(code || air_access_code){
        // we have access code then get user access        
        authorizeUser(urlParams, code, air_access_code);
      } else {
        // call IDP to authenticate
        window.location = authHelper.IDPcodeUrl();
      }
    } else {
      setLanguage();
    } 
  }

  if (cookies.get('lang') == null) {
    setLocaleData()
  }

  return (
    < BrowserRouter >
      <Root />
    </BrowserRouter >
  )
}

export default App;
