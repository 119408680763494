import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { checkRole } from '../../helpers/Utilities';
import { ACTION_HISTORY_SCREEN, CODE_MANAGEMENT_SCREEN, INSPECTION_PLAN_SCREEN, SEARCH_CHART_SCREEN, STATISTICAL_ABNORMALITY_SETTINGS, TEST_CRITERIA_SCREEN, APPLICATION_CONFIG_SCREEN } from '../../resources/securityRoles';
import { store } from '../../stores/store';
import MenuButton from '../MenuButton/MenuButton';

import CDOjectEditor from '../CDObjectEditor/CDObjectEditor';

import InspectionPlan from '../CDInspectionPlan/CDInspectionPlan';

import helperSession from '../../helpers/HelperSession';
import helperCommon from '../../helpers/HelperCommon';

const TopBar = (props) => {


    const version = '1.0.0.100';

    let strings = props.strings;
    const globalState = useContext(store);
    let history = useHistory();
    const { dispatch } = globalState;

    const env = helperSession.env;
    const plant_code = helperSession.plant_code;

    const [connectionColor, setConnectionColor] = useState('green');
    const [sessionEnv, setSessionEnv] = useState('');
    const [serverColor, setServerColor] = useState('grey');
    
    useEffect(() => {        
        helperCommon.isConnectedCallback((connected) => {            
            const sColor = connected ? 'green' : 'red';    
            setConnectionColor(sColor);        
            setSessionEnvAndColor();
        })        
    }, [plant_code]);

    useEffect(() => {
        setSessionEnvAndColor();
    }, [env]);

    function setSessionEnvAndColor(){
        const sEnv = helperSession.env ? helperSession.env.toUpperCase() : "";
        setSessionEnv(`Version: ${sEnv} - ${version}`);
        //setSessionEnv(sEnv);
        
        let sColor =  "grey";
        if(sEnv ==="TST") sColor="#ff4d4d";
        else if(sEnv==="PROD") sColor="green";        
        setServerColor(sColor);        
    }

    function getModalStyle() {
        return {
            top: '15%',
            left: '37%'
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [exitDialogFlag, setExitDialogFlag] = React.useState(false);
    const [userInfoFlag, setUserInfoFlag] = React.useState(false);
    const [inspectionPlanFlag, setInspectionPlanFlag] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const checkConnection = async () => {

        const isConnected = await helperCommon.isConnected()
        if(isConnected) {
            setConnectionColor('green');
            alert("Connected");
        } else {
            setConnectionColor('red');
            alert("NOT Connected");
        }        
    }

    const handleOpenExitDialog = () => {
        setExitDialogFlag(true);
    };

    const handleCloseExitDialog = () => {
        setExitDialogFlag(false);
    };

    const handleOpenUserInfoDialog = () => {
        setUserInfoFlag(true);
    };

    const handleCloseUserInfoDialog = () => {
        setUserInfoFlag(false);
    };

    const handleOpenInspectionPlanDialog = () => {
        setInspectionPlanFlag(true);
    };

    const handleCloseInspectionPlanDialog = () => {
        setInspectionPlanFlag(false);        
    };

    const handleClickAnchor = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    };

    const handleAnchorClick = (val) => {
        handleCloseAnchor();
        switch (val) {
            case 'statabnrec':
                history.push('/statabnnotificationscreen')
                break;
            case 'settest':
                history.push('/testscreen')
                break;
            case 'codemanagement':
                history.push('/codemanagementscreen')
                break;
            case 'applicationconfig':
                history.push('/applicationconfigscreen')
                break;
            case 'inspectionplan':                
                //history.push('<Link to="/inspectionplanscreen" target="_blank">Hello World</Link>')
                //history.push('/inspectionplanscreen')
                handleOpenInspectionPlanDialog();
                break;
            default:
                break;
        }
    };

    const logUserOut = () => {
        dispatch({ type: 'update_token', value: '' });
        dispatch({ type: 'clear_user_data' });
        helperSession.clearAppSession();
        window.location = '/';

        //window.location = 'https://auth.kochid.com/idp/startSLO.ping';        
    }

    const promptExitContentmodalBody = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Molex SPC</h2>
            <hr size="-1" />
            <p id="simple-modal-description">
                {strings.logout_modal_labels.desc}
            </p>
            <br />
            <Button variant="contained" color="primary" onClick={logUserOut}>{strings.logout_modal_labels.yes_button}</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" color="primary" onClick={handleCloseExitDialog}>{strings.logout_modal_labels.no_button}</Button>
            <br />
        </div>
    );

    return (
        <AppBar position='fixed' style={{ backgroundColor: '#E0E0E0 ' }}>
            <Toolbar>
                <Grid container alignItems="center"
                    justify="center">
                    <Grid item xs={3}>
                        <Grid container justify="flex-start">
                            <Grid item xs={2} align='right' style={{ marginTop: '8px' }}>
                                <MenuButton icon="fa fa-home" goto="charts" label={''} disabled={!checkRole(SEARCH_CHART_SCREEN)} />
                            </Grid>
                            <Grid item xs={10} align='left'>
                                <Typography color="inherit" style={{ color: 'black', fontWeight: '600', fontSize: '20px', display: 'inline' }}>
                                    Molex SPC &nbsp; 1.3.1.3
                                    <i className="fa fa-server" style={{ color: serverColor , fontSize: '22px' }} title={sessionEnv}></i>
                                </Typography> &nbsp;&nbsp;&nbsp;
                                <Typography color="inherit" style={{ cursor: 'pointer', color: 'black', fontWeight: '600', fontSize: '20px', display: 'inline' }}>
                                    <a href={'/chartscreen'} rel="noopener noreferrer" target='_blank' disabled={!checkRole(SEARCH_CHART_SCREEN)}><i className="fa fa-plus"></i></a>
                                </Typography>
                                <br />
                                <Typography style={{ color: 'black', fontSize: '14px', display: 'inline' }}>{strings.menu_labels.hello}, </Typography>
                                <Typography style={{ color: 'black', fontWeight: '600', fontSize: '14px', display: 'inline' }}>
                                    {helperSession.profile.firstName + ' ' + helperSession.profile.lastName}
                                    &nbsp;<i className="fa fa-info-circle" style={{ cursor: 'pointer' }} onClick={handleOpenUserInfoDialog} title="User Info"></i>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container justify="flex-start">
                            <div className='spc-topbar-userprod-info'>
                                {helperSession.plant_code && 
                                    <>
                                        <Typography className='inline-text-14-black' style={{ display: 'inline' }}>
                                            <i className="fa fa-plug" style={{ color: connectionColor , cursor: 'pointer', fontSize: '18px' }} onClick={checkConnection} title="Check Connection"></i>
                                            &nbsp;{strings.input_labels.plant}: 
                                        </Typography> 
                                        <Typography className='inline-text-14-black-bold'>({globalState.state.plant_code})&nbsp;{globalState.state.plant_name}
                                            
                                        </Typography>
                                    </>
                                }
                                {props.process && <>&nbsp;&nbsp;&nbsp;&nbsp;<Typography className='inline-text-14-black' style={{ display: 'inline' }}>{strings.input_labels.process}: </Typography> <Typography className='inline-text-14-black-bold'>{props.process} </Typography></>}
                                {props.pno && <><br /><Typography style={{ color: 'black', fontSize: '14px', display: 'inline' }}>{strings.menu_labels.product_tool_num}: </Typography> <Typography style={{ color: 'black', fontWeight: '600', fontSize: '14px', display: 'inline' }}>{`${props.pno} - ${props.toolno}`}</Typography></>}
                                {props.dimension && <><br /><Typography style={{ color: 'black', fontSize: '14px', display: 'inline' }}>{strings.menu_labels.dimension}: </Typography> <div style={{ textAlign: 'left', display: 'inline' }}>
                                    <select name="cds-dimension-dd" id="cds-dimension-dd" onChange={e => props.handleChangeDimension(e.target.value)}>
                                        {props.dimensionList}
                                    </select>
                                </div></>}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container justify="flex-end">

                            <div className='MenuButton d-flex flex-column' disabled={!checkRole(SEARCH_CHART_SCREEN)} onClick={handleClickAnchor}>
                                <i className={"fa fa-cog"}></i><i className={"fa fa-angle-down"} style={{ fontSize: '16px' }}></i><br />
                                <span>{strings.menu_labels.admin}</span>
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseAnchor}
                            >
                                
                                <MenuItem onClick={() => handleAnchorClick('statabnrec')}>
                                    <div className='MenuButton d-flex flex-column'>
                                        <i className={"fa fa-envelope"}></i> &nbsp;&nbsp;
                                        <span>{strings.menu_labels.stat_abn_rec}</span>
                                    </div>
                                </MenuItem>
                                <hr size='-1' />
                                <MenuItem disabled={!checkRole(TEST_CRITERIA_SCREEN)} onClick={() => handleAnchorClick('settest')}>
                                    <div className='MenuButton d-flex flex-column'>
                                        <i className={"fa fa-tasks"}></i> &nbsp;&nbsp;
                                         <span>{strings.menu_labels.set_test}</span>
                                    </div>
                                </MenuItem>
                                <MenuItem disabled={!checkRole(CODE_MANAGEMENT_SCREEN)} onClick={() => handleAnchorClick('codemanagement')}>
                                    <div className='MenuButton d-flex flex-column'>
                                        <i className={"fa fa-list-ol"}></i> &nbsp;&nbsp;&nbsp;
                                        <span>{strings.menu_labels.code_mang}</span>
                                    </div>
                                </MenuItem>
                                <MenuItem disabled={!checkRole(APPLICATION_CONFIG_SCREEN)} onClick={() => handleAnchorClick('applicationconfig')}>
                                    <div className='MenuButton d-flex flex-column'>
                                        <i className={"fa fa-wrench"}></i> &nbsp;&nbsp;&nbsp;
                                        <span>{strings.menu_labels.application_config}</span>
                                    </div>
                                </MenuItem>
                            </Menu>
                            <MenuButton icon="fa-history" goto="history" label={strings.menu_labels.action_history} disabled={!checkRole(ACTION_HISTORY_SCREEN)} />
                            
                            <div className='MenuButton d-flex flex-column' disabled={!checkRole(INSPECTION_PLAN_SCREEN) || !props.showInspIcon} onClick={() => handleAnchorClick('inspectionplan')} >
                                <i className={"fa fa-file-text"}></i><br />
                                <span>{strings.menu_labels.insp_plan}</span>
                            </div>
                           
                            <div className='MenuButton d-flex flex-column' disabled={!checkRole(SEARCH_CHART_SCREEN)} onClick={handleOpenExitDialog}>
                                <i className={"fa fa-times-circle"}></i><br />
                                <span>{strings.menu_labels.exit}</span>
                            </div>
                            <>
                                <Modal open={exitDialogFlag} onClose={handleCloseExitDialog} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                                    {promptExitContentmodalBody}                                    
                                </Modal>

                                <Modal open={userInfoFlag} onClose={handleCloseUserInfoDialog} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                                    <CDOjectEditor object_data={globalState.state.profile} width="500px" name="Profile"
                                        title="User Profile" collapsed="1" handleClose={handleCloseUserInfoDialog}></CDOjectEditor>
                                </Modal>

                                <Modal open={inspectionPlanFlag} onClose={handleCloseInspectionPlanDialog} 
                                    disableBackdropClick = {true}
                                    aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
                                >
                                    <div style={{width: "80%", margin: "0px auto", marginTop: "10px", backgroundColor: "white"}}>
                                        <h3 style={{backgroundColor: "lightGray"}}> 
                                            &nbsp;&nbsp;
                                            <i className="fa fa-times" onClick={handleCloseInspectionPlanDialog} title="Close"></i>
                                            &nbsp;&nbsp;
                                            Inspection Plan                                            
                                        </h3>
                                        <InspectionPlan></InspectionPlan>
                                    </div>
                                    
                                </Modal> 
                            </>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;