import Grid from '@material-ui/core/Grid';

import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext, useState } from 'react';
import LocalizedStrings from 'react-localization';
import { useHistory } from "react-router-dom";

import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import lang from '../../lang/lang';
import { store } from '../../stores/store';
import { SEARCH_CHART_SCREEN } from '../../resources/securityRoles';
import { checkRole, convertDateToYYYYMMDD } from '../../helpers/Utilities';
import Wait from "../../components/Wait"

import ApiService from '../../services/ApiService'
import ApiConstants from '../../services/ApiConstants'

import helperSession from '../../helpers/HelperSession'

import '../../App.css';
// import HelperCommon from '../../helpers/HelperCommon';

const ChartScreen = (props) => {

    const globalState = useContext(store);
    const { dispatch } = globalState;    
    let history = useHistory();

    const oApi = new ApiService();
    const selectedRecord = (pno, revision, sd_testdate, toolno, plant_code, process_code, process_name) => {
        dispatch({ type: 'set_revision_screen_data', value: {pno: pno,
            toolno: toolno,
            revision: revision,
            sd_testdate: sd_testdate,
            plant_code: plant_code} })
        dispatch({ type: 'set_process', value: {process_code, process_name} })
        history.push({
            pathname: '/revisionscreen'
        })
    }

    const setDefaultPlants = () => {
        return (
            <option value='select'>Select required plant</option>
        )
    }

    // const setDefaultProcess = () => {
    //     return (
    //         <option value='select'>Select required process</option>
    //     )
    // }

    const updatePlants = (fetchedPlants) => {
        setIsFetched(1)
        setPlantdata(fetchedPlants);
    }
  
    const updateTabledata = (fetchedResult) => {
        setTabledata(fetchedResult);
    }


    const [plantsData, setPlantdata] = useState(() => setDefaultPlants())
    const [tableData, setTabledata] = useState()
    const [isFetched, setIsFetched] = useState(0)
    const [error, setError] = useState('');

    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';
    }

    const handleKeyDown = (e) => {
        if (e.key==='Enter') {
           search();
        }
    }

    const search = () => {
        setError('');
        if (document.getElementById("cs_prodnum").value.length<4)
            setError(strings.error_msgs.min_prod_length);
        else {
        wait();
        var searchParams = {
            pno: document.getElementById("cs_prodnum").value,
            toolno: document.getElementById("cs_toolnum").value,
            count: 100
        };
        const header = {
            plant_code: document.getElementById('plant').value
        };
        
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Search_Product_Setup, searchParams, header).then((response) => {
            stopWaiting()
            var recordsArray = []
            var i = 0;
            
            if (response.data.data.length===0)
                setError(strings.error_msgs.no_search_data_found);

            for (i = 0; i < response.data.data.length; i++) {
                const pno = response.data.data[i].pno;
                const toolno = response.data.data[i].toolno;
                const revision = response.data.data[i].revision;
                const sd_testdate = response.data.data[i].sd_testdate;
                const process_code = response.data.data[i].process_code;
                const process_name = response.data.data[i].process_name;                
                
                recordsArray.push(<tr className='table-row' key={i} onClick={() => selectedRecord(pno, revision, sd_testdate, toolno, document.getElementById('plant').value , process_code, process_name)}>
                    <td className='table-cell'>{response.data.data[i].pno}</td>
                    <td className='table-cell'>{response.data.data[i].toolno}</td>
                    <td className='table-cell'>{response.data.data[i].revisionno}</td>
                    <td className='table-cell'>{convertDateToYYYYMMDD(response.data.data[i].sd_testdate)}</td>
                </tr>)
            }

            updateTabledata(recordsArray)
            document.getElementById('dataGrid').style.visibility = 'visible'
        })
            .catch(error => {
                stopWaiting()
            });
        }

    }

    const onPlantSelect = (event) => {        
        const plant_code = event.target.value;
        const UpdateState = (plant_code!==globalState.state.plant_code);
        if(UpdateState){
            const plant = document.getElementById("plant");
            const plant_name = plant.options[plant.selectedIndex].text;
            helperSession.plant_code = plant_code;
            helperSession.plant_name = plant_name;
            dispatch({ type: 'update_plant', value: {plant_code: helperSession.plant_code, plant_name:  helperSession.plant_name} });               
            // Check connection
            //HelperCommon.isConnected();
        }
    }

    let strings = new LocalizedStrings(lang);

    strings.setLanguage(globalState.state.lang);


    //Get plants list
    const getPlantsList = () => {        
        
        const render_plants=[];
        const plantList = helperSession.plants;
        const plant_code = helperSession.plant_code;
        let plantFound = false;        
        stopWaiting();            
        
        plantList.forEach( (plant, inx) => {
            render_plants.push(<option defaultValue={plant_code} key={inx} value={plant.plant_code}>{plant.plant_name}</option>)
            if(plant.plant_code == plant_code) plantFound = true;            
        });
        if(plantFound) {
            updatePlants(render_plants);            
        } else {
            let sMessage = "";
            if(plantList.length === 0) sMessage = "User is not assigned to any plant";
            else sMessage = `Invalid Plant Code: ${plant_code}`;
            history.push({pathname: '/error', state: {detail: sMessage} });
        }
    }

    const handleRefresh = () => {

    }

    //Get and list plants first[on pageload]
    if (isFetched === 0) {
        getPlantsList();        
    }

    if (checkRole(SEARCH_CHART_SCREEN)){
    return (
        <div className="App" >
            <TopBar strings={strings} />
            {error && <Alert className="app-alert-style" variant="filled" severity="error" onClose={() => setError('')}>
                <AlertTitle>{error}</AlertTitle>    
            </Alert>}
            <header className="App-header">
                <br/>
                <div className='cs-main-div'>
                    <Grid container spacing={2} justify="flex-start">

                        <Grid item xs={12}>
                            <div className="input-ph d-flex flex-row display-inline-block">
                                <span className='text'>{strings.input_labels.plant}</span>
                                <select defaultValue={helperSession.plant_code} name="plant" id="plant" onChange={onPlantSelect} disabled={!checkRole(SEARCH_CHART_SCREEN)}>
                                    {plantsData}
                                </select>
                            </div>
                            
                            <div className="input-ph d-flex flex-row display-inline-block">
                            <span className='text'>{strings.input_labels.product_number} *</span>
                                <input name="productNumber" disabled={!checkRole(SEARCH_CHART_SCREEN)} id="cs_prodnum" size="16" title="4 digits minimum" onKeyDown={handleKeyDown} required />
                            </div>

                            <div className="input-ph d-flex flex-row display-inline-block">
                            <span className='text'>{strings.input_labels.mc_tool_num}</span>
                                <input name="MCToolNumber" disabled={!checkRole(SEARCH_CHART_SCREEN)} id="cs_toolnum" size="15" onKeyDown={handleKeyDown} />
                            </div>

                            <div className="input-ph d-flex flex-row display-inline-block">
                                <br /><button className="input-button" disabled={!checkRole(SEARCH_CHART_SCREEN)} onClick={search}>{strings.input_labels.search}</button>
                            </div>


                        </Grid>

                        <Grid item xs={12}>

                            <table className="spc-header">

                                <tbody className='table-header'>

                                    <tr>
                                        <td className='table-head'>{strings.list_labels.pn}</td>
                                        <td className='table-head'>{strings.list_labels.mc_tool}</td>
                                        <td className='table-head'>{strings.list_labels.revision}</td>
                                        <td className='table-head'>{strings.list_labels.last_date}</td>
                                    </tr>

                                </tbody>

                            </table>
                        </Grid>

                        <Grid item xs={12} className="grid-h2" id="dataGrid" container justify="flex-start">
                            <table className="spc-table" id="resultTable">

                                <tbody id="tableBody" className="table-body">
                                    {tableData}
                                </tbody>

                            </table>
                        </Grid>

                    </Grid>
                </div>

            </header>

            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>              
            </div>

            <BottomBar strings={strings} handleRefresh={handleRefresh}/>
        </div>
    );
    }
    else {
        return (
            <div className="App" >
                <TopBar strings={strings} />
                <header className="App-header">
                    <div style={{ textAlign: 'center', width: '60%' }}>
                        <h2>{strings.no_entitlement_msg}</h2>
                    </div>
                </header>

                <div id="loading_ph" className="loading-overlay">
                    <Wait message={strings.hold_msg} >
                    </Wait>                
                </div>
                <BottomBar strings={strings} handleRefresh={handleRefresh}/>
            </div>
        )
    }
}

export default ChartScreen;