import React, { useState } from 'react';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ViewStream from '@material-ui/icons/ViewStream';
import './CDSChartArea.css';

const ChartSection = ({ heading, refreshCharts, children }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    expanded ? setExpanded(false) : setExpanded(true);
    refreshCharts();
  }

  return (
    <div className='cds-chartarea-section'>

      <div className='expandable'>
        <div className='expandButton' onClick={toggleExpand}>
          <div className={expanded ? 'expandOptionIcon' : 'expandOptionIcon selected'}>
            <ViewColumn />
          </div>
          <div className={expanded ? 'expandOptionIcon selected' : 'expandOptionIcon'}>
            <ViewStream />
          </div>
        </div>
      </div>

      <div className='section-heading'>{heading}</div>
      <div className={expanded ? 'charts-expanded' : 'charts'}>
        {children}
      </div>
    </div>
  )
}

export default ChartSection