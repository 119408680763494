import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../stores/store';

import { FormGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import LocalizedStrings from 'react-localization';

import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import { checkRole } from '../../helpers/Utilities';
import lang from '../../lang/lang';
import { TEST_CRITERIA_SCREEN } from '../../resources/securityRoles';

import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import Wait from "../../components/Wait";
import { makeStyles } from '@material-ui/core/styles';

import ApiService from '../../services/ApiService'
import ApiConstants from '../../services/ApiConstants'

import '../../App.css';

const TestCriteriaScreen = (props) => {
    const globalState = useContext(store);
    const { dispatch } = globalState;    
    const oApi = new ApiService();

    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);
    
    const [alertMessage, setAlertMessage] = useState('');
    const [processData, setProcessData] = useState([]);
    const [inspItemsData, setInspItemsData] = useState([]);    
    const [testsData, setTestsData] = useState([]);    
    const [pno, setPno] = useState('');    
    const [item, setItem] = useState('');
    const [process, setProcess] = useState('');
    const [processName, setProcessName] = useState('');
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [openSnakeBar, setOpenSnakeBar] = React.useState(false);
    const [openSnakeBarError, setOpenSnakeBarError] = React.useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [confirmModalText, setConfirmModalText] = useState('');
    const [actionType, setActionType] = useState('');
    const [selectedTab, setSelectedTab] = React.useState(0);
    
    const useStyles = makeStyles((theme) => ({
        snakebarRoot: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        }
    }));
    const classes = useStyles();

    // ******* State Changes *******

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        clear();
        if(newValue===0){
            getDefaultTests();            
        }        
    };     

    const  handleProcessChange = (event) => {
        handleSearch();          
    }

    // useEffect(() => {        
    //     setInspItemsData([]);               
    //    // handleSearch();
    //   }, [process])
    
    // const handlePnoChange = (event) => {
    //     setPno(String(event.target.value));                
    // }

    const handleItemChange = (event) => {
        handleSearch();
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    const handleConfirmYes = () => {
        switch (actionType) {
            case 'save':
                setIsConfirmModalOpen(false);
                setActionType('');
                handleSave();
                break;
            case 'search':
                setIsConfirmModalOpen(false);
                setActionType('');
                dispatch({ type: 'set_dirty_screen', value: { dirty_screen: false } });
                handleSearch();
                break;
            default:
        }
    }

    const handleConfirmNo = () => {
        setIsConfirmModalOpen(false);
        if (actionType==='search') {
            document.getElementById('tcs_process').value = process;
            document.getElementById('tcs_prodnum').value = pno;
            document.getElementById('tcs_inspectionitem').value = item;
        }
    }

    const handleCloseSnakeBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnakeBar(false);
    }

    const handleCloseSnakeBarError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnakeBarError(false);
    }

    const openConfirmModal = (action) => {
        if (action === 'search' && !globalState.state.dirty_screen)
            handleSearch();
        else {
            if (action === 'save') {
                setActionType(action);
                setConfirmModalText(strings.conf_dialog.save_recp_confirm_text);
                setIsConfirmModalOpen(true);

            }
            if (action === 'search') {
                setActionType(action);
                setConfirmModalText(strings.conf_dialog.changelost_confirm_text);
                setIsConfirmModalOpen(true);
            }
        }
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const wait = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';        
        
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';
    }

     /**
     * Retrieve test criteria for Selected process, or product no and [item]
     */
    const handleSearch = () => {        
        let urlParams = null;        
        setIsSaveDisabled(true);    
        
        wait();
        clear();
        // Clear Process adjust products list
        const elProcess = document.getElementById('tcs_process');
        const elProd = document.getElementById('tcs_prodnum');
        const elItem = document.getElementById('tcs_inspectionitem');

        const process_code = String( elProcess ? elProcess.value : "").trim();
        const sPno = String( elProd ? elProd.value : "").trim();
        const sItem = String( elItem ? elItem.value : "").trim();
        
        if(selectedTab>0 && !process_code && !sPno){
            AlertMessage("You need to select a process or product");
            stopWaiting();
            return;
        }
        
        switch (selectedTab){
            case 0: // Plant
                setProcess("");
                setPno("");
                setItem("");    
                getDefaultTests();
                break;
            case 1: // Processes
                
                setProcess(process_code);
                setProcessName(elProcess.options[elProcess.selectedIndex].text);
                setPno("");
                setItem("");                
                urlParams = {
                    process_code: process_code,
                    include_process_products: 1,
                    pno: "",
                    item:  "" 
                };                    
             
                break;
            case 2: // Product                
                setProcess("");
                setPno(sPno);
                setItem(sItem);
                urlParams = {
                    process_code: "",
                    pno: sPno,
                    item:  sItem 
                };
                break;
            default:
        }        
            
        if (urlParams) {            
            oApi.GET(ApiConstants.RequiredTest.Controller, ApiConstants.RequiredTest.Required_Tests, urlParams).then((response) => {
                const requiredTestsList = response.data.data;                    
                if(response.data.process_products && response.data.process_products.length>0)
                    AlertMessage(strings.tcs_labels.process_product_alert, response.data.process_products );            
                else 
                    AlertMessage("",null);      
                setTestsData(requiredTestsList);
                setIsSaveDisabled(false);                                
                stopWaiting();
            }).catch(error => {
                console.log(error);
                stopWaiting();
            });
        }        
    }

    const handleSave = () => {        
        wait();
        let body = [];
        let apiMethod = ApiConstants.RequiredTest.Upsert_Required_Tests;
        if(selectedTab === 0){ // default test            
            apiMethod =  ApiConstants.RequiredTest.Update_Master_Required_Tests;
        } 
        for (props in testsData) {
            body.push({
                id: testsData[props].id,
                NGOptionNo: testsData[props].NGOptionNo,
                process_code: process,
                pno: pno,
                item: item,
                K: testsData[props].K,
                checked: testsData[props].checked ? 1 : 0
            });
        }
                
        oApi.POST(ApiConstants.RequiredTest.Controller , apiMethod , null, body).then((response) => {
            dispatch({ type: 'set_dirty_screen', value: { dirty_screen: false } });
            handleSearch();
            setOpenSnakeBar(true);
            stopWaiting();
        }).catch(error => {
            console.error(error);                
            setOpenSnakeBarError(true);
            stopWaiting();
        });             
        
    }

    const onCheckBoxChange = (e, tno) => {
        dispatch({ type: 'set_dirty_screen', value: { dirty_screen: true } });
        let val = e.target.checked;
        setTestsData(prevState => {
            prevState[tno].checked = val;
            return ([ ...prevState ])
        });

        // setTestsData([...testsData].map(object => {
        //     if(object.TestNo === tno) {
        //       return {
        //         ...object,
        //         checked: val
        //       }
        //     }
        //     else return object;
        // }))

    }

    const onKInputChange = (e, tno) => {
        dispatch({ type: 'set_dirty_screen', value: { dirty_screen: true } });
        let val = e.target.value;              
        setTestsData(prevState => {
            prevState[tno].K = Number(val);
            return ([ ...prevState ])
        });
    }
    
    const getDefaultTests = () => {
        wait();
       
        oApi.GET(ApiConstants.RequiredTest.Controller, ApiConstants.RequiredTest.Default_Tests, null).then( (response) => {
            const defaultTestsList = response.data.data;
            let testData = defaultTestsList.map(x => {
                return {
                    id: -99,
                    NGOptionNo: x.NGOptionNo,
                    TestNo: x.TestNo,
                    NGDescr: x.NGDescr,
                    K: x.K,
                    AlertLevel: x.AlertLevel,
                    TestDescription: x.TestDescription,
                    checked: x.checked,
                    item: "",
                    pno: "",
                    proess_code: "",
                    TestName: x.TestName,
                    Source: x.Source
                }
            }) ;
            
            setTestsData(testData);
            setIsSaveDisabled(false);
            stopWaiting();
           
        }).catch(error => {
            console.error(error);
            stopWaiting();
        });
    }

    const getProcesses = () => {
        wait();
       
        oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Processes, null).then((response) => {
            const processList = response.data.data;
            let processDropDownList = [];
            processDropDownList.push(<option value='' key={0}></option>);
            for (let i = 0; i < processList.length; i++) {
                if (processList[i]['isUsed'] === true)
                    processDropDownList.push(<option value={processList[i].process_code} key={i + 1}>{processList[i].process}</option>);
            }
            setProcessData(processDropDownList);            
        }).catch(error => {
            console.log("getProcess: ",error);
        });
        stopWaiting();
    }

    const AlertMessage = (message, prodList) => {
        let oAlertMessage = [];
        oAlertMessage.push(message);
        if(prodList){
            oAlertMessage.push(<hr />);
            for(let item of prodList)
            {
                oAlertMessage.push(`[${item.pno}] `);                 
            }            
        }
        setAlertMessage(oAlertMessage);        
    }

    const handleInspectionItemSearch = (e) => {
        clear();

        const sPno = document.getElementById('tcs_prodnum').value;
        if (sPno) {
            setPno(sPno.trim());
            wait();
            let urlParams = {
                pno: sPno.trim(),
                revision: "*"
            };            
            oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Dimensions, urlParams).then((response) => {
                const insItemsList = response.data.data;
                let insItemsDropDownList = [];
                insItemsDropDownList.push(<option value='' key={0}></option>);
                for (let i = 0; i < insItemsList.length; i++) {
                    insItemsDropDownList.push(<option value={insItemsList[i].dimension} key={i + 1}>{insItemsList[i].dimension}</option>);
                }
                setInspItemsData(insItemsDropDownList);

                if(insItemsList.length===0) {
                    AlertMessage(strings.tcs_labels.product_items_alerts,null);
                }
                else{                
                    AlertMessage("",null);
                    handleSearch();
                }
                stopWaiting();
            }).catch(error => {
                console.error(error);
                stopWaiting();
            });
        }        
    }

    useEffect(() => {
        wait();
        getDefaultTests();
        getProcesses();
        //handleSearch()
    }, []);
   

    const clear = () => {
        setTestsData([]);
        AlertMessage("",null);
    }

    const handleRefresh = () => {        
    }   

    const displayFilterSection = () => {
        return(
            <>
                <div className={classes.root}>
                <AppBar position="static">                    
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Test Criterias">
                        <Tab label={strings.tcs_labels.plant_tab}  />
                        <Tab label={strings.tcs_labels.process_tab}  />
                        <Tab label={strings.tcs_labels.product_tab} />
                    </Tabs>
                </AppBar>
                <div className='tcs-grid-item-one-div'>
                <TabPanel value={selectedTab} index={0}>
                    <Typography variant="h5">{strings.tcs_labels.plant_tab_text}</Typography>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <Typography variant="h5">{strings.tcs_labels.process_tab_text}</Typography>
                    <hr />
                    <Typography className='inline-text-14-black-inline'>{strings.tcs_labels.process_label}*: </Typography>
                    &nbsp;
                    <select name="tcs_process" id="tcs_process" style={{ width: '300px', height: '25px' }} 
                        value={process} onChange={handleProcessChange}
                    >
                        {processData}
                    </select>
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <Typography variant="h5">{strings.tcs_labels.product_tab_text}</Typography>
                    <hr />
                    <Typography className='inline-text-14-black-inline'>{strings.tcs_labels.product_label}*: </Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input name="tcs_prodnum" id="tcs_prodnum" style={{ width: '300px', height: '20px', marginBottom: '10px' }} size="20"  
                        defaultValue={pno}  onBlur = {handleInspectionItemSearch}/>                                            
                    <br />
                    <Typography className='inline-text-14-black-inline'>{strings.tcs_labels.item_label}: </Typography>
                        &nbsp;
                        <select style={{ width: '300px', height: '25px' }} name="tcs_inspectionitem" id="tcs_inspectionitem"
                          value={item} onChange={handleItemChange}
                        >
                            {inspItemsData}
                        </select>              
                </TabPanel>            
                </div>
            </div>            

            <div>
                <Alert severity="warning">
                    {alertMessage}                                
                </Alert>                            
            </div>
        </>
        )
    }

    const InputDisplayTitle = () => {
        let sTitle = "";
        if(process) 
            sTitle = `${strings.tcs_labels.process_label}: ${processName} (${process})`;
        else if(pno){
            sTitle = `${strings.tcs_labels.product_label}: ${pno}`;
            if(item) sTitle +=`    ${strings.tcs_labels.item_label}: ${item}`;            
        }
        else
            sTitle = strings.tcs_labels.plant_tab;
        return sTitle;
    }
    const displayInputSection = () => {
        
        return (
            <div className="tcs-testitem-area">                
                <Typography className='tcs-grid-two-label'>{strings.tcs_labels.test_item_label}: </Typography>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Typography className='inline-text-14-black-bold'>
                    { InputDisplayTitle() }
                </Typography>
                <FormControl component="fieldset" style={{ width: '99%' }}>
                    <FormGroup>
                        <div className="tcs-test-div">
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr key="test_header">
                                        <th>Test</th>
                                        <th>Description</th>
                                        <th>Source</th>
                                        <th>K</th>
                                    </tr>
                                </thead>
                                <tbody>                                    
                                    { displayTestCritiraData()  }
                                </tbody>
                            </table>
                            <br />
                            <button className="input-button" style={{ float: 'right', marginRight: '3vw' }} 
                                disabled={isSaveDisabled} onClick={() => openConfirmModal('save')}>{strings.input_labels.save}
                            </button>
                            
                        </div>   
                    </FormGroup>             
                </FormControl>
            </div>
        )
    }

    const displayTestCritiraData = () =>{
        
        if(!testsData) return null;
        return testsData.map(record => displayTestCriteriaDataRow(record.TestNo))
    }

    const displayTestCriteriaDataRow = (tno) => {

        let inputType = (tno <=1) ? "hidden" : "number";
        const testRecord = testsData[tno];
        if(!testRecord.AlertLevel) inputType = "hidden";

        
        let sColor = "black";
        if(testRecord){
            if(Number(testRecord.id) === -1) {
                //checked=false;
                sColor = "red"
            } 
        }
        
        const trStyle = {
            color: sColor
        };
        //checked={!!record.checked}
        return (
            <tr style={trStyle} key={tno}>
                <td>
                    <input type="checkbox" id={`${testsData[tno].TestNo}`} checked={testRecord.checked}
                        onChange={(e) => onCheckBoxChange(e, testRecord.TestNo)}
                    />                    
                    {testRecord.TestName}
                </td>
                <td>{testRecord.TestDescription}</td>
                <td>{testRecord.Source}</td>
                <td>
                    <input type={inputType} id={`K_${testRecord.TestNo}`} value={testsData[tno].K} size="12" 
                       onChange={(e) => onKInputChange(e, testRecord.TestNo)}
                    />                    
                </td>
            </tr>
        )
    }

    if (checkRole(TEST_CRITERIA_SCREEN))
        return (
            <div className="App" >
                <TopBar strings={strings} />
                <header className="App-header">
                    <Grid container spacing={3} className='tcs-main-grid'>                    
                        <Grid item xs={12} sm={12} md={12} lg={5}>                            
                            {displayFilterSection()}                            
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={7}>                            
                            {displayInputSection()}
                        </Grid>
                    </Grid>
                    <br /><br /><br /><br /><br /><br /><br />
                    <ConfirmationModal isOpen={isConfirmModalOpen} handleYes={handleConfirmYes} handleNo={handleConfirmNo} text={confirmModalText} />
                    <div className={classes.snakebarRoot}>
                        <Snackbar open={openSnakeBar} autoHideDuration={6000} onClose={handleCloseSnakeBar}>
                            <Alert onClose={handleCloseSnakeBar} severity="success">
                                {strings.conf_dialog.successMsgTestsSaved}
                            </Alert>
                        </Snackbar>
                    </div>
                    <div className={classes.snakebarRoot}>
                        <Snackbar open={openSnakeBarError} autoHideDuration={6000} onClose={handleCloseSnakeBarError}>
                            <Alert onClose={handleCloseSnakeBarError} severity="error">
                                {strings.conf_dialog.errorMsgTestsSaved}
                            </Alert>
                        </Snackbar>
                    </div>

                </header>

                <div id="loading_ph" className="loading-overlay">
                    <Wait message={strings.hold_msg} >
                    </Wait>                    
                </div> 
                <BottomBar strings={strings} handleRefresh={handleRefresh} />
            </div>
        );
    else
        return (
            <div className="App" >
                <TopBar strings={strings} />
                <header className="App-header">
                    <div style={{ textAlign: 'center', width: '60%' }}>
                        <h2>You are not authorized.</h2>
                    </div>
                </header>
                <BottomBar strings={strings} handleRefresh={handleRefresh}/>
            </div>
        )
}

export default TestCriteriaScreen;