import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import React, { useState } from 'react';
import { checkRole } from '../../helpers/Utilities';
import { SEARCH_CHART_SCREEN } from '../../resources/securityRoles';
// import { store } from '../../stores/store';
import LanguagePopupModal from '../LanguagePopup/LanguagePopupModal';
import MenuButton from '../MenuButton/MenuButton';

const BottomBar = (props) => {

    let strings = props.strings;
    //const globalState = useContext(store);
    const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
    const openLanguageModal = () => {
        setIsLanguageModalOpen(true);
    }
    const closeLanguageModal = () => {
        setIsLanguageModalOpen(false);
    }

    return (
        <AppBar position='fixed' style={{ backgroundColor: '#E0E0E0', top: "auto", bottom: 0 }}>
            <Toolbar>
                <Grid container>
                    <Grid item xs={4}>
                        <MenuButton icon="fa-arrow-left" goto="back" disabled={!checkRole(SEARCH_CHART_SCREEN)} 
                        label={strings.menu_labels.back} />
                    </Grid>

                    <Grid item xs={4}>
                        <div disabled={!checkRole(SEARCH_CHART_SCREEN)} className='MenuButton d-flex flex-column' onClick={()=>openLanguageModal()}>
                            <i className={"fa fa-globe"}></i><br />
                            <span>{strings.menu_labels.language}</span>
                        </div>
                    </Grid>

                    <Grid item xs={4}>
                        <MenuButton icon="fa-refresh" goto="refresh" disabled={!checkRole(SEARCH_CHART_SCREEN)} label={strings.menu_labels.refresh} handleRefresh={props.handleRefresh}/>
                    </Grid>
                </Grid>
                <LanguagePopupModal closeModal = {closeLanguageModal} isOpen = {isLanguageModalOpen} />
            </Toolbar>

        </AppBar>
    )
}

export default BottomBar;