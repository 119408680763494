import '../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import MaterialTable from 'material-table';
import { MTableBodyRow } from 'material-table';
import LocalizedStrings from 'react-localization';

import lang from '../../lang/lang';
import { store } from '../../stores/store';
import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import helperCommon from '../../helpers/HelperCommon';
import { convertTodaysDateToYYYYMMDD, convertDateToYYYYMMDDLessTime } from '../../helpers/Utilities';

import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import Wait from "../../components/Wait";
import CorrectiveAction from '../../components/CorrectiveAction/CorrectiveAction';
import { showExpiredLicenseError } from '@material-ui/x-license';

const ActionHistoryScreen = (props) => {
  
  const globalState = useContext(store);
  const oApi = new ApiService();
  let strings = new LocalizedStrings(lang);
  strings.setLanguage(globalState.state.lang);

  const [processData, setProcessData] = useState([]);
  const [waitTitle, setWaitTitle] = useState('')
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  //for CorrectiveAction Modal
  const ref = React.createRef();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedAction, setSelectedAction] = useState({});
  const [correctiveCodes, setCorrectiveCodes] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alert, setAlert] = useState({
    severity: '',
    message: '',
  });

  const intialFromDaySpan = -5;

  const wait = (title) => {    
    if(title) setWaitTitle(title)    
    const el = document.getElementById('loading_ph');
    if (el) el.style.display = 'block';
  }

  const stopWaiting = () => {
    const el = document.getElementById('loading_ph');
    if (el) el.style.display = 'none';
  }

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  }

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  }

  const handleSearch = (event) => {
    getActionHistory(toDate, fromDate);
  }

  const setActionHistoryData = (data) => {
    const modActionHistoryList = data.map((record, inx) => {
      return {
        process: record.process,
        productno: record.pno,
        mctool: record.toolno,
        batchno: helperCommon.toString(record.BATCHNO, ""),
        serialno: helperCommon.toString(record.SERIALNO, ""),
        inspectionitem: record.NG_Item,
        vr: helperCommon.toString(record.Option_NGDescr, ""),
        containerno: helperCommon.toString(record.NG_LotNo, ""),
        sequentialno: helperCommon.toString(record.NG_ReelNo, ""),
        issueddate: convertDateToYYYYMMDDLessTime(record.NG_NGDate),
        measurement: helperCommon.round(record.NG_TestValue, 4),
        LSL: helperCommon.round(record.NG_LSL, 4),
        LCL: helperCommon.round(record.NG_LCL, 4),
        UCL: helperCommon.round(record.NG_UCL, 4),
        USL: helperCommon.round(record.NG_USL, 4),
        reasonforabnormality: helperCommon.toString(record.Action_Reason, ""),
        takenaction: helperCommon.toString(record.Action_Measure, ""),
        actiondate: convertDateToYYYYMMDDLessTime(record.Action_ActionDate),
        owner: record.Action_EmpName,
        remarks: record.Action_Descr,
        // additional data needed for CorrectiveAction edit modal 
        actionKey: record.ActionKey,
        ngKey: record.NGKey,
        actionCode: record.Action_Code,
        processCode: record.process_code,
      };
    });
    setData(modActionHistoryList);
  }

  const getActionHistory = async (todate, fromdate) => {
    let params = {};
    let offset = 0;
    const historyData = [];

    if (fromdate) params.fromdate = fromdate;
    if (todate) params.todate = todate;
    if (document.getElementById('ahs_process').value !== '') params.process_code = document.getElementById('ahs_process').value;
    if (document.getElementById('ahs_prodnum').value !== '') params.pno = document.getElementById('ahs_prodnum').value;


    let result 
    let totalCount = 0
    wait("Getting Counts");
    // Get counts
    params.count_only=1
    result = await oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.History, params);
    console.log(result)
    if (result.data.count > 0) {
      totalCount = result.data.data;
    }
    delete params.count_only
    if(totalCount>0){      
      wait(`${totalCount} record(s) found `)      
      try {
        while (offset >= 0) {
          params.offset = offset;
          const percent = Math.round((offset/totalCount)*100)
          wait(`${offset} of ${totalCount} - ${percent}% `)
          const result = await oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.History, params);
          if (result.data.count > 0) {
            //historyData.concat(result.data.data)
            result.data.data.map(x => historyData.push(x));
          }
          offset = Number(result.data.next_offset);
          
        }
      } catch (error) {
        console.log(error);
      }
    }

    setActionHistoryData(historyData);
    stopWaiting();

    // oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.History, params).then((response) => {
    //   stopWaiting();
    //   switch (response.status) {
    //     case 200:
    //       setActionHistoryData(response.data.data);
    //       break;
    //     default:
    //   }
    // }).catch(error => {
    //   stopWaiting();
    // });
  }

  const getProcesses = (initialLoad) => {
    wait();
    oApi.GET(
      ApiConstants.MasterData.Controller,
      ApiConstants.MasterData.Processes,
      null
    ).then((response) => {
      stopWaiting();
      const processList = response.data.data;
      let processDropDownList = [];
      processDropDownList.push(<option value='' key={0}></option>);
      for (let i = 0; i < processList.length; i++) {
        if (processList[i]['isUsed'] === true)
          processDropDownList.push(<option value={processList[i].process_code} key={i + 1}>{processList[i].process}</option>);
      }
      setProcessData(processDropDownList);
      if (initialLoad)
        getActionHistory(null, convertTodaysDateToYYYYMMDD(intialFromDaySpan));
    }).catch(error => {
      stopWaiting();
    });
  }

  const handleToDate = () => {
    setToDate('');
  }

  const handleFromDate = () => {
    setFromDate('');
  }

  // this re-initializes screen, resetting values to default on load
  const handleRefresh = () => {
    wait();
    document.getElementById('ahs_process').value = '';
    document.getElementById('ahs_prodnum').value = '';
    //setToDate(convertTodaysDateToYYYYMMDD());
    setFromDate(convertTodaysDateToYYYYMMDD(intialFromDaySpan));
    getProcesses(true);
  }

  useEffect(() => {
    setFromDate(convertTodaysDateToYYYYMMDD(intialFromDaySpan));
    getProcesses(true);
  }, []);

  /* 
   * For CorrectiveAction Modal 
   */
  const handleClose = () => setOpen(false);
  const handleEdit = async (rowData) => {
    const action = {};
    setSelectedRow(rowData);

    action.actionKey = rowData.actionKey;
    action.ngKey = rowData.ngKey;
    action.process = rowData.process;
    action.vr = rowData.vr;
    action.containerNo = rowData.containerno;
    action.sequentialNo = rowData.sequentialno;
    action.owner = rowData.owner;
    action.modifiedBy = globalState.state.profile.userid;
    action.reason = rowData.reasonforabnormality;
    action.actionCode = rowData.actionCode;
    action.remarks = rowData.remarks;

    await getCorrectiveCodes(rowData.processCode);  // wait for this to complete before opening modal 
    setSelectedAction(action);
    setOpen(true);
  }

  const getCorrectiveCodes = async (processCode) => {
    let params = {
      process_code: processCode,
      active: true
    };

    wait();
    await oApi.GET(ApiConstants.Action.Controller, ApiConstants.Action.Corrective_Codes, params).then((response) => {
      stopWaiting();
      setCorrectiveCodes(response.data.data);
    }).catch(error => {
      stopWaiting();
      console.error(error);
    });
  }

  /**
  *  displays error or success message from CorrectiveAction component
  *  displays as snackbar alert
  */
  const handleCAMessage = (severity, message) => {
    setAlert({
      severity: severity,
      message: message,
    })
    setOpenSnackbar(true);
  }

  const closeCAMessage = () => {
    setOpenSnackbar(false);
  }

  const getRowBackgroundColor = (rowData) => {
    let color = '#FFF';

    color = selectedRow && (selectedRow.tableData.id === rowData.tableData.id) ? '#D4E6F1' : '#FFF';

    // if no corrective action, override row color 
    if (!rowData.actionKey || rowData.actionKey <= 0) {
      color = selectedRow && (selectedRow.tableData.id === rowData.tableData.id) ? '#dc9590' : '#F2D7D5';
    }
    return color;
  }

  return (
    <div className="App" >
      <TopBar strings={strings} />
      <header className="App-header" style={{ marginTop: '70px' }}>
        <Grid spacing={1} container className="ahs-main-grid">
          <Grid item xs={12}>
            <Typography className="inline-text-14-white">{strings.ahs_labels.date_range}: </Typography> &nbsp;&nbsp;&nbsp;&nbsp;
            <input type="date" name="ahs_fromdate" id="ahs_fromdate" value={fromDate} onChange={handleFromDateChange} required />
            &nbsp; <button className="input-button" onClick={handleFromDate}>{strings.input_labels.clear_dates}</button> - &nbsp;
            <input type="date" name="ahs_todate" id="ahs_todate" value={toDate} onChange={handleToDateChange} required /> &nbsp;
            <button className="input-button" onClick={handleToDate}>{strings.input_labels.clear_dates}</button>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <div>
              <Typography className="inline-text-14-white">{strings.input_labels.process}: </Typography> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select name="ahs_process" id="ahs_process">
                {processData}
              </select>
            </div>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography className="inline-text-14-white">{strings.ahs_labels.prod_numb}: </Typography> &nbsp;&nbsp;&nbsp;
            <input name="ahs_prodnum" id="ahs_prodnum" size="12" />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <button className="input-button" onClick={handleSearch}>{strings.input_labels.search}</button>
          </Grid>
        </Grid>

        <div className="ahs-data-grid">
          <MaterialTable
            title={strings.menu_labels.action_history}
            options={{
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
                position: 'sticky',
                top: 0
              },
              filtering: true,
              exportButton: true,
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50, { value: data.length, label: 'All' }],
              exportAllData: true,
              maxBodyHeight: '500px',
              actionsColumnIndex: 0,
              rowStyle: rowData => ({
                backgroundColor: getRowBackgroundColor(rowData)
              }),
            }}
            localization={strings.grid}
            columns={[
              {
                field: 'process', title: strings.input_labels.process, cellStyle: {
                  minWidth: 150
                },
                headerStyle: {
                  minWidth: 150
                }
              },
              {
                field: 'productno', title: strings.ahs_labels.prod_numb, cellStyle: {
                  minWidth: 140
                },
                headerStyle: {
                  minWidth: 140
                }
              },
              {
                field: 'mctool', title: strings.list_labels.mc_tool, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'inspectionitem', title: strings.tcs_labels.item_label, cellStyle: {
                  minWidth: 150,
                  maxWidth: 400
                },
                headerStyle: {
                  minWidth: 150,
                  maxWidth: 400
                }
              },
              {
                field: 'vr', title: strings.cds_notification.vr, cellStyle: {
                  minWidth: 300,
                  maxWidth: 400
                },
                headerStyle: {
                  minWidth: 300,
                  maxWidth: 400
                }
              },
              {
                field: 'containerno', title: strings.cds_notification.cont, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'sequentialno', title: strings.cds_notification.seq, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'batchno', title: strings.cds_notification.batchno, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'serialno', title: strings.cds_notification.serialno, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'issueddate', title: strings.cds_notification.iss_dat, cellStyle: {
                  minWidth: 130
                },
                headerStyle: {
                  minWidth: 130
                }
              },
              {
                field: 'measurement', title: strings.cds_notification.val, cellStyle: {
                  minWidth: 40
                },
                headerStyle: {
                  minWidth: 40
                }
              },
              { field: 'LSL', title: 'LSL' },
              { field: 'LCL', title: 'LCL' },
              { field: 'UCL', title: 'UCL' },
              { field: 'USL', title: 'USL' },
              {
                field: 'reasonforabnormality', title: strings.cds_notification.root_cause, cellStyle: {
                  minWidth: 300,
                  maxWidth: 400
                },
                headerStyle: {
                  minWidth: 300,
                  maxWidth: 400
                }
              },
              {
                field: 'takenaction', title: strings.cds_notification.tak_act, cellStyle: {
                  minWidth: 170
                },
                headerStyle: {
                  minWidth: 170
                }
              },
              {
                field: 'actiondate', title: strings.cds_notification.act_dat, cellStyle: {
                  minWidth: 130
                },
                headerStyle: {
                  minWidth: 130
                }
              },
              {
                field: 'owner', title: strings.cds_notification.owner, cellStyle: {
                  minWidth: 100
                },
                headerStyle: {
                  minWidth: 100
                }
              },
              {
                field: 'remarks', title: strings.cds_correctiveaction_modal.remarks, cellStyle: {
                  minWidth: 250
                },
                headerStyle: {
                  minWidth: 250
                }
              }
            ]}
            data={data}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Edit Action',
                onClick: (event, rowData) => handleEdit(rowData)
              }
            ]}
            onRowClick={((event, selectedRow) => setSelectedRow(selectedRow))}
            components={{
              Row: (props) => {
                return <MTableBodyRow {...props} onDoubleClick={() => handleEdit(props.data)} />;
              }
            }}
          />
        </div>
      </header>

      <Modal
        disableBackdropClick
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CorrectiveAction
          data={selectedAction}
          correctiveCodes={correctiveCodes}
          handleClose={handleClose}
          handleRefresh={handleSearch}
          resultMessage={handleCAMessage}
          ref={ref}
        />
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={closeCAMessage}
      >
        <Alert onClose={closeCAMessage} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>

      <div id="loading_ph" className="loading-overlay">
        <Wait message={strings.hold_msg} title={waitTitle} />
      </div>
      <BottomBar strings={strings} handleRefresh={handleRefresh} />
    </div>
  );
}

export default ActionHistoryScreen;