import React from 'react';
import "../App.css";

import waitImage from "../images/loading.gif"
const Wait = (props) => {
  const title = props.title || ""
  return (

 //   <div id="loading" className="loading-overlay">
      <div className="wait">        
        <img src={waitImage} alt="Waiting"></img>
        <br />
        {title &&
          <>          
          {title}
          <hr />
        </>
        }
        {props.message}
      </div>
   // </div>

  );
}
export default Wait;