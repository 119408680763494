import React from 'react';
import { Bar } from 'react-chartjs-2';
import '../../App.css';

const Histogram = (props) => {
    const data = props.data;
    const chartData = {
        labels: [],
        datasets: [
            {
                label: "FREQUENCY",
                data: [],
                fill: false,
                backgroundColor: "#1D84FF",
                borderColor: "#1D84FF",
                pointRadius: 2,
                type: 'bar',
                order: 2
            },
            {
                label: "EXP-WITHIN",
                data: [],
                fill: false,
                backgroundColor: "rgba(0,0,0,1)",
                borderColor: "white",
                pointRadius: 2,
                type: 'line',
                order: 1
            },
            {
                label: "EXP-OVERALL",
                data: [],
                fill: false,
                backgroundColor: "rgba(0,0,0,1)",
                borderColor: "green",
                pointRadius: 2,
                type: 'line',
                order:0
            }
        ]
    }

    const chartOptions = {
        responsive: true,
        title: {
            display: false
        },
        tooltips: {
            mode: 'index',
            intersect: true
        },
        annotation: {
            annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 2,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 1,
                label: {
                    enabled: false,
                    content: 'Test label'
                }
            }]
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        display: true,
                        fontColor: '#EDEDED'
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        display: true,
                        fontColor: '#EDEDED'
                    }
                }
            ]
        },
        legend: {
            display: true,
            position: 'top',
            labels: {
                fontColor: "#EDEDED"
              }
        }
    };

    data.frequency.forEach((dt) => {
        chartData.datasets[0].data.push(dt);
    });
    data['exp_within'].forEach((dt) => {
        chartData.datasets[1].data.push(dt);
    });
    data['exp_overall'].forEach((dt) => {
        chartData.datasets[2].data.push(dt);
    });
    data['bins'].forEach((dt) => {
        chartData.labels.push(dt);
    });

    return (
        <>
            <Bar data={chartData} options={chartOptions} />
        </>
    );
}

export default Histogram;