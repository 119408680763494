import React from 'react';
import { Line } from 'react-chartjs-2';
import '../../App.css';

const RangeChart = (props) => {
  let data = null;
  let UCL = null;
  let LCL = null;
  if (props.selectValue === 'r_chart') {
    data = props.data.Ri || [];
    UCL = props.data.UCLr;
    LCL = props.data.LCLr;
  } else if (props.selectValue === 's_chart') {
    data = props.data.Si;
    UCL = props.data.UCLs;
    LCL = props.data.LCLs;
  } else {
    //data = [null, null, null, null, ...props.data.movingRange];
    data = props.data.movingRange ? [null, null, null, null, ...props.data.movingRange] : [];
    UCL = props.data.UCLr;
    LCL = props.data.LCLr;
  }

  //const data = props.selectValue === 'r_chart' ? props.data['Ri'] : props.selectValue === 's_chart' ? props.data['Si'] : props.data['movingRange'];
  const chartData = {
    labels: [],
    datasets: [
      {
        label: props.selectValue === 'r_chart' ? "Ri" : props.selectValue === 's_chart' ? 'Si' : 'Range',
        data: [],
        fill: false,
        backgroundColor: "lightblue",
        borderColor: "darkblue",
        pointRadius: 2,
        lineTension: 0
      },
      {
        label: "LCL",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'yellow' ? "orange" : "yellow",
        borderColor: props.data.color === 'yellow' ? "orange" : "yellow",
        pointRadius: 1,
        borderDash: [8, 5]
      },
      {
        label: "UCL",
        data: [],
        fill: false,
        backgroundColor: props.data.color === 'yellow' ? "orange" : "yellow",
        borderColor: props.data.color === 'yellow' ? "orange" : "yellow",
        pointRadius: 1,
        borderDash: [8, 5]
      }
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    title: {
      display: false
    },
    tooltips: {
      mode: 'index',
      intersect: true
    },
    annotation: {
      annotations: [{
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 2,
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 1,
        label: {
          enabled: false,
          content: 'Test label'
        }
      }]
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            fontColor: props.data['text_color']
          }
        }
      ]
    },
    legend: {
      display: false,
    }
  };

    data.forEach((dt) => {
        chartData.labels.push('');
        chartData.datasets[0].data.push(dt);
        chartData.datasets[1].data.push(LCL);
        chartData.datasets[2].data.push(UCL);
        // chartData.datasets[3].data.push(props.data['U_2sigma_x']);
        // chartData.datasets[4].data.push(props.data['U_1sigma_x']);
        // chartData.datasets[5].data.push(props.data['L_2sigma_x']);
        // chartData.datasets[6].data.push(props.data['L_1sigma_x']);
    });

  return (
    <div className='chart-wrapper' style={{ backgroundColor: props.data.color }}>
      <Line
        data={chartData}
        options={chartOptions}
        datasetKeyProvider={props.datasetKeyProvider}
        key={props.refreshKey}
      />
    </div>
  );
}

export default RangeChart;