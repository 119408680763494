import React from 'react';
const IDP_AuthHandler = (props) => {

    const processAuthResults = (url) => {        
    }
    
    processAuthResults(window.location.href);

    return (
        <div className="square-green">
            <span>Logged in !</span>
        </div>
    )
}

export default IDP_AuthHandler;