import React, { useContext } from 'react';
import lang from '../../lang/lang';
import LocalizedStrings from 'react-localization';
import { store } from '../../stores/store';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Histogram from '../Histogram/Histogram';
import Modal from '@material-ui/core/Modal';
import CDOjectEditor from '../../components/CDObjectEditor/CDObjectEditor'

const ProcessCapInfo = (props) => {
    const globalState = useContext(store);
    let strings = new LocalizedStrings(lang);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    strings.setLanguage(globalState.state.lang);
    return (
        <div className='cds-pci-main-div'>
            <Grid container>
                <Grid item xs={4}>
                    <div className="cds-pci-info" style={{ color: props.data['text_color'], backgroundColor: props.data.color, borderColor: props.data.color}}>
                        <Typography className='cds-pci-left-div-top-heading'>
                            {strings.chart_detail.pci}
                            &nbsp;<i className="fa fa-info-circle" onClick={handleOpen} title="Chart Data"></i>
                        </Typography>                        
                        
                        <Typography className='cds-pci-left-div-heading'>{strings.chart_detail.va} {props.data.revision}</Typography>
                        <table>
                            <tbody>
                                <tr><td className='font-weight-bold font-size-14'>{strings.cds_pci.overall}</td><td className='font-weight-bold font-size-14'>{strings.cds_pci.within}</td></tr>
                                <tr><td><span className='font-weight-bold'>stddev</span>: {props.data.SigmaOverall}</td><td><span style={{ fontWeight: "bold" }}>stdev</span>: {props.data.SigmaWithin}</td></tr>
                                <tr><td><span className='font-weight-bold'>PP</span>: {props.data.PP}</td><td><span className='font-weight-bold'>CP</span>: {props.data.CP}</td></tr>
                                <tr><td><span className='font-weight-bold'>PPU</span>: {props.data.PPU}</td><td><span className='font-weight-bold'>CPU</span>: {props.data.CPU}</td></tr>
                                <tr><td><span className='font-weight-bold'>PPL</span>: {props.data.PPL}</td><td><span className='font-weight-bold'>CPL</span>: {props.data.CPL}</td></tr>
                                <tr><td><span className='font-weight-bold'>PPK</span>: {props.data.PPK}</td><td><span className='font-weight-bold'>CPK</span>: {props.data.CPK}</td></tr>
                                <tr><td><span className='font-weight-bold'>CPM</span>: {props.data.CPM}</td><td></td></tr>
                            </tbody>
                        </table>
                        <hr size="-1" />
                        <table>
                            <tbody>
                                <tr><td className='font-weight-bold font-size-14'>{strings.cds_pci.potential_failure}</td><td className='font-weight-bold font-size-14'>{strings.cds_pci.potential_failure}</td></tr>
                                <tr><td><span className='font-weight-bold'>{`PPM<LSL`}</span>: {props.data.ppm_LSL_overall}</td><td><span className='font-weight-bold'>{`PPM<LSL`}</span>: {props.data.ppm_LSL_within}</td></tr>
                                <tr><td><span className='font-weight-bold'>{`PPM>USL`}</span>: {props.data.ppm_USL_overall}</td><td><span className='font-weight-bold'>{`PPM>USL`}</span>: {props.data.ppm_USL_within}</td></tr>
                                <tr><td><span className='font-weight-bold'>PPM {strings.cds_pci.total}</span>: {props.data.ppm_total_overall}</td><td><span className='font-weight-bold'>PPM {strings.cds_pci.total}</span>: {props.data.ppm_total_within}</td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                    
                </Grid>
                <Grid item xs={8}>
                    <div className="cds-pci-histo-div" style={{ borderColor: props.data.color }}>
                        <Histogram data={props.histogramData} />
                    </div>
                </Grid>
            </Grid>

            <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">                
                <CDOjectEditor object_data={props.data} width="500px" name="Dim Data" title="Chart Data" collapsed="1" handleClose={handleClose}></CDOjectEditor>
            </Modal>
        </div>
    );
}

export default ProcessCapInfo;