import {Modal, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import LocalizedStrings from 'react-localization';
import lang from '../../lang/lang';
import { store } from '../../stores/store';

const ConfirmationModal = ({isOpen, handleYes, handleNo, text}) => {
    const globalState = useContext(store);
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);

    function getModalStyle() {
        return {
            top: '10%',
            left: '34vw'
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: '26%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3)
        },
    }));

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const modalBody = (
        <div style={modalStyle} className={classes.paper}>
            <div id="simple-modal-description">
                <Typography className='text-18-bold'>{strings.conf_dialog.confirm_label}</Typography>
                <hr size='-1'/>
                <div>
                    <Typography className='inline-text-16-black'>{text}</Typography>
                </div>
                <br/>
                <div>
                    <Button variant="contained" color="primary" onClick={handleYes}>{strings.conf_dialog.yes}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="secondary" onClick={handleNo}>{strings.conf_dialog.no}</Button>
                    
                    {/* <button className="input-button" onClick={handleYes}>{strings.conf_dialog.yes}</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="input-button" onClick={handleNo}>{strings.conf_dialog.no}</button> */}
                </div>
            </div>
            <br />
        </div>
    )

    return (
        <Modal
                disableBackdropClick
                open={isOpen}
                onClose={handleNo}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalBody}
        </Modal>
    );
}

export default ConfirmationModal;