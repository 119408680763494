import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import LocalizedStrings from 'react-localization';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

import Alert from '@material-ui/lab/Alert';
import { store } from '../../stores/store';
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import lang from '../../lang/lang';
import Wait from "../../components/Wait";
import CDOjectEditor from '../../components/CDObjectEditor/CDObjectEditor';
import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';

import '../../App.css';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

const ApplicationConfigScreen = (props) => {

    const globalState = useContext(store);
    const oApi = new ApiService();
    let strings = new LocalizedStrings(lang);    
    strings.setLanguage(globalState.state.lang);

    const classes = useStyles();
    const [filesList, setFileslist] = useState([]);
    const [selectedFile, setSelectedFile] = useState({});
    const [configData, setConfigData] = useState(null);
    const [ updatedConfigData, setUpdatedConfigData] = useState({});
        
    const [message, setMessage] = useState('Application Configurations');
    const [severity, setSeverity] = useState('info');

    const [dataType, setDataType] = useState("");

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        getConfigFiles();
    },[])

    const startWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'block';
    }

    const stopWaiting = () => {
        const el = document.getElementById('loading_ph');
        if(el) el.style.display = 'none';
    }

    const getConfigFiles = () => {

        setMessage("Configuration Files");
        setSeverity("success");
        startWaiting();
        oApi.GET(ApiConstants.Util.Controller, ApiConstants.Util.List_Config_Files, null).then((response) => {
            stopWaiting();
            setFileslist(response.data);
        }).catch(error => {
            stopWaiting();
        });
    }

    const downloadFile = (file) =>{
        startWaiting();
        const urlParams = {file_name: file.File};
        init();
        oApi.GET(ApiConstants.Util.Controller, ApiConstants.Util.Download_Config_File, urlParams).then((response) => {
            if (typeof response.data === 'string' || response.data instanceof String) {
                document.getElementById("text-data").value = response.data;
                setDataType("STRING");
            } else {
                setConfigData(response.data);
                setDataType("JSON");
            }
            setSelectedFile(file);   
            setMessage(file.File);
            setSeverity("success");         
            stopWaiting();
        }).catch(error => {
            stopWaiting();            
        });
    }

    const uploadFile = (sData,file_name) =>{

        startWaiting();
        const urlParams = {file_name: file_name};
        
        oApi.POST(ApiConstants.Util.Controller, ApiConstants.Util.Upload_Config_File, urlParams, sData).then((response) => {   
            setMessage("Data saved");
            setSeverity("success");        
            stopWaiting();
        }).catch(error => {
            setMessage(error.message);
            setSeverity("error");
            stopWaiting();            
        });
    }


    const handleFileClick = (file) => {
        downloadFile(file);
    }

    const renderSideItem = (file, inx) => {
        if(file.Size===0) return null;
        return (
            <ListItem button key={inx}>
                <ListItemIcon>
                    <i className="fa fa-file"></i>
                </ListItemIcon>
                <ListItemText primary={file.File} onClick ={ () => handleFileClick(file)} />
            </ListItem>
        )
    }

    const renderData = () => {
        const textDisplay = dataType==="STRING"?"block":"none";
        const jsonDisplay = dataType==="JSON"?"block":"none";

        return (
            <>
                <div style={{display: textDisplay}}>
                    <textarea id="text-data" name="text-data"  cols="130" rows="40" >
                    </textarea>
                </div>

                <div style={{display: jsonDisplay}} >
                    <CDOjectEditor object_data={configData} edit={true} width="" name={null} no_header={true} marginTop="1px" onEdit={e => handleOnEditJson(e)}>    
                    </CDOjectEditor>
                </div>
            </>
        )
    }

    const init = () => {
        setSelectedFile({});
        document.getElementById("text-data").value = "";
        setConfigData(null);
        setDataType("");
    }
 
    const handleRefresh = () =>{
        init();
        getConfigFiles();    
    }

    const handleSave = () => {
        let sData="";
        if(dataType==="STRING"){
            sData = document.getElementById("text-data").value;
        } else if(dataType==="JSON"){
            sData = JSON.stringify(updatedConfigData, null, 2);
        }
        if(sData){
            uploadFile(sData, selectedFile.File);
        }       
        
    }

    const handleOnEditJson = (e) => {
        if(Array.isArray(e.updated_src))
            setUpdatedConfigData([...e.updated_src]);
        else
            setUpdatedConfigData({...e.updated_src});
        if (e.new_value === "error") {
            return false
        }
    }

    return (
        <div className="App1" style={{height: "800px", padding: "5px", backgroundColor: "darkgray", marginTop: "35px"}}>
            <TopBar strings={strings} />
            <br/>
            
            <Grid container>
                <Grid item xs={1} style={{paddingLeft: "15px", paddingTop: "10px"}}>
                    <Button variant="contained" color="primary" className={classes.button} startIcon={<SaveIcon />} onClick={handleSave}>
                        {strings.input_labels.save}
                    </Button>
                </Grid>

                <Grid item xs={11}>
                    <Alert  variant="filled" severity={severity} >
                        {message}                    
                    </Alert>
                </Grid>
            </Grid>           
            
            
            <Grid container justify="flex-start" style={{padding: "10px"}}>
               
                <Grid item xs={3}>
                    <List component="nav" aria-labelledby="nested-list-subheader"
                        subheader={ 
                            <ListSubheader component="div" id="nested-list-subheader">
                                Configuration Files
                            </ListSubheader>
                        }
                        className={classes.root}
                    >
                        { filesList.map((file,inx) => renderSideItem(file,inx)) }
                    </List>
                </Grid>
                <Grid item xs={9}>
                    {renderData()}
                </Grid>
            </Grid>

            <div id="loading_ph" className="loading-overlay">
                <Wait message={strings.hold_msg} >
                </Wait>                    
            </div>

            <BottomBar strings={strings} handleRefresh={handleRefresh} />
            
        </div>
    )
}

export default ApplicationConfigScreen;