/**
 * 
 */
const ApiConstants = {
    
    MasterData : {
        Controller: "masterdata",
        Plants: "plants",
        Search_Product_Setup: "search_product_setup",
        Dimensions: "dimensions",
        Revisions: "revisions",
        Processes: "processes",
        Product_Info: "product_info",
        Design_Spec_Count: "design_spec_count",
        Design_Spec: "design_spec",
        ProcessProducts: "process_products"
    },

    RequiredTest: {
        Controller: "requiredtest",
        Default_Tests: "default_tests",
        Required_Tests: "required_tests",
        Update_Master_Required_Tests: "update_master_required_tests",
        Upsert_Required_Tests: "upsert_required_tests",
        Add_Required_Test: "add_required_test",
        Update_Required_Test: "update_required_test",
        Delete_Required_Test: "delete_required_test",
        Delete_Required_Tests: "delete_required_tests"
    },

    TestData: {
        Controller: "testdata",
        Xbar: "xbar",
        Stats: "stats"
    },

    Action: {
        Controller: "action",
        Corrective_Codes: "corrective_codes",
        Add_Corrective_Code: "add_corrective_code",
        Update_Corrective_Code: "update_corrective_code",
        Delete_Corrective_Code: "delete_corrective_code",
        History: "history",
        Add_Action: "add_action",
        Update_Action: "update_action",
        Delete_Action: "delete_action",
        NGhistory: "nghistory",
        Add_NGhistory: "add_nghistory"
    },

    Notify: {
        Controller: "notify",
        Abnormality_List: "abnormality_list",
        Recipients: "recipients",
        Add_Recipients: "add_recipients",
        Copy_To_ABN: "Copy to Abnornamilty",
        
    },

    Status: {
        Controller: "status",
        Status: "status"
    },

    User: {
        Controller: "user",
        Profile: "profile",
        Token: "token",
        Air_Users: "air_users",
        IsConnected: "is_connected"
    },

    Util: {
        Controller: "util",
        List_Config_Files: "list_config_files",
        Download_Config_File: "download_config_file",
        Upload_Config_File: "upload_config_file"
    }
}

export default ApiConstants;