// store.js
import React, { createContext, useReducer } from 'react';
import helperSession from '../helpers/HelperSession';
import lang from '../lang/lang';

const initialState = {
    profile: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        sub: '',
        userPrincipleName: '',
        workCity: '',
        workCountry: '',
        workLocationName: '',
        workLocation: '',
        company: '',
        payCompany: '',
        networkID: '',
        isAuthenticated: false,
        costCenter: '',
        entitlements: [],
        plants: ''        
    },

    access_token: '',        
    lang: localStorage.getItem('language') || 'en',
    langStrings: {},
    pno: '',
    toolno: '',
    dimension: '',
    revision: '',
    sd_testdate: '',    
    process_code: '',
    process_name: '',
    dirty_screen: false,
    plant_code: '',
    plant_name: '',
    uqno: ''
};

const sessionState = () => {
    const sessionState = {
        profile: helperSession.profile,
        pno: helperSession.pno,
        toolno: helperSession.toolno,
        dimension: helperSession.dimension,
        revision: helperSession.revision,
        process_code: helperSession.process_code,
        process_name: helperSession.process_name,
        sd_testdate: helperSession.sd_testdate,        
        plant_code: helperSession.plant_code,
        plant_name: helperSession.plant_name,

        dirty_screen: false,
    };

    return sessionState;   

};

//const store = createContext(initialState);
const store = createContext(sessionState());
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((currState, action) => {
        switch (action.type) {
            case 'update_user':
                currState.profile.firstName = action.value.firstName;
                currState.profile.lastName = action.value.lastName;
                currState.profile.emailAddress = action.value.emailAddress;
                //currState.profile.sub = action.value.sub;
                                
                currState.profile.company = action.value.company;
                currState.profile.payCompany = action.value.payCompany;
                currState.profile.networkID = action.value.networkID;
                currState.profile.isAuthenticated = true;
                currState.profile.costCenter = action.value.costCenter;
                currState.profile.entitlements = [];

                action.value.entitlements.forEach(x => {
                    const attrs = x.split(',')
                    if (attrs[2].split('=')[1] === 'molex ccs spc tool')
                        currState.profile.entitlements.push(attrs[0].split('=')[1])
                })    

                currState.langStrings = lang[currState.lang];
                return currState;

            case 'update_token':
                currState.access_token = action.value;
                return currState;
            case 'select_lang':
                currState.lang = action.value;
                currState.langStrings = lang[currState.lang];
                localStorage.setItem('language', currState.lang);
                return currState;

            case 'clear_user':
                return initialState;

            case 'clear_user_data':
                currState.profile.firstName = '';
                currState.profile.lastName = '';
                currState.profile.emailAddress = '';
                currState.profile.sub = '';
                currState.profile.userPrincipleName = '';
                currState.profile.workCity = '';
                currState.profile.workCountry = '';
                currState.profile.workLocationName = '';
                currState.profile.workLocation = '';
                currState.profile.company = '';
                currState.profile.payCompany = '';
                currState.profile.networkID = '';
                currState.profile.isAuthenticated = false;
                currState.profile.costCenter = '';
                currState.profile.entitlements = [];
                return currState;

            case 'set_revision_screen_data':
                currState.pno = action.value.pno;
                currState.toolno = action.value.toolno;
                currState.revision = action.value.revision;
                currState.sd_testdate = action.value.sd_testdate;   
                currState.dimension = "";
                //if(currState.pno && currState.revision) currState.uqno = currState.pno + currState.revision; 
                
                helperSession.pno = action.value.pno;
                helperSession.toolno = action.value.toolno;                
                helperSession.sd_testdate = action.value.sd_testdate;
                helperSession.dimension = "";

                return currState;
            case 'set_chart_detail_screen_data':
                currState.pno = action.value.pno;
                currState.toolno = action.value.toolno;
                currState.dimension = action.value.dimension;
                currState.revision = action.value.revision;
                currState.sd_testdate = action.value.sd_testdate;
                //if(currState.pno && currState.revision) currState.uqno = currState.pno + currState.revision; 
                
                helperSession.pno = action.value.pno;
                helperSession.toolno = action.value.toolno;
                helperSession.dimension = action.value.dimension;
                helperSession.revision = action.value.revision;
                helperSession.sd_testdate = action.value.sd_testdate;
                
                return currState;
            case 'set_dimension':
                currState.dimension = action.value.dimension;                
                helperSession.dimension = action.value.dimension;                
                return currState;            
            case 'set_revision':
                currState.revision = action.value.revision;                
                //if(currState.pno && currState.revision) currState.uqno = currState.pno + currState.revision; 
                helperSession.revision = action.value.revision;                
                return currState;            
            case 'set_process':
                currState.process_code = action.value.process_code;
                currState.process_name = action.value.process_name;

                helperSession.process_code = action.value.process_code;
                helperSession.process_name = action.value.process_name;
                return {...currState};
            case 'update_plant':                
                currState.plant_code = action.value.plant_code;
                currState.plant_name = action.value.plant_name;
                return action.value.norender ? currState :  {...currState};                
            case 'set_dirty_screen':
                currState.dirty_screen = action.value.dirty_screen;
                return currState;
            case 'set_uqno':
                currState.uqno = action.value.uqno;
                return currState;
            
            default:
                throw new Error();
                
        };
    }, sessionState());

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }