import React, { useContext, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import Snackbar from '@material-ui/core/Snackbar';
import LocalizedStrings from 'react-localization';
import { checkRole, convertDateToYYYYMMDD, roundNumber } from '../../helpers/Utilities';
import lang from '../../lang/lang';
import { CORRECTIVE_ACTION } from '../../resources/securityRoles';
import { store } from '../../stores/store';
import CorrectiveAction from '../CorrectiveAction/CorrectiveAction';

const CDSNotificationTable = (props) => {
    const globalState = useContext(store);
    let strings = new LocalizedStrings(lang);
    strings.setLanguage(globalState.state.lang);

    //DOM reference for Modal 
    const ref = React.createRef();

    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alert, setAlert] = useState({
      severity: '',
      message: '',
    });
    const [notificationTableData, setNotificationTableData] = useState([]);
    const [selectedAction, setSelectedAction] = useState({
      actionKey: '',
      ngKey: '',
      process: '',
      vr: '',
      containerNo: '',
      sequentialNo: '',
      owner: '',
      modifiedBy: '',
      reason: '',
      actionCode: '',
      remarks: '',
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCorrectiveAction = (itemData) => {
      const action = {};
      action.actionKey = itemData.ActionKey;
      action.ngKey = itemData.NGKey;
      action.process = globalState.state.process_name;
      action.vr = itemData.Option_NGDescr;
      action.containerNo = itemData.NG_LotNo;
      action.sequentialNo = itemData.NG_ReelNo;
      action.owner = itemData.Action_EmpName;
      action.modifiedBy = globalState.state.profile.userid;
      action.reason = itemData.Action_Reason;
      action.actionCode = itemData.Action_Code;
      action.remarks = itemData.Action_Descr;

      setSelectedAction(action);
      handleOpen();
    }

    /**
     *  displays error or success message from CorrectiveAction component
     *  displays as snackbar alert
     */
    const handleCAMessage = (severity, message) => {
      setAlert({
        severity: severity,
        message: message,
      })
      setOpenSnackbar(true);
    }

    const closeCAMessage = () => {
      setOpenSnackbar(false);
    }

    const refreshNotificationTableData = () => {
      const notifTableData = [];
      for (let i = 0; i < props.data.length; i++) {
        let ng_date_string = convertDateToYYYYMMDD(props.data[i]['NG_NGDate']);
        let action_date_string = convertDateToYYYYMMDD(props.data[i]['Action_ActionDate']);
        notifTableData.push(
          <tr onDoubleClick={() => handleCorrectiveAction(props.data[i])} key={i}>
            <td>{props.data[i]['Option_NGDescr']}</td>
            <td>{props.data[i]['NG_LotNo']}</td>
            <td>{props.data[i]['NG_ReelNo']}</td>
            <td>{ng_date_string}</td>
            <td>{roundNumber(props.data[i]['NG_TestValue'], 5)}</td>
            <td>{props.data[i]['NG_LSL']}</td>
            <td>{props.data[i]['NG_LCL']}</td>
            <td>{props.data[i]['NG_UCL']}</td>
            <td>{props.data[i]['NG_USL']}</td>
            <td></td>
            <td style={{ backgroundColor: +props.data[i]['ActionKey'] <= 0 ? "red" : "" }}>{props.data[i]['Action_Reason']}</td>
            <td style={{ backgroundColor: +props.data[i]['ActionKey'] <= 0 ? "red" : "" }}>{props.data[i]['Action_Measure']}</td>
            <td style={{ backgroundColor: +props.data[i]['ActionKey'] <= 0 ? "red" : "" }}>{action_date_string}</td>
            <td style={{ backgroundColor: +props.data[i]['ActionKey'] <= 0 ? "red" : "" }}>{props.data[i]['Action_EmpName']}</td>
            <td><button disabled={!checkRole(CORRECTIVE_ACTION)} className="input-button" onClick={() => handleCorrectiveAction(props.data[i])}> <i className={"fa fa-edit"}></i></button></td>
          </tr>
        );
      }
      setNotificationTableData(notifTableData);
    }

    useEffect(() => {
      refreshNotificationTableData();
    }, [props.data]);

    return (
      <div>
        <Typography className='cds-ns-main-heading-one'>{strings.cds_notification.sys_not}</Typography>
        <Typography className='cds-ns-main-heading-two'>{strings.cds_notification.cs_sup}</Typography>

        <table className="spc-header cds-not-table">
          <thead className='CDSTable-header' style={{ backgroundColor: 'white' }}>
            <tr>
              <th style={{ width: '15%' }}>{strings.cds_notification.vr}</th>
              <th colSpan="2" scope="colgroup">{strings.cds_notification.trcb}</th>
              <th style={{ width: '8%' }}>{strings.cds_notification.iss_dat}</th>
              <th className='cds-ns-grid-value-col'>{strings.cds_notification.val}</th>
              <th style={{ width: '3%' }}>{strings.cds_notification.lsl}</th>
              <th style={{ width: '3%' }}>{strings.cds_notification.lcl}</th>
              <th style={{ width: '3%' }}>{strings.cds_notification.ucl}</th>
              <th style={{ width: '3%' }}>{strings.cds_notification.usl}</th>
              <th></th>
              <th>{strings.cds_notification.root_cause}</th>
              <th>{strings.cds_notification.tak_act}</th>
              <th style={{ width: '8%' }}>{strings.cds_notification.act_dat}</th>
              <th>{strings.cds_notification.owner}</th>
              <th style={{ width: '3%' }}>{strings.cds_notification.actions}</th>
            </tr>
            <tr>
              <th></th>
              <th scope="col" style={{ width: '6%' }}>{strings.cds_notification.cont}</th>
              <th scope="col" style={{ width: '4%' }}>{strings.cds_notification.seq}</th>
              <th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th>
            </tr>
          </thead>
          <tbody className='CDSTable-body'>
            {notificationTableData}
          </tbody>
        </table>
        {notificationTableData.length === 0 && <div className='cds-ns-no-data-heading'><h6>No Notification Items found</h6></div>}
        <Modal
          disableBackdropClick
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <CorrectiveAction 
            data={selectedAction} 
            correctiveCodes={props.correctiveCodes}
            handleClose={handleClose}
            handleRefresh={props.handleUpdateNotifData}
            resultMessage={handleCAMessage}
            ref={ref} 
          />
        </Modal>
        <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={closeCAMessage}
        >
          <Alert onClose={closeCAMessage} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      </div>
    );
}

export default CDSNotificationTable;