import React from "react";
import { useHistory } from "react-router-dom";

import "./MenuButton.css";

const MenuButton = (props) => {

    let history = useHistory();
    var routeScreen = () => {
        
        switch (props.goto) {
            case 'charts':
                history.push('/chartscreen')
                break;
            case 'history':
                history.push('/actionhistoryscreen')
                break;
            case 'back':
                history.goBack();
                break;
            case 'refresh':
                props.handleRefresh();
                break;
            default:
                break;
        }
    }

    return (
        <div className='MenuButton d-flex flex-column' onClick={routeScreen} disabled={props.disabled} >
            <i className={"fa " + props.icon}></i><br />
            <span>{props.label}</span>
        </div>
    )
}

export default MenuButton;