import {securityRoles} from '../resources/securityRoles';
import helperSession from '../helpers/HelperSession';

export const convertTodaysDateToYYYYMMDD = (offsetDays) => {
    let date_ob = new Date();
    if(offsetDays) date_ob.setDate(date_ob.getDate() + offsetDays);
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    return year + '-' + month + '-' + date;
}

export const convertDateToYYYYMMDD = (target_date) => {
    if (!target_date) return null;
    let date_action = new Date(target_date);
    let date = ("0" + date_action.getDate()).slice(-2);
    let month = ("0" + (date_action.getMonth() + 1)).slice(-2);
    let year = date_action.getFullYear();
    return year + '-' + month + '-' + date + ' ' + date_action.toLocaleTimeString();
}

export const convertDateToYYYYMMDDLessTime = (target_date) => {
    if (!target_date) return null;
    let date_action = new Date(target_date);
    let date = ("0" + date_action.getDate()).slice(-2);
    let month = ("0" + (date_action.getMonth() + 1)).slice(-2);
    let year = date_action.getFullYear();
    return year + '-' + month + '-' + date;
}

export const checkRole = (roles) => {

    if(!helperSession.plant_code) return false;

    const allowedRoles = securityRoles[roles.toUpperCase()];
    if(helperSession.entitlements) {
        for (let i=0; i< helperSession.entitlements.length ; i++){
            if(allowedRoles.find((role) => role===helperSession.entitlements[i])) {
                return true;                
            }
        }
    }
    return false;
}

/**
 * Check if session have entitlements and assigned plants
 * 1 - Have both entitlements and plan codes
 * -1 - No entitlements
 * -2 - No plants
 * -3 - No Roles 
 */
export const checkEntitlements = () => {

    if(!helperSession.access_token) return 1;

    if(!helperSession.entitlements || helperSession.entitlements.length === 0) {
        // No entitlements
        return -1;
    } 
    
    if(!helperSession.plants || helperSession.plants.length === 0) {
        // No Plants
        return -2;
    } 

    // Check for roles
    if(helperSession.plants.length === helperSession.entitlements.length){
        // all plants no roles
        return -3;
    }
    
    return 1;
}

/**
 * Convert Date string to local date time (YYYY-MM-DD HH:mm:SS AM/PM)
 * @param {String sDateTime 
 */
export const localDateTime = (sDateTime) => {    
    const date_time = new Date(sDateTime)
    const date = ("0" + date_time.getDate()).slice(-2);
    const month = ("0" + (date_time.getMonth() + 1)).slice(-2);
    const year = date_time.getFullYear();
    return year + '-' + month + '-' + date + ' ' + date_time.toLocaleTimeString();
}
// export const toNumber = (value, precision) =>{
//     return Number(Number(value).toFixed(precision));
// }

/**
 * 
 * @param {Number} value 
 * @param {Number} decimalPlaces 
 */
export const roundNumber = (value, decimalPlaces) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(value * factor) / factor;
}
