
import Axios from 'axios';
import helperSession from '../helpers/HelperSession'

const _apiMethod = (controller, method) => { 
    const sUrl = `${process.env.REACT_APP_API_URL}/${controller}/${method}`;    
    return sUrl;
}

class ApiService {    

    constructor(){
        this.initSession();
        //this.plant_code = helperSession.plant_code;
        //this.access_token = helperSession.access_token;

        //this.plant_code = "AS";
    }

    initSession(){
        this.plant_code = helperSession.plant_code;
        this.access_token = helperSession.access_token;
    }

    encodeUrlParams(urlParams) {
        // Check if null or empty
        urlParams = urlParams.replaceAll("#","%23");        
        return urlParams
    }

    getUrlParams(urlParams) {
        // Check if null or empty
        if(!urlParams) return "";
        if(Object.keys(urlParams).length === 0) return "";
        // Build URL
        let  params_str = "";
        
        Object.keys(urlParams).forEach( (key) => {
            params_str += (params_str) ? "&" : "?";
            params_str += `${key}=${urlParams[key]}`;
        })        
        return params_str;
    }

    getheaders(oParams) {
        if(!this.plant_code || !this.access_token) this.initSession();
        let plant_code = oParams ? oParams.plant_code : "";
        if(!plant_code) plant_code = helperSession.plant_code;
        //if(!plant_code) plant_code='AS';
        
        let access_token = oParams ? oParams.access_token: "";
        if(!access_token) {
            access_token =  this.access_token;
        }
        
        const headers = oParams ? {...oParams} : {};
        headers.Authorization =  `Bearer ${access_token}`; 
        headers.Accept = '*/*';
        headers['Content-Type'] = 'application/json';
        headers['plant_code'] = plant_code;
        return headers;
    }

    /**
     * Execute http Get and return a promise
     * @param {String} controller 
     * @param {String} method 
     * @param {Array} urlParams 
     * @param {Object} headers 
     */
    GET(controller, method, urlParams, headers) {        
        
        return new Promise((resolve, reject) => {
            const oHeaders = this.getheaders( headers );
            let url = _apiMethod(controller, method) + this.encodeUrlParams(this.getUrlParams(urlParams));
            Axios.get(url, { 
                headers: oHeaders 
            }).then(response => {
                //helperSession.connected = true;                
                resolve(response)
            }).catch(error => {
                //console.log(error.response.status);
                //helperSession.connected = false;
                // check for 403 status and use rducer to hanlde errors and redirect
                reject(error)
            });
        });    
    }
    
    /**
     * Execcute http POSt and return promise
     * @param {String} controller 
     * @param {String} method 
     * @param {Array} urlParams 
     * @param {Object} body 
     * @param {Object} headers 
     */
    POST(controller, method, urlParams, body, headers) {
        let url = _apiMethod(controller, method);
        const oHeaders = this.getheaders( headers );
        
        url += this.encodeUrlParams(this.getUrlParams(urlParams));
        return new Promise((resolve, reject) => {
            Axios.post(url, body, {
                headers: oHeaders
            })
            .then(response => {
                //helperSession.connected = true;
                resolve(response)
            })
            .catch(error => {
                //helperSession.connected = false;
                reject(error)
            });    
        });
    }

    httpGET(url, headers) {        
        
        return new Promise((resolve, reject) => {            
            Axios.get(url, { 
                headers: headers 
            }).then(response => {
                //helperSession.connected = true;                
                resolve(response)
            }).catch(error => {
                //console.log(error.response.status);
                //helperSession.connected = false;
                // check for 403 status and use rducer to hanlde errors and redirect
                reject(error)
            });
        });    
    }

    httpPOST(url, body, headers) {
        return new Promise((resolve, reject) => {
            Axios.post(url, body, {
                headers: headers
            })
            .then(response => {
                //helperSession.connected = true;
                resolve(response)
            })
            .catch(error => {
                //helperSession.connected = false;
                reject(error)
            });    
        });
    }
}

export default ApiService;