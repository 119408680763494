
import authHelper from "../auth/AuthHelper";

const HelperSession = {    
    _appSessionKey: "_SPC_SESSION_DATA_",

    set AppSession(value){
        sessionStorage.setItem(this._appSessionKey, JSON.stringify(value));
    },

    /**
     * Return App sessions
     */

    /******  Getter and Setters ******/
    get AppSession() {
        const _session = sessionStorage.getItem(this._appSessionKey);
        const oSession =_session ? JSON.parse(_session) : this.clearAppSession();
        return oSession;
    },

    get plant_code() {
        return this.AppSession.plant_code;
    },

    set plant_code(value) {
        this.updateAppSession('plant_code',value);
    },

    get plant_name() {
        return this.AppSession.plant_name;
    },

    set plant_name(value) {
        this.updateAppSession('plant_name',value);
    },

    get access_token() {
        return this.AppSession.access_token;
    },

    set access_token(value) {
        this.updateAppSession('access_token',value);
    },

    get profile() {
        return this.AppSession.profile;
    },

    set profile(value) {
        this.updateAppSession('profile',{...value});
    },

    get plants() {               
        return this.AppSession.plants;
    },

    set plants(value) {
        this.updateAppSession('plants',[...value]);
    },

    get entitlements() {
        
        if(!this.AppSession.profile) return [];
        return this.AppSession.profile.entitlements ? this.AppSession.profile.entitlements : [];
    },

    get air_access_code() {
        return this.AppSession.air_access_code ? this.AppSession.air_access_code.trim() : "";
    },

    set air_access_code(value) {
        this.updateAppSession('air_access_code',value);
    },

    get pno() {
        return this.AppSession.pno ? this.AppSession.pno.trim() : "";
    },

    set pno(value) {
        this.updateAppSession('pno',value);
    },

    get toolno() {
        return this.AppSession.toolno ? this.AppSession.toolno.trim() : "";
    },

    set toolno(value) {
        this.updateAppSession('toolno',value);
    },
    
    get revision() {
        return this.AppSession.revision ? this.AppSession.revision.trim() : "";
    },

    set revision(value) {
        this.updateAppSession('revision',value);
    },

    get redirect() {
        return this.AppSession.redirect;
    },

    set redirect(value) {
        this.updateAppSession('redirect',value);
    },

    get air_mode() {
        return this.AppSession.air_mode;
    },

    set air_mode(value) {
        this.updateAppSession('air_mode',value);
    },

    get connected() {
        return this.AppSession.connected;
    },

    set connected(value) {
        this.updateAppSession('connected',value);
    },

    get env() {
        return this.AppSession.env;
    },

    set env(value) {
        this.updateAppSession('env',value);
    },

    get dimension() {
        return this.AppSession.dimension ? this.AppSession.dimension.trim() : "";
    },

    set dimension(value) {
        this.updateAppSession('dimension',value);
    },

    get sd_testdate() {
        return this.AppSession.sd_testdate ? this.AppSession.sd_testdate.trim() : "";
    },

    set sd_testdate(value) {
        this.updateAppSession('sd_testdate',value);
    },

    get process_code() {
        return this.AppSession.process_code ? this.AppSession.process_code.trim() : "";
    },

    set process_code(value) {
        this.updateAppSession('process_code',value);
    },

    get process_name() {
        return this.AppSession.process_name ? this.AppSession.process_name.trim() : "";
    },

    set process_name(value) {
        this.updateAppSession('process_name',value);
    },


    /**
     * 
     */
    clearAppSession() {
        const _session=  {};        
        
        _session.air_access_code= null;
        _session.pno= null;
        _session.toolno= null;
        _session.revision= null;
        _session.plant_code= null;
        _session.plant_name= null;
        _session.redirect = false;
        _session.air_mode = false;
        _session.access_token = null;
        _session.profile = {};
        _session.code = null;
        _session.plants = [];
        
        _session.dimension = "";
        _session.sd_testdate = "";
        _session.process_code =null;
        _session.process_name=null;
        _session.connected = null;
        _session.env = "";
        
        
        this.AppSession = _session;
        return _session;
    },

    /**
     * 
     * @param {*} plant_code 
     */
    getPlant(plant_code){
        return this.AppSession.plants.find( x => x.plant_code===plant_code);
    },

    /**
     * 
     * @param {String} key 
     * @param {Obj} value 
     */
    updateAppSession(key, value) {
        const _session=  this.AppSession;
        _session[key] = authHelper.sanitizeValue(value);
        this.AppSession = _session;
        
        return _session;
    },
    
    /**
     * 
     * @param {Key} key 
     */
    removeAppSession(key) {
        return this.updateAppSession(key, null);
    },

    /**
     * 
     * @param {String} urlParams 
     */
    setAppSession(urlParams) {
        const _session=  this.AppSession;
        if(urlParams) {
            const air_access_code = authHelper.getUrlParam(urlParams,'AIR_access_code');
            const pno = authHelper.getUrlParam(urlParams, 'pno');
            const toolno = authHelper.getUrlParam(urlParams, 'toolno');
            const revision = authHelper.getUrlParam(urlParams, 'revision');
            const dimension = authHelper.getUrlParam(urlParams, 'dimension');
            const plant_code= authHelper.getUrlParam(urlParams, 'plant_code');
            const access_code= authHelper.getUrlParam(urlParams, 'code');

            if(air_access_code) _session.air_access_code= air_access_code;
            if(pno) _session.pno = pno;
            if(toolno) _session.toolno = toolno;
            if(revision) _session.revision = revision;
            if(dimension) _session.dimension = dimension;
            if(plant_code) _session.plant_code = plant_code;
            if(access_code) _session.access_code= access_code;
        }

        if (_session.pno) {
          _session.redirect = true;
        }
    
        if(_session.air_access_code){
          _session.air_mode = true;
        }
        
        this.AppSession = _session;        
        return _session;
    },       

    /**
     * 
     * @param {String} key 
     */
    getAppSessionStorageValue(key) {
        const value = sessionStorage.getItem(key);
        return authHelper.sanitizeValue(value);
    }
}

export default HelperSession;