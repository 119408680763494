import ApiSerice from '../services/ApiService';
import ApiConstants from '../services/ApiConstants';
import helperSession from './HelperSession';


const HelperCommon = {

    oApi: new ApiSerice(),

    ReloadToken() {
        if(this.oApi) this.oApi.initSession();
    },

    /**
     * 
     * @param {String} plant_code 
     */
    GetPlants(plant_code) {
        const plantParams = {
            plant_code: "*"
        };
        const plantList = [];
        return new Promise((resolve, reject) => {
            try {
                this.oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Plants, plantParams).then((response) => {
                    // entitled plans
                    response.data.data.forEach(plant => {
                        if (helperSession.entitlements.indexOf(plant.entitlement_name.toLowerCase()) >= 0) {
                            plantList.push({
                                plant_name: plant.name,
                                plant_code: plant.plant_code
                            })
                        }
                    });
                    helperSession.updateAppSession('plants', plantList);
                    if(plantList.length>0 && !helperSession.plant_code) {
                        helperSession.plant_code = plantList[0].plant_code;
                        helperSession.plant_name = plantList[0].plant_name;
                    } 
                    resolve(plantList);
                });
            } catch (error) {
                reject(error);
            }
        })
    },

    /**
     * return string value of object
     * @param {Object} obj 
     * @param {String} sDefault 
     */
    toString(obj, sDefault) {
        return obj ? obj.toString() : sDefault;
    },

    /**
    * Rounds a value to a specified amount of decimal places
    * @param {number} value - the value to be rounded
    * @param {number} decimalPlaces - the amount of decimal places to round to
    * @return {number} the rounded number
    */
    round(value, decimalPlaces)  {
        const factor = Math.pow(10, decimalPlaces);
        return Math.round(value * factor) / factor;
    },

    /**
     * Check if session connect with specified plan_code session
     */
    async isConnected(){
        try{
            const result = await this.oApi.GET(ApiConstants.User.Controller, ApiConstants.User.IsConnected);             
            helperSession.connected = result ? (result.data.count>0) : false;
            helperSession.env = result.data ? result.data.env : "";            
            
        } catch(error) {            
            console.log('Connection Error', error)
            helperSession.connected = false;
        }
        return helperSession.connected;        
    }, 
    
    isConnectedCallback(callback){
        
        this.oApi.GET(ApiConstants.User.Controller, ApiConstants.User.IsConnected)
        .then(response => {          
            if(response.data) {
                helperSession.connected = true;
                helperSession.env = response.data.env;
                callback(true);
            } else {
                helperSession.connected = false;
                callback(false);
            }
        }).catch(error => {
            helperSession.connected = false;
            callback(false);            
        });    
    }

}

export default HelperCommon