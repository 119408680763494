import React, { useContext } from 'react';
import Cookies from 'universal-cookie';
import { store } from './stores/store'
import { useHistory } from "react-router-dom";
import TopBar from './components/MenuBar/TopBar'
import BottomBar from './components/MenuBar/BottomBar'
import LocalizedStrings from 'react-localization';
import lang from './lang/lang';
import helperSession from '../src/helpers/HelperSession';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import './App.css';



const cookies = new Cookies();

const Main = (props) => {

  let history = useHistory();

  const globalState = useContext(store);
  const { dispatch } = globalState;

  let strings = new LocalizedStrings(lang);

  const sLang = cookies.get('lang');
  strings.setLanguage(sLang ? sLang : 'en');  
  
  dispatch({ type: 'update_plant', value: {plant_code: helperSession.plant_code, plant_name:  helperSession.plant_name, norender: 1} });
  if (helperSession.redirect) {
    const oValue = {
      pno: helperSession.pno,
      toolno: helperSession.toolno,
      revision: helperSession.revision,
      dimension: helperSession.dimension,
      sd_testdate: '',
      plant_code: helperSession.plant_code
    };   

    if(helperSession.dimension) {
      dispatch( { type: 'set_chart_detail_screen_data', value: oValue });
      history.push('/chartdetailscreen');
    }
    else {
      dispatch( { type: 'set_revision_screen_data', value: oValue });
      history.push('/revisionscreen');
    }
  } else {    
    history.push('/chartscreen');
  }

  return (
    <div className="App" >

      <TopBar strings={strings} />

      <header className="App-header" style={{ minHeight: '90vh' }}>
       
      </header>

      <BottomBar strings={strings} />

    </div >
  );
}

export default Main;
