import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LocalizedStrings from 'react-localization';
import IDP_AuthHandler from "./auth/IDP_AuthHandler";
import AIR_AuthHandler from "./auth/AIR_AuthHandler";
import Main from "./Main";
import ChartScreen from "./screens/ChartScreen/ChartScreen";
import RevisionScreen from "./screens/RevisionScreen/RevisionScreen";
import ChartDetailScreen from "./screens/ChartDetailScreen/ChartDetailScreen";
import ActionHistoryScreen from "./screens/ActionHistoryScreen/ActionHistoryScreen";
import StatAbnNotificationScreen from "./screens/NotificationScreen/StatAbnNotificationScreen";
import InspectionPlanScreen from "./screens/InspectionPlanScreen/InspectionPlanScreen";
import CodeManagementScreen from "./screens/CodeManagementScreen/CodeManagementScreen";
import TestCriteriaScreen from "./screens/TestCriteriaScreen/TestCriteriaScreen";
import ErrorScreen from "./screens/ErrorScreen/ErrorScreen";
import ApplicationConfigScreen from "./screens/ApplicationConfigScreen/ApplicationConfigScreen";

import { checkRole, checkEntitlements } from './helpers/Utilities';

import { ACTION_HISTORY_SCREEN, CODE_MANAGEMENT_SCREEN, INSPECTION_PLAN_SCREEN, SEARCH_CHART_SCREEN, STATISTICAL_ABNORMALITY_SETTINGS, TEST_CRITERIA_SCREEN, APPLICATION_CONFIG_SCREEN, REVISION_SCREEN, CHART_DETAIL_SCREEN } from './resources/securityRoles';
import lang from './lang/lang';

const Pdf = () =>{    
    return(        
        <InspectionPlanScreen></InspectionPlanScreen>   
    )
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
    // If no entitlements found or plants, redirect to error page
    let iCheck = checkEntitlements();    
    
    //** Route to intended page after checkings roles */
    if(iCheck === 1 ){
        return (
            <Route {...rest} render={(props) => (
            checkRole(rest.screen) === true
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: "/",
                    state: { from: props.location }
                }} />
            )} />
        )
    }

    // ** Else route to error page
    const strings = new LocalizedStrings(lang);
    let detail = "Unauthorized Access";
    if(iCheck === -1) detail = strings.no_entitlement_msg;
    else if(iCheck === -2) detail = strings.no_plants_msg;
    else if(iCheck === -3) detail = strings.no_roles_msg;
    return (
        <Route {...rest} render={(props) => (
            <Redirect to={{
                pathname: '/error',
                state: { from: props.location, detail: detail }
            }} />         
        )} />
    )    
}       

const Root = (props) => {    
    return (
        <div>
            <Switch>
                <ProtectedRoute component={ChartScreen} path="/chartscreen" screen={SEARCH_CHART_SCREEN} />
                <ProtectedRoute component={RevisionScreen} path="/revisionscreen" screen={REVISION_SCREEN} />
                <ProtectedRoute component={ChartDetailScreen} path="/chartdetailscreen" screen={CHART_DETAIL_SCREEN} />
                <ProtectedRoute component={ActionHistoryScreen} path="/actionhistoryscreen" screen={ACTION_HISTORY_SCREEN} />
                <ProtectedRoute component={StatAbnNotificationScreen} path="/statabnnotificationscreen" screen={STATISTICAL_ABNORMALITY_SETTINGS} />
                <ProtectedRoute component={ApplicationConfigScreen} path="/applicationconfigscreen" screen={APPLICATION_CONFIG_SCREEN} />
                <ProtectedRoute component={Pdf} path="/inspectionplanscreen" sreen={INSPECTION_PLAN_SCREEN} />
                <ProtectedRoute component={CodeManagementScreen} path="/codemanagementscreen" screen={CODE_MANAGEMENT_SCREEN} />
                <ProtectedRoute component={TestCriteriaScreen} path="/testscreen" screen={TEST_CRITERIA_SCREEN} />
                <Route component={IDP_AuthHandler} path="/callback" />
                <Route component={AIR_AuthHandler} path="/airauth" />
                <Route component={ErrorScreen} path="/error" />
                <Route component={Main} path="/" />
            </Switch>
        </div>
    )

}

export default Root;