import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import LocalizedStrings from 'react-localization';
import { store } from '../../stores/store';
import helperSession from '../../helpers/HelperSession';
import lang from '../../lang/lang';
import alertImage from "../../images/Alert.png"
import '../../App.css';

const ErrorScreen = (props) => {

    let strings = new LocalizedStrings(lang);
    const globalState = useContext(store);    
    const { dispatch } = globalState;

    const logUserOut = () => {
        dispatch({ type: 'update_token', value: '' });
        dispatch({ type: 'clear_user_data' });
        helperSession.clearAppSession();
        window.location = '/';
        //window.location = 'https://auth.kochid.com/idp/startSLO.ping';        
    }

    let sDetail = props.location.state;
    if(!sDetail) {
        let params = new URLSearchParams(document.location.search);
        sDetail = params.get("detail"); 
    }
    if(!sDetail) sDetail = "No error detail found";    
    return (
        <div className="error">        
            <h2>CCS SPC Tool Error</h2>
            <img src={alertImage} alt="Error" width="75px" height="75px"></img>            
            <h3>{sDetail}</h3>
            <hr />
            <Button variant="contained" color="primary" onClick={logUserOut}>{strings.menu_labels.home_page}</Button>            
            {JSON.stringify(props.location.state, null, 2)}
      </div>

    );
}

export default ErrorScreen;