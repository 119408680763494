import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useState } from 'react';
import LocalizedStrings from 'react-localization';
import Dimension from "../../components/Dimension/Dimension";
import BottomBar from '../../components/MenuBar/BottomBar';
import TopBar from '../../components/MenuBar/TopBar';
import lang from '../../lang/lang';
import { store } from '../../stores/store';
import Wait from '../../components/Wait';
import ApiService from '../../services/ApiService';
import ApiConstants from '../../services/ApiConstants';
import helperSession from '../../helpers/HelperSession'
import '../../App.css';
import styles from './Revision.module.css'

const RevisionScreen = ({ params }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const oApi = new ApiService();
  //Language selection
  let strings = new LocalizedStrings(lang);
  strings.setLanguage(globalState.state.lang);
  //end of language selection

  //Loading functions
  const wait = () => {
    const el = document.getElementById('loading_ph');
    if (el) el.style.display = 'block';
  }
  const stopWaiting = () => {
    const el = document.getElementById('loading_ph');
    if (el) el.style.display = 'none';
  }
  //end of loading functions

  //Change revision
  const changeRevision = (revNum) => {
    dispatch({ type: 'set_revision', value: { revision: revNum } });
    getDimensions(globalState.state, revNum)
  }

  //Set default revision selection
  const setDefaultRevision = () => {
    return (
      <option value='select'>Select required revision</option>
    )
  }

  //Set states
  const [dimData, setDimdata] = useState([])
  const [rivData, setRivdata] = useState(() => setDefaultRevision())
  const [isFetched, setIsFetched] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNo, setPageNo] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isSelectDisable, setIsSelectDisable] = useState(true);
  const [revisionNumbers, setRevisionNumbers] = useState([]);
  //End of set states

  //Update revision
  const updateRevisions = (revisions) => {
    let revArray = [];
    revArray = revisions.map((revision, i) => <option key={i} value={revision}>{revision}</option>);
    // set the selected value to be the latest revision
    dispatch({ type: 'set_revision', value: { revision: revisions[revisions.length - 1] } });
    setRivdata(revArray)
  }

  //Update dimensions
  const updateDimensions = (fetchedDimensions) => {
    setDimdata(fetchedDimensions);
  }

  //Function to filter unique revision numbers from result
  const getRevisions = (data) => {
    var j, k, count = 0
    var start = false;
    var outputArray = [];
    for (j = 0; j < data.length; j++) {
      for (k = 0; k < outputArray.length; k++) {
        if (data[j].revision === outputArray[k]) {
          start = true;
        }
      }
      count++;
      if (count === 1 && start === false) {
        outputArray.push(data[j].revision);
      }
      start = false;
      count = 0;
    }
    return outputArray
  }

  //Get dimensions from API
  const getDimensions = (params, revNum) => {
    wait();
    const dimParams = {
      pno: params.pno.trim(),
      toolno: params.toolno.trim()
    };

    if (revNum) { //If specific revision number is passed
      dimParams.revision = revNum;
    }

    oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Dimensions, dimParams).then((response) => {

      
      // Set Global UQNO
      const nData = response.data.data ? response.data.data.length : 0
      if(nData==0) {
        stopWaiting()
        return
      }
      
      const uqno = response.data.data[0].UQNo
      dispatch({ type: 'set_uqno', value: { uqno: uqno } });
      
      setDimdata([]);
      //stopWaiting();
      var dimArray = []
      var i = 0;
      const testDate = params.sd_testdate ? params.sd_testdate.trim() : "";
      for (i = 0; i < nData; i++) {
        dimArray.push(<Dimension data={response.data.data[i]} pno={params.pno} toolno={params.toolno}
          sd_testdate={testDate} plant_code={params.plant_code} riv_data={revisionNumbers} itemNo={i} key={i}
        />)
      }
      stopWaiting();

      getRevisions(response.data.data);
      setTotalPages(Math.ceil(dimArray.length / itemsPerPage));
      updateDimensions(dimArray);
      setPageNo(1);
      setTimeout(() => setIsSelectDisable(false), 3000)

    }).catch(error => {
      console.error(error);
      stopWaiting();
    });
  }

  // handles pagination
  const handlePagination = (event, value) => {
    setPageNo(value);
  }

  // handles update to items per page dropdown
  const handleItemsPerPageUpdate = (value) => {
    setItemsPerPage(value);
    setTotalPages(Math.ceil(dimData.length / value));
    if (pageNo > Math.ceil(dimData.length / value))
      setPageNo(Math.ceil(dimData.length / value));
  }

  // returns dimension data for the selected page with items per page setting
  const getDimDataPerPage = () => {
    return dimData.slice((pageNo - 1) * itemsPerPage, pageNo * itemsPerPage).map(dimData => dimData)
  }

  const handleRefresh = () => {
    //wait();
    let revision;
    var dimParams = {
      pno: globalState.state.pno.trim(),
      toolno: globalState.state.toolno.trim()
    };

    oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Dimensions, dimParams).then((response) => {
      const revisions = getRevisions(response.data.data);
      setRevisionNumbers(revisions);
      revision = revisions[revisions.length - 1];
      updateRevisions(revisions);
      getDimensions(globalState.state, revision);
    })
  }

  //Get Dimensions
  if (isFetched === 0) {
    setIsFetched(1);
    if (helperSession.access_token) helperSession.redirect = false;
    let revision;
    var dimParams = {
      pno: globalState.state.pno.trim(),
      toolno: globalState.state.toolno.trim()
    };

    oApi.GET(ApiConstants.MasterData.Controller, ApiConstants.MasterData.Dimensions, dimParams).then((response) => {
      const revisions = getRevisions(response.data.data);
      setRevisionNumbers(revisions);
      revision = revisions[revisions.length - 1];
      updateRevisions(revisions);
      getDimensions(globalState.state, revision);
    })
  }

  return (
    <>
      <TopBar strings={strings} pno={globalState.state.pno} toolno={globalState.state.toolno} process={globalState.state.process_name} showInspIcon={true} />

      <div className={styles.content}>

        <div className={styles.selection}>
          <div>
            <label htmlFor='revision'>{strings.input_labels.select_revision}</label>
            <select name="revision" id="revision" onChange={e => changeRevision(e.target.value)} value={globalState.state.revision}>
              {rivData}
            </select>
          </div>

          <div>
            <label htmlFor='itemspage'>{strings.input_labels.items_per_page}</label>
            <select
              disabled={isSelectDisable}
              value={itemsPerPage}
              name="itemspage"
              id="itemspage"
              onChange={e => handleItemsPerPageUpdate(e.target.value)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>

          <div>
            {strings.input_labels.total_items}:&nbsp; <span className={styles.total}>{dimData.length}</span>
          </div>
        </div>

        <div className={styles.dimensions}>
          {getDimDataPerPage()}
        </div>

        <div className={styles.pagination}>
          <Pagination count={totalPages} page={pageNo} color="secondary" onChange={handlePagination} />
        </div>

      </div>

      <div id="loading_ph" className="loading-overlay">
        <Wait message={strings.hold_msg} >
        </Wait>
      </div>

      <BottomBar strings={strings} handleRefresh={handleRefresh} />
    </>
  )
}

export default RevisionScreen;