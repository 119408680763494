export const en = {
    menu_labels: {        
        action_history: 'Action History',
        search_chart: 'Search Chart',
        set_test: 'Set Test Criteria',
        exit: 'Exit',
        language: 'Language',
        refresh: 'Refresh',
        hello: 'Hello',
        product_tool_num: 'Product-Tool #',
        dimension: 'Measurement Item',
        back: 'Back',
        code_mang: 'Code Management',
        insp_plan: 'View Inspection Plan',
        admin: 'System Settings',        
        stat_abn_rec: 'Statistical Abnormality Recipients',
        application_config: 'Application Configuration',
        home_page: 'Home Page'
    },
    input_labels: {
        plant: 'Plant',
        process: 'Process',        
        product_number: 'Product Number',
        mc_tool_num: 'Tool Number',        
        search: 'Search',
        select_revision: 'Select revision: ',
        items_per_page: 'Measurement Items per Page: ',
        save: 'Save',
        total_items: 'Total No. of Measurement Items',
        clear_dates: 'Clear',
        insplanheading: 'Schematic description for'
    },
    list_labels: {
        pn: 'PN',
        mc_tool: 'MC/Tool No.',        
        revision: 'Revision #',
        last_date: 'Last Date of Measurement'
    },
    logout_modal_labels: {
        yes_button : 'Yes, Log me out',
        no_button: 'No',
        desc: 'Are you sure you want to logout?'
    },
    chart_detail: {
        pci: 'Process Capability Information',
        va: 'Revision #'        
    },
    cds_search: {
        runs: 'Most recent 100 runs',
        range: 'Date range to search',
        refresh: 'Refresh',
        select_chart: 'Select chart type',
        chart_label: 'chart'
    },
    cds_notification : {
        sys_not: 'System Notification',
        cs_sup: 'Corrective Action from Supervisor',
        cor_act: 'Corrective Action',
        trcb: 'Traceability',
        vr: 'Violated Rule',
        iss_dat: 'Issued Date',
        val: 'Measurement',
        lsl: 'LSL',
        lcl: 'LCL',
        ucl: 'UCL',
        usl: 'USL',
        root_cause: 'Reason for Abnormality',
        tak_act: 'Taken Action',
        act_dat: 'Action Date',
        owner: 'Owner',
        cont: 'Container#',
        seq: 'Sequential#',
        actions: 'Actions',
        batchno: 'Extra Traceability1',
        serialno: 'Extra Traceability2'
    },
    cds_correctiveaction_modal : {
        but_save: 'Add',
        but_cancel: 'Cancel',
        v_rule: 'Violated Rule',
        owner: 'Owner',
        modified_by: 'Modified By',
        reason: 'Reason for Abnormality',
        action_type: 'Corrective Action',
        remarks : 'Remarks',
        but_update: 'Update',
        but_remove: 'Remove'
    },
    ahs_labels : {
        date_range : 'Alert Date',        
        prod_numb: 'Product No.',
        doc_num: 'doc_num',
        ext_trc1: 'Extra Traceability1',
        ext_trc2: 'Extra Traceability2',
        reason_jud: 'Violated Rule',                                
        reporter: 'Owner',
        reason_for_abnormality: 'Reason for Abnormality'        
    },
    tcs_labels : {
        plant_tab: 'Plant',
        plant_tab_text: 'Update Plant Default Test Criteria',
        
        process_tab: 'Process',
        process_tab_text: 'Update Processess Test Criteria',
        process_label: 'Process',

        product_tab: 'Product',
        product_tab_text: 'Update Product/Items Test Criteria',
        product_label: 'Product No',
        item_label: 'Inspection Item',

        test_item_label: 'Test Rules',
        process_product_alert: 'Selected process product(s) have been adjusted, Setting will be over-written on save',
        product_items_alerts: 'Selected product items/dimensions were not found!, Invalid product no'
    },
    error_msgs: {
        min_prod_length: 'Product number must be at least 4 digits.',
        no_search_data_found: 'No data found for this search.',
        ca_fields_required: 'Reason for Abnormality & Corrective Action fields are required.'
    },
    cms_notification: {
        code_management_label: 'Corrective Action Code Management',                                
        process_lbl: 'Process',
        code_lbl: 'Code',
        code_desc_lbl: 'Code Description',
        status_lbl: 'Status'
    },
    hold_msg: 'Processing your request, Please Wait...',
    cds_pci: {
        potential_failure: 'Potential Failure',
        overall: 'Overall',
        within: 'Within',
        total: 'Total'
    },
    email_notifications: {        
        dept: 'Process',                        
        stat_abn: 'Statistical Abnormality Recipient Settings',        
        out_spec: 'Out of spec.',
        out_cont: 'Out of control',
        low_cpk: 'Low CPK',
        save_sett: 'Save',
        stat_abn_type: 'Type of Stastical Abnormality',        
        email_add: 'E-mail Address',
        add: 'ADD',
        remove: 'REMOVE',
        global_email_label: 'Global E-mail Address List',
        rec_email_label: 'Recipients for',
        copy_from_label: 'Copy from',
        copy_list_but: 'Copy',
        copy_stat_abn_type: 'Copy to Stastical Abnormality',
    },
    sel_lang: 'Select Language',
    language_popup_alert: 'Note: After you select a new language, you will be redirected to the Home Page and all your unsaved changes will be lost.',
    conf_dialog: {
        yes: 'Yes',
        no: 'No',
        save_confirm_text: 'Are you sure you want to save these changes?',
        cancel_confirm_text: 'Are you sure you want to cancel? Any changes will not be saved.',
        remove_confirm_text: 'Are you sure you want to remove?',
        copy_recp_confirm_text: 'Are you sure you want to copy recipients?',
        save_recp_confirm_text: 'Save these changes?',
        successMsgSaveRecipients: 'Recipient(s) were saved successfully.',
        errorMsgSaveRecipients: 'Could not save Recipients due to Error.',
        changelost_confirm_text: 'All unsaved changes will be lost. Are you sure?',
        confirm_label: 'Confirm Action',
        successMsgSaveCAAction: 'Corrective Action was saved successfully.',
        errorMsgSaveCAAction: 'Could not save Corrective Action due to Error.',
        successMsgRemoveCAAction: 'Corrective Action was removed successfully.',
        errorMsgRemoveCAAction: 'Could not remove Corrective Action due to Error.',
        successCodeAddAction :'Code was added successfully.',
        errorCodeAddAction: 'Could not add Code due to error.',
        successCodeUpdateAction :'Code was updated successfully.',
        errorCodeUpdateAction: 'Could not update Code due to error.',
        successCodeDeleteAction :'Code was deleted/inactivated successfully.',
        errorCodeDeleteAction: 'Could not delete/inactivate Code due to error.',
        updateCodeValidationMsg : 'Code Description & Status are required fields.',
        addCodeValidationMsg : 'All fields are required.',
        successMsgTestsSaved: 'Tests were saved successfully.',
        errorMsgTestsSaved: 'Could not save Tests due to error.',
        errorMsgInspPlanNoPDF: 'PDF document could not be found.'
    },
    no_entitlement_msg: 'Please request entitlements in order to use this app.',
    no_plants_msg: 'Please request access to plant(s) in order to use this app.',
    no_roles_msg: 'Please request access to role(s) in order to use this app.',
    grid: {
        body: {
            emptyDataSourceMessage: "No data available for this request.",
            addTooltip: 'Add New Record',
            deleteTooltip: 'Delete Record',
            editTooltip: 'Edit Record',
            filterRow: {
                filterTooltip: 'Filter'
            },
            editRow: {
                deleteText: 'Are you sure you want to delete this record?',
                cancelTooltip: 'Cancel',
                saveTooltip: 'Save'
            }
        },
        header: {
            actions: ''
        },
        pagination: {
            labelDisplayedRows: `{from}-{to} of {count}`,
            labelRowsSelect: '',
            firstAriaLabel: '',
            firstTooltip: 'First Page',
            previousAriaLabel: '',
            previousTooltip: 'Previous Page',
            nextAriaLabel: '',
            nextTooltip: 'Next Page',
            lastAriaLabel: '',
            lastTooltip: 'Last Page'
        },
        toolbar: {     
            addRemoveColumns: '',
            nRowsSelected: '',
            showColumnsTitle: '',
            showColumnsAriaLabel: '',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',            
            searchTooltip: 'Search',
            searchPlaceholder: 'Search'           
        }    
    }
};
